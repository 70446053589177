import styled from "styled-components";
import { defaultTheme } from "storybook/themes/default";
import { FieldErrorDefault } from "storybook/components/field-error";

const { colors } = defaultTheme;

export const Container = styled.div``;

export const Column = styled.div`
  flex: 1;
`;

export const Row = styled.div`
  gap: 10px;
  display: flex;
  flex-direction: row;

  ${Column}:first-child {
    flex: 2;
  }
`;

export const StyledInput = styled.input<{ $error: boolean }>`
  width: 100%;
  height: 37px;
  font-size: 16px;
  font-weight: 700;
  padding: 8px 16px;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  outline-color: ${({ $error }) => ($error ? "#DB6068" : "#6a6e87")};
  font-variation-settings: "wght" 700;
  color: ${({ $error }) => ($error ? "#DB6068" : colors.ciqBrand)};
  border: 1px solid
    ${({ $error }) => ($error ? "#DB6068" : "rgba(0, 0, 0, 0.26)")};

  &:focus {
    border: 1px solid ${({ $error }) => ($error ? "#DB6068" : "#6a6e87")};
  }

  &::placeholder {
    color: ${({ $error }) => ($error ? "#DB6068" : colors.ciqBrand)};
  }
`;

export const StyledSelectWrapper = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
`;

export const StyledSelect = styled.select<{ $error: boolean }>`
  width: 100%;
  height: 37px;
  font-size: 16px;
  font-weight: 700;
  appearance: none;
  padding: 8px 16px;
  border-radius: 8px;
  padding: 0.5rem 1rem;
  border: 1px solid rgba(0, 0, 0, 0.26);
  font-variation-settings: "wght" 700;
  outline-color: ${({ $error }) => ($error ? "#DB6068" : "#6a6e87")};
  color: ${({ $error }) => ($error ? "#DB6068" : colors.ciqBrand)};
  border: 1px solid
    ${({ $error }) => ($error ? "#DB6068" : "rgba(0, 0, 0, 0.26)")};

  &:focus {
    border: 1px solid ${({ $error }) => ($error ? "#DB6068" : "#6a6e87")};
  }
`;

export const ArrowWrapper = styled.div`
  top: 50%;
  right: 1rem;
  width: 0.75rem;
  height: 0.75rem;
  position: absolute;
  pointer-events: none;
  transform: translateY(-75%);
  background: url('data:image/svg+xml;charset=US-ASCII,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 4 5"><path fill="%236a6e87" d="M2 5L0 3h4z"/></svg>')
    no-repeat center center/contain;
`;

export const StyledFieldset = styled.fieldset`
  border: none;
  padding: 0;
  margin: 0;
`;

export const StyledLegend = styled.legend`
  margin: 0;
  border: none;
  padding: 0 0 16px 0;
  font-family: ${defaultTheme.typography.fontFamily};
`;

export const StyledLabel = styled.label`
  display: block;
  font-size: 14px;
  padding-bottom: 5px;
`;

export const ExampleDate = styled.p`
  margin: 0;
  font-size: 12px;
  padding: 0 0 15px 0;
  color: ${colors.ciqBrandLight};
  font-family: ${defaultTheme.typography.fontFamily};
`;

export const ErrorText = styled.p`
  color: #db6068;
  font-size: 12px;
  margin: 7px 0 0 0;
`;

export const StyledError = styled(FieldErrorDefault)`
  // I tried adding a "subtitle3" variant to Typography but MUI wouldn't allow it.
  // Another reason to get rid of MUI.
  font-size: 12px !important;
  margin-top: 1px;
`;

import React, { ReactNode } from "react";
import { ExpandMore } from "@material-ui/icons";
import { ComponentThemeProvider } from "storybook/context/component-theme";
import { Typography } from "storybook/components/typography";

import {
  StyledDetailsDefault,
  StyledSummaryDefault,
  StyledTitleContainer,
  StyledPreviewContainer,
} from "./theme/default";

const styledDetailsMap = {
  default: StyledDetailsDefault,
} as const;

const styledSummaryMap = {
  default: StyledSummaryDefault,
} as const;

export type AccordionType = {
  theme: "default";
  children: ReactNode;
  title: string;
  previewContent: ReactNode;
};

export function Accordion({
  theme,
  children,
  title,
  previewContent,
}: AccordionType): JSX.Element {
  const StyledDetails = styledDetailsMap[theme];
  const StyledSummary = styledSummaryMap[theme];

  return (
    <ComponentThemeProvider theme={theme}>
      <StyledDetails data-testid="Accordion">
        <StyledSummary data-testid="Accordion__Summary">
          <StyledTitleContainer>
            <Typography theme="default" variant="body1" color="ciqBrandDark">
              <b>{title}</b>
            </Typography>
            <ExpandMore />
          </StyledTitleContainer>
          {previewContent && (
            <StyledPreviewContainer>{previewContent}</StyledPreviewContainer>
          )}
        </StyledSummary>
        {children}
      </StyledDetails>
    </ComponentThemeProvider>
  );
}

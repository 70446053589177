import { CSSProperties } from "react";
import styled from "styled-components";
import Popper from "@material-ui/core/Popper";
import { selector } from "storybook/utils/selector";
import { defaultTheme } from "storybook/themes/default";
import { isIE11 } from "storybook/utils/helpers";

const { colors } = defaultTheme;

export const Container = styled.div`
  gap: 1rem;
  display: flex;
  position: relative;
  flex-direction: column;
`;

export const FormElements = styled.div`
  display: flex;
  flex-direction: row;
`;

export const InputContainer = styled.div`
  display: flex;
  flex: 1;
`;

export const Input = styled.input<{ $error?: boolean; $disabled?: boolean }>`
  color: ${colors.ciqBrand};
  width: 100%;
  border: 0;
  height: 100%;
  margin: 0;
  display: block;
  min-width: 0;
  background: none;
  box-sizing: border-box;
  letter-spacing: inherit;
  font-size: 16px;
  font-weight: 700;
  font-variation-settings: "wght" 700;
  flex: 1;
  height: 40px;
  border-radius: 8px;
  position: relative;
  display: inline-flex;
  box-sizing: border-box;
  padding: 0.5rem 1rem;
  border: 2px solid transparent;
  box-shadow: inset 0 0 0 1px
    ${({ $error }) => ($error ? colors.error : "rgba(0, 0, 0, 0.26)")};
  font-family: ${defaultTheme.typography.fontFamily};
  cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "text")};

  ${({ $disabled, $error }) =>
    !$disabled &&
    `
    &:hover {
      box-shadow: inset 0 0 0 1px ${
        $error ? colors.error : "rgba(0, 0, 0, 0.87)"
      };
    }

    &:focus-within {
      box-shadow: inset 0 0 0 2px ${$error ? colors.error : colors.ciqBrand};
    }

    &:focus {
      outline: none;
    } 
  `}

  &::placeholder {
    color: rgba(106, 110, 135, 0.5);
    opacity: 1;
  }
`;

export const ListContainer = styled.div`
  width: 100%;
  z-index: 1000;
`;

export const List = styled.ul`
  margin: 0;
  padding: 0;
  overflow-y: auto;
  min-width: 120px;
  border-radius: 2px;
  background-color: white;
  list-style-type: none;
`;

export const ListItem = styled.li<{ $highlighted: boolean }>`
  cursor: pointer;
  padding: 12px;
  font-size: 14px;
  font-family: ${defaultTheme.typography.fontFamily};
  color: ${({ $highlighted }) => ($highlighted ? "white" : "black")};
  background-color: ${({ $highlighted }) =>
    $highlighted ? colors.ciqBrandDark : "white"};

  &:first-child {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }

  &:last-child {
    border-bottom-left-radius: 2px;
    border-bottom-right-radius: 2px;
  }
`;

export const InputListContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const ChildrenContainer = styled.div`
  display: flex;
`;

export const LabelContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Label = styled.label<{
  $isDirty?: boolean;
  $width?: CSSProperties["width"];
}>`
  height: 40px;
  display: flex;
  margin-right: 10px;
  align-items: center;
  word-break: break-word;
  color: ${({ $isDirty }) =>
    $isDirty ? colors.dirtyLabel : "rgba(0, 0, 0, 0.87)"};
  width: ${({ $width }) => $width || "auto"};
  font-family: ${defaultTheme.typography.fontFamily};
`;

export const Filler = styled.div`
  flex: 1;
`;

export const ChipContainer = styled.div<{ $expanded: boolean }>`
  display: ${isIE11() ? "block" : "flex"};
  padding-bottom: 3px;
  margin: ${isIE11() ? "45px" : "10px"} 0 0 0;
  flex-direction: column;
  overflow: hidden;
  position: relative;
  max-height: ${({ $expanded }) => ($expanded ? "1000px" : "86px")};

  &::after {
    content: "";
    bottom: 0;
    left: 0;
    right: 0;
    height: 5px;
    position: absolute;
    background-color: white;
    pointer-events: none;
  }
`;

export const ChipsInner = styled.div`
  display: flex;
  flex-wrap: wrap;

  .chip {
    margin-bottom: 0.5rem;
    margin-right: 0.5rem;
  }
`;

export const AnimatedChip = styled.div`
  animation: fadeIn 0.3s ease-in;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  &:hover {
    button {
      opacity: 1;
    }
  }

  button {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  &.removing {
    animation: fadeOut 0.3s ease-out forwards;
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

export const ButtonContainer = styled.div<{ $disabled: boolean }>`
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 1) 20%
  );
  display: flex;
  justify-content: center;

  ${selector`.MuiButton-root`} {
    font-weight: 500;
    color: #851d58;
    text-transform: none;
    outline: none;

    &:hover,
    &:focus,
    &:active {
      text-decoration: underline;
      background-color: transparent;
    }
  }
`;

export const StyledPopper = styled(Popper)<{
  $width?: number;
  $hasItems: boolean;
  $maxHeight?: CSSProperties["maxHeight"];
}>`
  // In Ithaca, the modal renders at z-index 1300.
  // As there could be multiple modals with custom z-indexes calculated
  // on the fly, this number is high to ensure the select popup appears in front.
  // However, this is brittle and needs to be solved properly.
  z-index: 10000;
  max-height: 95vh;
  padding: 8px 0;
  overflow: auto;
  background-color: white;
  border-radius: 8px;
  user-select: none;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  min-width: ${({ $width }) => ($width ? `${$width}px` : "auto")};
  max-height: ${({ $maxHeight }) => $maxHeight || "95vh"};

  // Font styling is needed due to the portal not being within the font wrapper.
  font-family: ${defaultTheme.typography.fontFamily};
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;
`;

export const StyledListIE11 = styled.div<{
  $width?: number;
}>`
  max-height: 150px;
  z-index: 10000;
  padding: 8px 0;
  overflow: auto;
  background-color: white;
  border-radius: 8px;
  user-select: none;
  min-width: ${({ $width }) => ($width ? `${$width}px` : "auto")};
  border: 1px solid ${colors.inputBorder};
  top: 40px;
  left: 0;
  position: absolute;

  // Font styling is needed due to the portal not being within the font wrapper.
  font-family: ${defaultTheme.typography.fontFamily};
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;

  ${ListItem} {
    padding: 8px;
    font-size: 12px;
  }
`;

import React, { CSSProperties } from "react";
import { StyledComponent } from "styled-components";
import CloseIcon from "@material-ui/icons/Cancel";
import { Typography } from "../typography";
import {
  ButtonContainer,
  ContainerDefault,
  InnerContainerDefault,
} from "./theme/default";

type ThemeName = "default";
export type VariantProp = "default" | "primary" | "secondary";

export type ChipType = {
  role?: string;
  value: string;
  theme: ThemeName;
  variant: VariantProp;
  disabled?: boolean;
  ariaSelected?: boolean;
  onRemove: (item: string) => void;
  maxWidth?: CSSProperties["maxWidth"];
};

type StyledComponentMap = Record<ThemeName, StyledComponent<any, any>>;

const containerMap: StyledComponentMap = {
  default: ContainerDefault,
};

const innerContainerMap: StyledComponentMap = {
  default: InnerContainerDefault,
};

export function Chip({
  role,
  variant,
  value,
  theme,
  onRemove,
  disabled,
  maxWidth,
  ariaSelected,
}: ChipType) {
  const Container = containerMap[theme];
  const InnerContainer = innerContainerMap[theme];

  return (
    <Container
      role={role}
      title={value}
      className="chip"
      data-testid="Chip"
      $variant={variant}
      $disabled={disabled}
      aria-selected={ariaSelected}
    >
      <InnerContainer
        $variant={variant}
        $disabled={disabled}
        $maxWidth={maxWidth}
      >
        <Typography
          theme="default"
          variant="body2"
          color="ciqBrand"
          component="span"
        >
          {value}
        </Typography>
        <ButtonContainer role="button" onClick={() => onRemove(value)}>
          <CloseIcon />
        </ButtonContainer>
      </InnerContainer>
    </Container>
  );
}

import { useHistory } from "react-router-dom";
import { Typography } from "storybook/components/typography";
import { Button } from "storybook/components/button";
import {
  Content,
  CopyContainer,
  ButtonContainer,
  GraphicContainer,
  FinishedContainer,
} from "./styles";
import { useCallback } from "react";

export function Completed() {
  const history = useHistory();

  const handleHomeClick = useCallback(() => {
    history.push("/");
  }, [history]);

  return (
    <FinishedContainer>
      <Content>
        <Typography
          theme="default"
          variant="h4"
          color="ciqBrand"
          component="h1"
        >
          You&apos;re Finished!
        </Typography>
        <CopyContainer>
          <Typography theme="default" variant="body1" color="ciqBrand">
            Thank you for completing your risk assessment! Your provider will 
            review these updates to determine any follow-up recommendations.
          </Typography>
        </CopyContainer>
        <GraphicContainer>
          <img src="/finished.png" width="288" height="225" alt="complete" />
        </GraphicContainer>
        <ButtonContainer>
          <Button
            theme="default"
            color="default"
            variant="contained"
            onClick={handleHomeClick}
          >
            Home
          </Button>
        </ButtonContainer>
      </Content>
    </FinishedContainer>
  );
}

import styled from "styled-components";
import { VisuallyHide } from "storybook/styles/common";
import { defaultTheme } from "storybook/themes/default";
import { Button } from "storybook/components/button";
import { Typography } from "storybook/components/typography";
import { selector } from "storybook/utils/selector";

const { colors } = defaultTheme;

const StyledRadioInput = styled.input`
  position: static;
`;

export const StyledRadioInputHidden = styled.input`
  ${VisuallyHide}
`;

export const StyledRadioInputLegacy = styled(StyledRadioInput)``;

export const StyledRadioInputContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledRadioIndicator = styled.span<{
  selected?: boolean;
  $keyboardFocus?: boolean;
}>`
  height: 24px;
  width: 24px;
  background-color: ${colors.white};
  border-radius: 50%;
  margin-right: 0.75rem;
  border: 1px solid ${colors.ciqBrandLight};
  flex-shrink: 0;
  position: relative;
  box-sizing: border-box;
  background-color: ${({ $keyboardFocus }) =>
    $keyboardFocus ? colors.inputBorder : "transparent"};

  :hover {
    border-color: ${colors.ciqBrandDark};
  }

  &:after {
    content: "";
    position: absolute;
    display: ${({ selected }) => (selected ? "block" : "none")};
    width: 16px;
    height: 16px;
    background: ${colors.ciqBrand};
    border-radius: 50%;
    top: 3px;
    left: 3px;
  }
`;

const StyledRadioLabel = styled(Typography)`
  display: inline-flex;
  align-items: center;
  position: relative;

  :hover ${StyledRadioIndicator} {
    border-color: ${colors.ciqBrandDark};
  }
`;

export const StyledRadioLabelLegacy = styled(StyledRadioLabel).attrs({
  color: "greyDarkest",
  theme: "legacy",
  variant: "body1",
  component: "label",
})``;

export const StyledRadioLabelDefault = styled(StyledRadioLabel).attrs({
  color: "ciqBrandDark",
  theme: "default",
  variant: "body1",
  component: "label",
})``;

export const StyledRadioButtonLegacy = styled(Button)<{
  $keyboardFocus?: boolean;
}>`
  &${selector`.MuiButton-root`} {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    background-color: ${({ $keyboardFocus }) =>
      $keyboardFocus && colors.inputBorder};
  }
`;

// mostly copied from
// https://gitlab.ops.canceriq.com/ciq/engineering/umbrella/blob/master/apps/ithaca/src/components/cards/ciq-card.tsx#L49
export const StyledRadioButtonDefault = styled(Button)<{
  selected?: boolean;
  error?: boolean;
  $keyboardFocus?: boolean;
}>`
  &${selector`.MuiButton-root`} {
    align-self: stretch;
    display: flex;
    justify-content: flex-start;
    white-space: break-spaces;
    border: none;
    text-transform: none;
    padding: 1rem;
    flex-direction: column;
    outline: none;
    background-color: ${colors.white};
    border-radius: 0.5rem;
    box-shadow: ${({ selected, error }) =>
      error
        ? `0px 0px 0px 2px ${colors.error}`
        : selected
        ? `0px 0px 0px 2px ${colors.highlight}`
        : `0px 3px 1px -2px rgba(0,0,0,0.2),0px 2px 2px 0px rgba(0,0,0,0.14),0px 1px 5px 0px rgba(0,0,0,0.12)`};

    &:disabled {
      box-shadow: ${({ selected }) =>
        selected
          ? `0px 0px 0px 2px ${colors.highlight}`
          : `0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)`};
      background: ${colors.greyBright};
      cursor: default;
    }
    background-color: ${({ $keyboardFocus }) =>
      $keyboardFocus ? colors.inputBorder : "transparent"};

    &:focus {
      outline: none;
      background-color: ${colors.white};
    }

    &:hover {
      background-color: ${colors.white};
    }

    &:hover:active {
      background-color: ${colors.white};
      outline: none;
    }
  }
`;

import React, { useCallback, useEffect } from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { useFormContext, FieldValues } from "react-hook-form";
// import DateRangeIcon from "@material-ui/icons/DateRange";
import { Input, InputType } from "storybook/components/input";
import { ThemeType } from "storybook/utils/theme";
import "react-datepicker/dist/react-datepicker.css";
import { DateInputContainer } from "./styles";

const DEFAULT_DATE_FORMAT: string = "M/dd/yyyy";

export interface DateInputType<T> extends InputType<T> {
  theme: Extract<ThemeType["name"], "default">;
  dateFormat?: ReactDatePickerProps["dateFormat"];
  timeIntervals?: ReactDatePickerProps["timeIntervals"];
  showTimeSelect?: ReactDatePickerProps["showTimeSelect"];
  showMonthDropdown?: ReactDatePickerProps["showMonthDropdown"];
  showYearDropdown?: ReactDatePickerProps["showYearDropdown"];
  popperPlacement?: ReactDatePickerProps["popperPlacement"];
  onChange?: ReactDatePickerProps["onChange"];
  filterTime?: ReactDatePickerProps["filterTime"];
  minDate?: ReactDatePickerProps["minDate"];
  minTime?: ReactDatePickerProps["minTime"];
  maxTime?: ReactDatePickerProps["maxTime"];
}

// TODO: Return to calendar as clickable part of date input when we have time

// interface CustomInputType<T> extends InputType<T> {
//   onClick: () => void;
// }

// function CustomInput<T>({
//   name,
//   disabled,
//   onClick,
//   ...rest
// }: CustomInputType<T>) {
//   return (
//     <InputContainer>
//       <Input<T> name={name} disabled={disabled} {...rest} />
//       <IconContainer onClick={onClick}>{/* <DateRangeIcon /> */}</IconContainer>
//     </InputContainer>
//   );
// }

function DateInput<T>({
  dateFormat = DEFAULT_DATE_FORMAT,
  popperPlacement = "top-end",
  ...inputProps
}: DateInputType<T>) {
  // const [isOpen, setIsOpen] = React.useState<boolean>(false);
  const { setValue, getValues } = useFormContext();
  const {
    name,
    minTime,
    maxTime,
    minDate,
    disabled,
    onChange,
    filterTime,
    placeholder,
    timeIntervals,
    showTimeSelect,
    showMonthDropdown,
    showYearDropdown,
    ...rest
  } = inputProps;
  const selectedDate = getValues(name);

  const handleChange = useCallback(
    (date: Date, event) => {
      setValue(name, date as FieldValues[typeof name], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });

      if (onChange) {
        onChange(date, event);
      }
    },
    [name, setValue, onChange]
  );

  useEffect(() => {
    // On initial render, the Input component stringifies the Date object
    // it receives from RHF and displays this value, which changes to the formatted
    // date upon date selection. To ensure the initial value of the Input is correct,
    // it needs to be set. There's probably a better way to do this, but this
    // is what I came up with for now.
    const initialValue: Date = getValues(name);

    if (initialValue) {
      handleChange(initialValue, null);
    }
  }, [getValues, handleChange, name]);

  return (
    <DateInputContainer data-testid="DateInput">
      <DatePicker
        name={name}
        minTime={minTime}
        maxTime={maxTime}
        autoComplete="off"
        disabled={disabled}
        filterTime={filterTime}
        onChange={handleChange}
        dateFormat={dateFormat}
        selected={selectedDate}
        minDate={minDate}
        peekNextMonth={true}
        dropdownMode="select"
        showPopperArrow={false}
        placeholderText={placeholder}
        timeIntervals={timeIntervals}
        showTimeSelect={showTimeSelect}
        popperPlacement={popperPlacement}
        showMonthDropdown={showMonthDropdown}
        showYearDropdown={showYearDropdown}
        customInput={<Input<T> name={name} disabled={disabled} {...rest} />}
        customInputRef="innerRef"
      />
    </DateInputContainer>
  );
}

export { DateInput };

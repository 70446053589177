import React, { useEffect, useCallback } from "react";
import { z } from "zod";
import { Box } from "@material-ui/core";
import { Button } from "storybook/components/button";
import { Modal } from "storybook/components/modal";
import { Input } from "storybook/components/input";
import { Typography } from "storybook/components/typography";
import { InputGroup } from "storybook/components/input-group";
import { useForm } from "storybook/utils/form";
import { ModalType, usePatientInterest } from "context/patient-interest";
import { InputsContainer, ModalBodyWrapper } from "../styles";
import { FormSection } from "./";

type Props = {
  onClose: () => void;
  isSubmitting: boolean;
  onContinue: (data: unknown, formSection: FormSection) => void;
};

const errorText = "Please enter a valid email address";

const emailSchema = z
  .object({
    email: z.string().email({ message: errorText }).nullable().optional(),
    confirmEmail: z
      .string()
      .email({ message: errorText })
      .nullable()
      .optional(),
  })
  .refine(
    (data) => {
      if (!data.email && !data.confirmEmail) {
        return true;
      }

      if (data.email || data.confirmEmail) {
        return data.email === data.confirmEmail;
      }

      return true;
    },
    {
      message: "Email addresses do not match",
      path: ["confirmEmail"],
    }
  );

type Email = z.infer<typeof emailSchema>;

export function EmailForm({ onClose, isSubmitting, onContinue }: Props) {
  const [formObject, setFormObject] = React.useState<Email>({
    email: null,
    confirmEmail: null,
  });
  const { Form, reset, getValues } = useForm<Email>({
    schema: emailSchema,
    values: formObject,
    mode: "all",
  });

  const { setModalType } = usePatientInterest();

  const email = getValues("email");
  const confirmEmail = getValues("confirmEmail");

  const handleContinue = useCallback(
    (formData) => {
      setFormObject(formData);
      setModalType(ModalType.CALENDAR);

      onContinue(formData, FormSection.EMAIL);
    },
    [onContinue, setModalType]
  );

  useEffect(() => {
    // Workaround for an issue where clearing fields after are they have a
    // value caused them to still be invalid on submit, when they should be valid.
    if (!email && !confirmEmail) {
      reset();
    }
  }, [email, confirmEmail, reset]);

  return (
    <Form onSubmit={handleContinue}>
      <Modal.Body>
        <ModalBodyWrapper>
          <Typography theme="default" variant="body1" color="black">
            Please enter your email to receive appointment reminders and
            additional information.
          </Typography>
          <InputsContainer data-id="InputsContainer">
            <InputGroup
              theme="default"
              orientation="vertical"
              className="input-group"
              label="please provide your email address"
            >
              <Input
                type="email"
                name="email"
                label="Email"
                theme="default"
                hideLabel={true}
                alignText="left"
                autoComplete="off"
                className="input"
                hideDirty={true}
                hideErrorText={true}
                disabled={isSubmitting}
                placeholder="Patient Email"
              />
              <Input
                type="email"
                theme="default"
                alignText="left"
                hideLabel={true}
                autoComplete="off"
                name="confirmEmail"
                className="input"
                hideDirty={true}
                hideErrorText={true}
                label="Confirm Email"
                disabled={isSubmitting}
                placeholder="Confirm Email"
              />
            </InputGroup>
          </InputsContainer>
          <Box mb="-2rem">
            <Typography theme="default" variant="body1" color="black">
              Would you like to share your scheduling availibility with us?
            </Typography>
          </Box>
        </ModalBodyWrapper>
      </Modal.Body>
      <Modal.Actions alignButtons="center">
        <Button
          color="secondary"
          variant="contained"
          theme="default"
          onClick={onClose}
          fullWidth={true}
          disabled={isSubmitting}
        >
          No
        </Button>
        <Button
          type="submit"
          color="default"
          variant="contained"
          theme="default"
          fullWidth={true}
          disabled={isSubmitting}
        >
          Yes
        </Button>
      </Modal.Actions>
    </Form>
  );
}

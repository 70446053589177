import { useState, useContext, useEffect } from "react";
import { SPECIALIST_HOSTNAME } from "env-vars";
import { useAdminContent } from "context/admin-content";
import { KioskAuthContext } from "context/kiosk-auth";
import { PatientAuthContext } from "context/patient-auth";
import { KioskResults, PatientResults } from "context/admin-content/types";

enum RiskKey {
  testing = "testing",
  breastMri = "breastMri",
  counseling = "counseling",
  average = "average",
}

// TODO: Do we still need this now that we can fetch risk from the backend?
function getRiskKeyLocalStorage() {
  const testing = localStorage.getItem(
    "ciq-questionnaire.meets_testing_criteria"
  );
  const breastMri = localStorage.getItem(
    "ciq-questionnaire.meets_mri_criteria"
  );
  const counseling = localStorage.getItem(
    "ciq-questionnaire.meets_counseling_criteria"
  );

  if (testing === "true") {
    return RiskKey.testing;
  }
  if (breastMri === "true") {
    return RiskKey.breastMri;
  }
  if (counseling === "true") {
    return RiskKey.counseling;
  }

  return RiskKey.average;
}

export function useRiskResultsKiosk(): KioskResults | null {
  const kioskAuthContext = useContext(KioskAuthContext);
  const adminData = useAdminContent();

  if (!kioskAuthContext) {
    throw new Error("This hook must be used within a KioskAuthProvider");
  }

  if (!adminData?.kioskResults) {
    return null;
  }

  // TODO: useRiskRequest instead?
  const riskKey = getRiskKeyLocalStorage();

  return adminData.kioskResults[riskKey];
}

// For patient logged in journeys, we retrieve risk from the backend
// instead of using local storage values set by Questionnaire.
function useRiskRequest() {
  const patientAuthContext = useContext(PatientAuthContext);
  const [riskKey, setRiskKey] = useState<RiskKey | undefined>();

  useEffect(() => {
    if (!patientAuthContext) {
      return;
    }

    (async () => {
      const patientId = localStorage.getItem("ciq_id");
      const idToken = localStorage.getItem("id_token");

      try {
        const response = await fetch(
          `${SPECIALIST_HOSTNAME}/api/v1/risk_profiles/${patientId}`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
          }
        );

        if (!response.ok) {
          // TODO: Error behaviour? It's a silent call so maybe do not show error to user

          return;
        }

        const riskData = await response.json();
        const guidances = riskData.data.attributes;
        const { testingGuidance, mriGuidance, counselingGuidance } = guidances;

        if (testingGuidance.meets_criteria) {
          setRiskKey(RiskKey.testing);
        } else if (mriGuidance.meets_criteria) {
          setRiskKey(RiskKey.breastMri);
        } else if (counselingGuidance.meets_criteria) {
          setRiskKey(RiskKey.counseling);
        } else {
          setRiskKey(RiskKey.average);
        }
      } catch (error) {
        // What to do?
      }
    })();
    // We only want to run this once and we expect
    // patientAuthContext to be an object if a patient is logged in.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { riskKey };
}

export function useRiskResultsPatient(): PatientResults | null {
  const patientAuthContext = useContext(PatientAuthContext);
  const adminData = useAdminContent();
  const { riskKey } = useRiskRequest();

  if (!patientAuthContext) {
    throw new Error("This hook must be used within a PatientAuthProvider");
  }

  if (!adminData?.patientResults) {
    return null;
  }

  if (!riskKey) {
    return null;
  }

  return adminData.patientResults[riskKey];
}

export function useRiskResults(): any {
  const kioskAuthContext = useContext(KioskAuthContext);
  const patientAuthContext = useContext(PatientAuthContext);
  const adminData = useAdminContent();
  const { riskKey } = useRiskRequest();

  if (!patientAuthContext && !kioskAuthContext) {
    throw new Error(
      "This hook must be used within a KioskAuthContext or PatientAuthProvider"
    );
  }

  if (kioskAuthContext && adminData?.kioskResults) {
    return adminData.kioskResults[getRiskKeyLocalStorage()];
  }
  if (patientAuthContext && adminData?.patientResults && riskKey) {
    return adminData.patientResults[riskKey];
  }

  return null;
}

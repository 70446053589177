import { CSSProperties } from "react";

type CSSLengthType = CSSProperties["width"] | CSSProperties["height"];

/**
 * If value is not a number, assume it's a valid CSS property.
 * If it's a number, assume it's pixels and return as this.
 * Otherwise it's 'auto'.
 */
export function sanitizeCSSLength(value: CSSLengthType) {
  if (value === undefined || value === "") return "auto";

  // The casting to a number first here is due to the fact that
  // the number option in the story is always a string.
  // Ideally we'd find a way in Storybook to make the type a number.
  if (Number.isInteger(Number(value))) {
    return `${value}px`;
  }

  return value;
}

type ValueType = number | string | undefined;
type PositionValue = "top" | "right" | "bottom" | "left";
type CSSPositionType = CSSProperties[PositionValue];

export function getCSSPosition(value: ValueType): CSSPositionType {
  if (value === undefined) return "auto";

  if (Number.isInteger(value)) {
    return `${value}px`;
  }

  return value;
}

export const checkProtocol = (url: string | undefined) =>
  /^((http|https):\/\/)/i.test(url || "");

/* @ts-ignore no type exists for this ancient property */
export const isIE11 = (): boolean => !!window.msCrypto;

import styled from "styled-components";
import { mobile, tablet } from "styles/breakpoints";
import { Typography } from "storybook/components/typography";
import { defaultTheme } from "storybook/themes/default";

const { colors } = defaultTheme;

export const HeadingContainer = styled.div`
  width: 100%;
  margin-top: 16px;
  background-color: ${colors.zircon};
`;

export const SectionNumber = styled(Typography).attrs({
  // @ts-ignore cannot resolve against default theme
  theme: "default",
})`
  && {
    font-weight: 700;
  }
  margin-right: 0.5rem;
`;

export const SectionHeading = styled.section`
  width: 100%;
  padding: 0 16px;
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
  justify-content: flex-start;
  align-items: center;

  @media (max-width: ${mobile}px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const SectionContent = styled.section<{
  $scrollbarWidth: number;
  $scrollbarVisible: boolean;
}>`
  width: 100%;
  display: flex;
  max-width: 1200px;
  margin: 16px auto 0 auto;
  align-items: flex-start;
  flex-direction: column;
  padding-top: 0;
  padding-right: ${({ $scrollbarVisible, $scrollbarWidth }) =>
    $scrollbarVisible ? 0 : $scrollbarWidth}px;
  padding-bottom: 0;
  padding-left: 16px;

  > :not(:last-child) {
    margin-bottom: 16px;
  }
`;

export const RiskSectionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  gap: 16px;

  @media (max-width: ${tablet}px) {
    flex-direction: column;
  }
`;

export const RiskSection = styled.div`
  border: 1px solid ${colors.grey};
  border-radius: 5px;
  width: 100%;
  padding: 10px 23px;
`;

export const RiskList = styled.ul`
  margin-top: 10px;
  padding-left: 20px;
  list-style-type: none;
  padding: 0;
`;

export const RiskListItemIncreased = styled.li`
  font-size: 14px;
  line-height: 24px;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  padding-left: 26px;
  margin-bottom: 5px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTIiIGhlaWdodD0iMTIiIHZpZXdCb3g9IjAgMCAxMiAxMiIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KICA8ZyBjbGlwLXBhdGg9InVybCgjY2xpcDBfMjM1MF81Nzc1KSI+CiAgICA8cGF0aCBkPSJNNiAxMEMzLjc5NSAxMCAyIDguMjA1IDIgNkMyIDMuNzk1IDMuNzk1IDIgNiAyQzguMjA1IDIgMTAgMy43OTUgMTAgNkMxMCA4LjIwNSA4LjIwNSAxMCA2IDEwWk02IDExQzguNzYgMTEgMTEgOC43NiAxMSA2QzExIDMuMjQgOC43NiAxIDYgMUMzLjI0IDEgMSAzLjI0IDEgNkMxIDguNzYgMy4yNCAxMSA2IDExWk01LjUgNlY4SDYuNVY2SDhMNiA0TDQgNkg1LjVaIiBmaWxsPSIjQUYwMjFDIi8+CiAgPC9nPgogIDxkZWZzPgogIDxjbGlwUGF0aCBpZD0iY2xpcDBfMjM1MF81Nzc1Ij4KICAgIDxyZWN0IHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgZmlsbD0id2hpdGUiLz4KICA8L2NsaXBQYXRoPgogIDwvZGVmcz4KPC9zdmc+Cg==");
`;

export const RiskListItemDecreased = styled.li`
  font-size: 14px;
  line-height: 24px;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  padding-left: 26px;
  margin-bottom: 5px;
  background-image: url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxMiIgaGVpZ2h0PSIxMiIgdmlld0JveD0iMCAwIDEyIDEyIiBmaWxsPSJub25lIj4KICA8cGF0aCBkPSJNNiAyQzMuNzk1IDIgMiAzLjc5NSAyIDZDMiA4LjIwNSAzLjc5NSAxMCA2IDEwQzguMjA1IDEwIDEwIDguMjA1IDEwIDZDMTAgMy43OTUgOC4yMDUgMiA2IDJaTTYgMUM4Ljc2IDEgMTEgMy4yNCAxMSA2QzExIDguNzYgOC43NiAxMSA2IDExQzMuMjQgMTEgMSA4Ljc2IDEgNkMxIDMuMjQgMy4yNCAxIDYgMVpNNS41IDZWNEg2LjVWNkg4TDYgOEw0IDZINS41WiIgZmlsbD0iIzQ3OEMzQiIvPgo8L3N2Zz4=");
`;

export const TooltipContainer = styled.span`
  vertical-align: middle;
`;

import React, { ReactElement } from "react";
import { ThemeProvider } from "styled-components";
import { Theme } from "@material-ui/core/styles";
import { MuiThemeProvider } from "@material-ui/core/styles";
import { ThemeType } from "storybook/utils/theme";
import { defaultTheme } from "storybook/themes/default";
import { legacyTheme } from "storybook/themes/legacy";
import { futureTheme } from "storybook/themes/future";

const themeMap = {
  default: defaultTheme,
  legacy: legacyTheme,
  future: futureTheme,
};

export interface ComponentThemeProviderProps {
  theme: ThemeType["name"];
  children: React.ReactNode;
}

export function ComponentThemeProvider({
  children,
  theme,
}: ComponentThemeProviderProps): ReactElement {
  const activeTheme = themeMap[theme];

  return (
    <MuiThemeProvider theme={activeTheme as Theme}>
      <ThemeProvider theme={activeTheme}>{children}</ThemeProvider>
    </MuiThemeProvider>
  );
}

import React from "react";
// @ts-ignore package has no types
import HtmlParser from "react-html-parser";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Typography } from "storybook/components/typography";
import { Tooltip } from "storybook/components/tooltip";
import { Accordion } from "storybook/components/accordion";
import { Loading } from "components/loading";
import { useAdminContent } from "context/admin-content";
import { useScrollbar } from "context/scrollbar";
import { useRiskReport } from "../use-risk-report";

import {
  HeadingContainer,
  SectionNumber,
  SectionHeading,
  SectionContent,
  RiskSectionContainer,
  RiskSection,
  RiskList,
  RiskListItemIncreased,
  RiskListItemDecreased,
  TooltipContainer,
} from "./styles";

import { ReportContainer, Content } from "../styles";

const IncreasedRiskTooltip = () => {
  return (
    <>
      <Typography theme="default" variant="h4" color="black">
        Hereditary cancer
      </Typography>
      <Typography theme="default" variant="body1" color="black">
        Hereditary cancer is a type of cancer that runs in some families.
        Collaborate with our specialist to create a personalized cancer
        prevention plan tailored to your hereditary cancer risk.
      </Typography>
    </>
  );
};

export const PatientResultsDisplay2 = () => {
  const adminData = useAdminContent();
  const { scrollbarVisible, scrollbarWidth } = useScrollbar();
  const { reportHtml, reportData, isLoading: reportLoading } = useRiskReport();

  const isLoading = adminData?.isLoading || reportLoading;

  if (isLoading) {
    return (
      <Content>
        <Loading />
      </Content>
    );
  }

  const {
    has_cancer: hasCancer,
    cancers_and_dx,
    lifestyle,
    vitals,
    reproductive_risk_factors,
    increased_risk_detected,
  } = reportData;

  const cancers: Array<any> = cancers_and_dx;

  const hasExerciseRisk =
    lifestyle.exercise_days && lifestyle.exercise_total < 75;

  const hasSmokingRisk = lifestyle.current_smoker || lifestyle.pack_years > 0;

  const hasVegDietaryRisk =
    lifestyle.veg_servings !== null &&
    lifestyle.veg_servings_s !== "More Than 4" &&
    lifestyle.veg_servings_s !== "N/A" &&
    lifestyle.veg_servings < 4;

  const hasRedMeatDietaryRisk =
    lifestyle.red_meat_servings && lifestyle.red_meat_servings > 6;

  const hasBmiRisk = vitals.bmi && vitals.bmi >= 25;

  const hasIncreasedRisk =
    hasCancer ||
    hasExerciseRisk ||
    hasSmokingRisk ||
    lifestyle.exceeds_alcohol_guidelines ||
    hasVegDietaryRisk ||
    hasRedMeatDietaryRisk ||
    hasBmiRisk;

  const hasDecreasedExerciseRisk =
    lifestyle.exercise_days && lifestyle.exercise_total >= 75;

  const hasDecreasedSmokingRisk =
    !lifestyle.current_smoker && !lifestyle.pack_years;

  const hasDecreasedVegDietaryRisk =
    lifestyle.veg_servings_s === "More Than 4" || lifestyle.veg_servings >= 4;

  const hasDecreasedRedMeatDietaryRisk =
    lifestyle.red_meat_servings && lifestyle.red_meat_servings <= 6;

  const hasDecreasedBmiRisk =
    vitals.bmi && vitals.bmi >= 18.5 && vitals.bmi <= 24.9;

  const hasDecreasedRisk =
    reproductive_risk_factors.age_first_birth ||
    !increased_risk_detected ||
    hasDecreasedExerciseRisk ||
    hasDecreasedSmokingRisk ||
    !lifestyle.current_drinker ||
    hasDecreasedVegDietaryRisk ||
    hasDecreasedRedMeatDietaryRisk ||
    hasDecreasedBmiRisk;

  const Report = () => (
    <ReportContainer role="article">
      {HtmlParser(reportHtml, {
        transform: (node: any) =>
          node.attribs?.id === "page2" ? null : undefined,
      })}
    </ReportContainer>
  );

  return (
    <>
      <HeadingContainer data-testid="PatientResultsDisplay2">
        <SectionHeading>
          <SectionNumber variant="h4" color="governorBay">
            1
          </SectionNumber>
          <Typography theme="default" variant="body1" color="governorBay">
            Risk Results
          </Typography>
        </SectionHeading>
      </HeadingContainer>
      <SectionContent
        $scrollbarWidth={scrollbarWidth}
        $scrollbarVisible={scrollbarVisible}
      >
        <Typography theme="default" variant="h4" color="black">
          Your personalized risk assessment
        </Typography>
        {increased_risk_detected ? (
          <Typography theme="default" variant="h4" color="monza">
            Increased Risk Detected
          </Typography>
        ) : (
          <Typography theme="default" variant="h4" color="apple">
            Average Risk Detected
          </Typography>
        )}
        {increased_risk_detected ? (
          <Typography theme="default" variant="body1" color="black">
            Thank you for completing the assessment!
            <br />
            <b>
              Based on your personal and family history, we recommend that you
              meet with our specialist to identify ways to manage and reduce
              your cancer risk.
            </b>
            <TooltipContainer>
              <Tooltip
                placement="left"
                theme="default"
                title={<IncreasedRiskTooltip />}
              >
                <InfoOutlinedIcon />
              </Tooltip>
            </TooltipContainer>
          </Typography>
        ) : (
          <Typography theme="default" variant="body1" color="black">
            Bring these results to your next doctor's appointment to create a
            personalized cancer prevention plan.
          </Typography>
        )}
        <RiskSectionContainer>
          <RiskSection>
            <Typography theme="default" variant="body1" color="ciqBrandDark">
              <b>Factors increasing your cancer risk</b>
            </Typography>
            {hasIncreasedRisk ? (
              <RiskList>
                {hasCancer &&
                  cancers.map((cancer) => (
                    <RiskListItemIncreased key={cancer.type}>
                      {cancer.type} Cancer
                      {cancer.age && <> at age {cancer.age}</>}
                    </RiskListItemIncreased>
                  ))}
                {hasExerciseRisk && (
                  <RiskListItemIncreased>
                    Total exercise of {lifestyle.exercise_total} minutes/week
                    below the recommended 75 minutes/week
                  </RiskListItemIncreased>
                )}
                {hasSmokingRisk && (
                  <RiskListItemIncreased>
                    {lifestyle.pack_years
                      ? `Smoker: has smoked for ${lifestyle.pack_years} years`
                      : "Smoker"}
                  </RiskListItemIncreased>
                )}
                {lifestyle.exceeds_alcohol_guidelines && (
                  <RiskListItemIncreased>
                    {`Drinking exceeds the recommended maximum of ${lifestyle.recommended_drinks} drinks per week`}
                  </RiskListItemIncreased>
                )}
                {hasVegDietaryRisk && (
                  <RiskListItemIncreased>
                    {`Fruit/Veg: only ${lifestyle.veg_servings} servings perday`}
                  </RiskListItemIncreased>
                )}
                {hasRedMeatDietaryRisk && (
                  <RiskListItemIncreased>
                    {`Red Meat: ${lifestyle.red_meat_servings} servings per week`}
                  </RiskListItemIncreased>
                )}
                {hasBmiRisk && (
                  <RiskListItemIncreased>
                    {`BMI: ${vitals.bmi}`}
                  </RiskListItemIncreased>
                )}
              </RiskList>
            ) : (
              <p>N/A</p>
            )}
          </RiskSection>
          <RiskSection>
            <Typography theme="default" variant="body1" color="ciqBrandDark">
              <b>Factors decreasing your cancer risk</b>
            </Typography>
            {hasDecreasedRisk ? (
              <RiskList>
                {reproductive_risk_factors.age_first_birth && (
                  <RiskListItemDecreased>
                    You have given birth
                  </RiskListItemDecreased>
                )}
                {!increased_risk_detected && (
                  <RiskListItemDecreased>
                    Up to date on recommended cancer screenings
                  </RiskListItemDecreased>
                )}
                {hasDecreasedExerciseRisk && (
                  <RiskListItemDecreased>
                    You are physically active
                  </RiskListItemDecreased>
                )}
                {hasDecreasedSmokingRisk && (
                  <RiskListItemDecreased>
                    You do not smoke
                  </RiskListItemDecreased>
                )}
                {!lifestyle.current_drinker && (
                  <RiskListItemDecreased>
                    You do not drink alcohol
                  </RiskListItemDecreased>
                )}
                {lifestyle.veg_servings >= 4 && (
                  <RiskListItemDecreased>
                    {`Fruit/Veg: ${lifestyle.veg_servings} servings per day`}
                  </RiskListItemDecreased>
                )}
                {lifestyle.veg_servings_s === "More Than 4" && (
                  <RiskListItemDecreased>
                    Fruit/Veg: More than 4 servings/day
                  </RiskListItemDecreased>
                )}
                {hasDecreasedRedMeatDietaryRisk && (
                  <RiskListItemDecreased>
                    {`Red Meat: ${lifestyle.red_meat_servings} servings per week`}
                  </RiskListItemDecreased>
                )}
                {hasDecreasedBmiRisk && (
                  <RiskListItemDecreased>
                    {`BMI: ${vitals.bmi}`}
                  </RiskListItemDecreased>
                )}
              </RiskList>
            ) : (
              <p>N/A</p>
            )}
          </RiskSection>
        </RiskSectionContainer>
        <Accordion
          theme="default"
          title="Report Preview"
          previewContent={<Report />}
        >
          <Report />
        </Accordion>
      </SectionContent>
    </>
  );
};

import styled from "styled-components";
import { Typography } from "storybook/components/typography";
import { FieldErrorDefault } from "storybook/components/field-error";
import { defaultTheme } from "storybook/themes/default";
import { selector } from "storybook/utils/selector";

const { colors } = defaultTheme;

type InputLabelDefaultProps = {
  $hasError: boolean;
  $isDirty: boolean;
  $hideLabel: boolean;
  $disabled: boolean;
  $alignText: string;
  $hideDirty: boolean;
};

export const InputLabelDefault = styled(Typography).attrs(
  ({ $isDirty, $hasError, $hideDirty }: InputLabelDefaultProps) => ({
    color:
      $isDirty && !$hideDirty && !$hasError
        ? "dirtyLabel"
        : "defaultTextButton",
    theme: "default",
    variant: "subtitle1",
    component: "label",
  })
)`
  display: flex;
  flex: 1;
  align-items: center;
  > * {
    flex: 0 0
      ${({ $hideLabel }: InputLabelDefaultProps) => ($hideLabel ? 100 : 50)}%;
  }

  ${selector`.MuiInputBase-root`} {
    font-weight: 700; // Required by Ithaca
    font-variation-settings: "wght" 700; // Required by Storybook
    padding: 0.5rem 1rem;
    border-radius: 8px;
    height: 37px;

    ${selector`.MuiOutlinedInput-notchedOutline`} {
      border: 1px solid
        ${({ $hasError }: InputLabelDefaultProps) =>
          $hasError ? colors.error : colors.inputBorder};
    }

    &${selector`.Mui-focused`}, &:hover {
      ${selector`.MuiOutlinedInput-notchedOutline`} {
        border-color: ${({ $hasError, $disabled }: InputLabelDefaultProps) => {
          if ($disabled) return "auto";

          return $hasError ? colors.error : colors.defaultTextButton;
        }};
      }

      &${selector`.Mui-focused`} ${selector`.MuiOutlinedInput-notchedOutline`} {
        border: 2px solid
          ${({ $hasError }: InputLabelDefaultProps) =>
            $hasError ? colors.error : colors.ciqBrand};
      }
    }

    ${selector`.MuiOutlinedInput-input`} {
      padding: 0;
      text-align: ${({ $alignText }) => $alignText};
      color: ${({ $hasError, $isDirty, $hideDirty }: InputLabelDefaultProps) =>
        $hasError
          ? colors.error
          : $isDirty && !$hideDirty
          ? colors.dirtyLabel
          : colors.ciqBrand};
    }

    input[type="number"] + ${selector`.MuiInputAdornment-positionEnd`} {
      margin-left: 0;
    }

    // IE11
    *::-ms-backdrop,
    & {
      border: 1px solid rgba(0, 0, 0, 0.26);
    }

    // IE11
    &:hover {
      *::-ms-backdrop,
      & {
        border-color: ${({ $hasError, $disabled }: InputLabelDefaultProps) => {
          if ($disabled) return "auto";

          return $hasError ? colors.error : colors.defaultTextButton;
        }};
      }
    }

    // IE11
    &${selector`.Mui-focused`} {
      *::-ms-backdrop,
      & {
        border: 2px solid
          ${({ $hasError }: InputLabelDefaultProps) =>
            $hasError ? colors.error : colors.ciqBrand};
      }
    }

    // IE11
    fieldset {
      *::-ms-backdrop,
      & {
        border: none !important;
      }
    }
  }
`;

export const StyledInputWithLabelInside = styled.div<{
  $hasError: boolean;
}>`
  * {
    font-family: ${defaultTheme.typography.fontFamily};
  }

  ${selector`.MuiFormControl-marginDense`} {
    margin: 0;
  }

  ${selector`.MuiInputBase-root`} {
    height: 37px;
    border-radius: 8px;
  }

  ${selector`.MuiInputLabel-outlined`} {
    color: ${({ $hasError }) => ($hasError ? colors.error : colors.ciqBrand)};
  }

  ${selector`.Mui-focused`} {
    ${selector`.MuiOutlinedInput-notchedOutline`} {
      border-color: ${({ $hasError }) =>
        $hasError ? colors.error : colors.ciqBrand};

      ${selector`.PrivateNotchedOutline-legendLabelled-3`} {
        color: ${colors.ciqBrand};
      }
    }
  }
`;

const StyledError = styled(FieldErrorDefault)`
  // I tried adding a "subtitle3" variant to Typography but MUI wouldn't allow it.
  // Another reason to get rid of MUI.
  font-size: 12px !important;
`;

export const StyledErrorWithLabelOutside = styled(StyledError)<{
  $alignText: string;
}>`
  margin-top: 5px;
  text-align: ${({ $alignText }) => $alignText};
`;

export const StyledErrorWithLabelInside = styled(StyledError)`
  margin: 5px 0 0 14px;
`;

export function CloseIcon() {
  return (
    <svg
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
      aria-label="close icon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_641_442"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="4"
        y="4"
        width="12"
        height="12"
      >
        <path
          d="M15.8333 5.34166L14.6583 4.16666L10 8.82499L5.34167 4.16666L4.16667 5.34166L8.82501 9.99999L4.16667 14.6583L5.34167 15.8333L10 11.175L14.6583 15.8333L15.8333 14.6583L11.175 9.99999L15.8333 5.34166Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_641_442)">
        <rect width="20" height="20" fill="black" fillOpacity="0.87" />
      </g>
    </svg>
  );
}

import { useState, useCallback } from "react";
import { Link } from "react-router-dom";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import { AccountIcon } from "./account-icon";
import { usePatientAuth } from "context/patient-auth";
import { useScrollbar } from "context/scrollbar";
import {
  Container,
  NavContent,
  AccountText,
  NavMenuItem,
  HomeMenuItem,
  NavSectionLeft,
  MenuButtonIcon,
  NavMenuItemLink,
  MenuContainer,
} from "./styles";

export function Header() {
  const { logout } = usePatientAuth();
  const [anchorEl, setAnchorEl] = useState(null);
  const { scrollbarVisible, scrollbarWidth } = useScrollbar();

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);

  const handleLogout = useCallback(() => {
    handleClose();
    logout();
  }, [handleClose, logout]);

  return (
    <Container
      $scrollbarWidth={scrollbarWidth}
      $scrollbarVisible={scrollbarVisible}
    >
      <NavContent>
        <NavSectionLeft>
          <Link to="/">
            <img alt="CancerIQ patient logo" src="/ciq-logo.png" width="135" />
          </Link>
        </NavSectionLeft>
        <MenuContainer>
          <MenuButtonIcon
            onClick={handleClick}
            disableFocusRipple={true}
            disableRipple={true}
          >
            <AccountIcon />
            <AccountText>Account</AccountText>
          </MenuButtonIcon>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            getContentAnchorEl={null}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <HomeMenuItem className="home">
              <NavMenuItemLink to="/self-assessment">Home</NavMenuItemLink>
            </HomeMenuItem>
            <MenuItem>
              <NavMenuItemLink to="/settings">Edit Account</NavMenuItemLink>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
              <NavMenuItem>
                <ExitToAppIcon /> Log out
              </NavMenuItem>
            </MenuItem>
          </Menu>
        </MenuContainer>
      </NavContent>
    </Container>
  );
}

export function LifestyleIcon() {
  return (
    <svg
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1822_18048)">
        <path
          d="M15.6777 5.75C16.7777 5.75 17.6777 4.85 17.6777 3.75C17.6777 2.65 16.7777 1.75 15.6777 1.75C14.5777 1.75 13.6777 2.65 13.6777 3.75C13.6777 4.85 14.5777 5.75 15.6777 5.75ZM5.17773 12.25C2.37773 12.25 0.177734 14.45 0.177734 17.25C0.177734 20.05 2.37773 22.25 5.17773 22.25C7.97773 22.25 10.1777 20.05 10.1777 17.25C10.1777 14.45 7.97773 12.25 5.17773 12.25ZM5.17773 20.75C3.27773 20.75 1.67773 19.15 1.67773 17.25C1.67773 15.35 3.27773 13.75 5.17773 13.75C7.07773 13.75 8.67773 15.35 8.67773 17.25C8.67773 19.15 7.07773 20.75 5.17773 20.75ZM10.9777 10.75L13.3777 8.35L14.1777 9.15C15.4777 10.45 17.1777 11.25 19.2777 11.25V9.25C17.7777 9.25 16.5777 8.65 15.6777 7.75L13.7777 5.85C13.2777 5.45 12.7777 5.25 12.1777 5.25C11.5777 5.25 11.0777 5.45 10.7777 5.85L7.97773 8.65C7.57773 9.05 7.37773 9.55 7.37773 10.05C7.37773 10.65 7.57773 11.15 7.97773 11.45L11.1777 14.25V19.25H13.1777V13.05L10.9777 10.75ZM19.1777 12.25C16.3777 12.25 14.1777 14.45 14.1777 17.25C14.1777 20.05 16.3777 22.25 19.1777 22.25C21.9777 22.25 24.1777 20.05 24.1777 17.25C24.1777 14.45 21.9777 12.25 19.1777 12.25ZM19.1777 20.75C17.2777 20.75 15.6777 19.15 15.6777 17.25C15.6777 15.35 17.2777 13.75 19.1777 13.75C21.0777 13.75 22.6777 15.35 22.6777 17.25C22.6777 19.15 21.0777 20.75 19.1777 20.75Z"
          fill="black"
        />
      </g>
      <defs>
        <clipPath id="clip0_1822_18048">
          <rect
            width="24"
            height="24"
            fill="white"
            transform="translate(0.177734)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}

import React, { useCallback } from "react";
import type { StyledComponent } from "styled-components";
import CloseIcon from "@material-ui/icons/Close";
import { useTheme } from "@material-ui/styles";
import { ComponentThemeProvider } from "storybook/context/component-theme";
import { ThemeType } from "storybook/utils/theme";
import {
  ModalProps,
  ModalHeadProps,
  CloseReasonType,
  ModalActionsProps,
  ModalSubComponentProps,
} from "./types";
import {
  StyledModalDefault,
  StyledModalHeadDefault,
  StyledModalBodyDefault,
  StyledModalActionsDefault,
  StyledCloseButtonDefault,
} from "./theme/default";
import {
  StyledModalLegacy,
  StyledModalHeadLegacy,
  StyledModalBodyLegacy,
  StyledModalActionsLegacy,
  StyledCloseButtonLegacy,
} from "./theme/legacy";
import {
  StyledModalFuture,
  StyledModalHeadFuture,
  StyledModalBodyFuture,
  StyledModalActionsFuture,
  StyledCloseButtonFuture,
} from "./theme/future";

type StyledComponentMap = Record<ThemeType["name"], StyledComponent<any, any>>;

const styledModalMap: StyledComponentMap = {
  default: StyledModalDefault,
  legacy: StyledModalLegacy,
  future: StyledModalFuture,
};

const styledModalHeadMap: StyledComponentMap = {
  default: StyledModalHeadDefault,
  legacy: StyledModalHeadLegacy,
  future: StyledModalHeadFuture,
};

const styledModalBodyMap: StyledComponentMap = {
  default: StyledModalBodyDefault,
  legacy: StyledModalBodyLegacy,
  future: StyledModalBodyFuture,
};

const styledModalActionsMap: StyledComponentMap = {
  default: StyledModalActionsDefault,
  legacy: StyledModalActionsLegacy,
  future: StyledModalActionsFuture,
};

const styledCloseButtonMap: StyledComponentMap = {
  default: StyledCloseButtonDefault,
  legacy: StyledCloseButtonLegacy,
  future: StyledCloseButtonFuture,
};

function ModalHead({
  children,
  alignTitle = "center",
}: ModalHeadProps): JSX.Element {
  const theme: ThemeType = useTheme();
  const StyledModalHead = styledModalHeadMap[theme.name];

  return (
    <StyledModalHead data-testid="Modal__Head" $alignTitle={alignTitle}>
      {children}
    </StyledModalHead>
  );
}

function ModalBody({ children }: ModalSubComponentProps): JSX.Element {
  const theme: ThemeType = useTheme();
  const StyledModalBody = styledModalBodyMap[theme.name];

  return (
    <StyledModalBody data-testid="Modal__Body">{children}</StyledModalBody>
  );
}

function ModalActions({
  children,
  alignButtons,
}: ModalActionsProps): JSX.Element {
  const theme: ThemeType = useTheme();
  const StyledModalActions = styledModalActionsMap[theme.name];

  return (
    <StyledModalActions
      data-testid="Modal__Actions"
      $alignButtons={alignButtons}
    >
      {children}
    </StyledModalActions>
  );
}

function Modal({
  open,
  theme,
  padding,
  margin,
  onClose,
  width,
  height,
  children,
  fullScreen,
  hideBackdrop,
  verticalAlign,
  hideCloseButton,
  backgroundColor,
  disableBackdropClick,
}: ModalProps): JSX.Element {
  const StyledModal = styledModalMap[theme];
  const StyledCloseButton = styledCloseButtonMap[theme];

  const handleClose = useCallback(
    (event: React.MouseEvent, reason: CloseReasonType) => {
      if ((fullScreen || disableBackdropClick) && reason === "backdropClick") {
        return;
      }

      if (onClose) {
        onClose(event);
      }
    },
    [disableBackdropClick, fullScreen, onClose]
  );

  return (
    <ComponentThemeProvider theme={theme}>
      <StyledModal
        open={open}
        $width={width}
        data-testid="Modal"
        $height={height}
        $padding={padding}
        $margin={margin}
        onClose={handleClose}
        $fullScreen={fullScreen}
        $verticalAlign={verticalAlign}
        $backgroundColor={backgroundColor}
        hideBackdrop={!fullScreen && hideBackdrop}
        // The assumption is made here that in full screen mode, we wouldn't
        // have a backdrop to disable clicking on, so pressing escape is always
        // possible in full screen mode. If we want to change this, we'd need
        // to de-couple "disableBackdropClick" and "disableEscapeKeyDown".
        disableEscapeKeyDown={!fullScreen && disableBackdropClick}
      >
        {!hideCloseButton && (
          <StyledCloseButton
            onClick={onClose}
            aria-label="close"
            className="close-btn"
            $fullScreen={fullScreen}
          >
            <CloseIcon />
          </StyledCloseButton>
        )}
        {children}
      </StyledModal>
    </ComponentThemeProvider>
  );
}

ModalHead.displayName = "Modal.Head";
ModalBody.displayName = "Modal.Body";
ModalActions.displayName = "Modal.Actions";

Modal.Head = ModalHead;
Modal.Body = ModalBody;
Modal.Actions = ModalActions;

export { Modal };

export type { ModalProps };

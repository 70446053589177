import styled from "styled-components";
import Popper from "@material-ui/core/Popper";
import { VisuallyHide } from "storybook/styles/common";
import { StyledDownArray } from "storybook/styles/legacy";
import { Typography } from "storybook/components/typography";
import { legacyTheme } from "storybook/themes/legacy";

const { colors } = legacyTheme;

export const FontWrapperLegacy = styled(Typography).attrs({
  theme: "legacy",
  variant: "body1",
  component: "div",
})``;

export const StyledSelectWrapperLegacy = styled.div`
  position: relative;
`;

export const StyledSelectedItemLegacy = styled.div`
  overflow: hidden;
  text-align: left;
  text-overflow: ellipsis;
`;

export const StyledPlaceholderLegacy = styled.div<{ $focused: boolean }>`
  text-align: left;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledSelectLegacy = styled.div<{
  $focused: boolean;
  $native: boolean;
  $disabled: boolean;
  $invert?: boolean;
  $hideLabel: boolean;
  $fitContent: boolean;
  $error: boolean;
}>`
  height: 34px;
  display: inline-block;
  border-radius: 3px;
  padding: 6px 24px 6px 12px;
  box-sizing: border-box;
  user-select: none;
  white-space: nowrap;
  text-align: center;
  position: relative;
  transition: all 0.5s;
  width: ${({ $hideLabel, $fitContent }) => {
    if ($fitContent) {
      return "auto";
    }

    return $hideLabel ? "100%" : "50%";
  }};
  border: 1px solid
    ${({ $invert, $error }) => {
      if ($error) {
        return colors.red;
      }

      return $invert ? colors.white : colors.grey;
    }};
  cursor: ${({ $disabled }) => ($disabled ? "default" : "pointer")};
  color: ${({ $focused, $invert }) =>
    $focused || $invert ? colors.white : colors.greyDarkest};
  background-color: ${({ $focused }) =>
    $focused ? colors.greyDark : "transparent"};
  ${({ $disabled }) => $disabled && "opacity: 0.7;"}

  ${({ $disabled }) =>
    !$disabled &&
    `
    &:hover,
    &:focus {
      color: ${colors.white};
      border-color: ${colors.greyDark};
      background-color: ${colors.greyDark};

      ${StyledDownArray}:after {
        border-top: 4px solid ${colors.white};
      }
    }
  `}

  ${StyledDownArray}:after {
    top: 14px;
    right: 12px;
    border-top: 4px solid
      ${({ $focused, $invert }) =>
        $focused || $invert ? colors.white : colors.greyDarkest};
  }
`;

export const StyledNativeSelect = styled.select<{
  $hideLabel?: boolean;
  $disabled?: boolean;
  $error: boolean;
}>`
  width: ${({ $hideLabel }) => ($hideLabel ? "100%" : "50%")};
  border: 1px solid ${({ $error }) => ($error ? colors.red : colors.iron)};
  font-family: ${legacyTheme.typography.fontFamily};
  color: ${colors.greyDarkest};
  border-radius: 1px;
  outline: none;
  padding: 7px 12px;

  ${({ $disabled }) =>
    !$disabled &&
    `
    &:hover,
    &:focus {
      border-color: #1ab394;
    }
  `}
`;

export const StyledLabelLegacy = styled.label<{
  $hide: boolean;
  $native: boolean;
  $invert?: boolean;
}>`
  vertical-align: ${({ $native }) => ($native ? "unset" : "super")};
  color: ${({ $invert }) => ($invert ? colors.white : colors.greyDarkest)};
  display: inline-block;
  width: 50%;

  ${({ $hide }) => $hide && VisuallyHide};
`;

export const StyledSingleSelectLegacy = styled(StyledSelectLegacy)``;

export const StyledMultiSelectLegacy = styled(StyledSelectLegacy)``;

export const StyledPopperLegacy = styled(Popper)<{ $width: number }>`
  top: 2px !important;
  z-index: 1;
  overflow: auto;
  user-select: none;
  font-size: 12px;
  cursor: pointer;
  max-height: 90vh;
  background-color: ${colors.white};
  box-shadow: 0 0 3px rgba(86, 96, 117, 0.7);
  min-width: ${({ $width }) => ($width ? `${$width}px` : "auto")};
  max-width: 90vw;

  // IE11
  *::-ms-backdrop,
  & {
    border: 1px solid ${colors.greyDarkest};
  }
`;

export const StyledArrowLegacy = styled.div``;

export const StyledItemLegacy = styled.div<{
  $selected: boolean;
  $focused: boolean;
}>`
  margin: 4px;
  padding: 3px 5px;
  line-height: 25px;
  font-size: 14px;
  font-family: ${legacyTheme.typography.fontFamily};

  &:hover,
  &:focus,
  &:active {
    color: ${colors.mineShaft};
    background-color: ${colors.wildSand};
  }

  ${({ $selected }) => $selected && `background-color: ${colors.wildSand};`}
  ${({ $focused }) => $focused && `background-color: ${colors.wildSand};`}
`;

export const StyledSelectedContainerLegacy = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledErrorTextLegacy = styled(Typography).attrs({
  color: "red",
  theme: "legacy",
  variant: "body1",
  component: "div",
})<{ $hide: boolean }>`
  margin-top: 8px;
  text-align: right;
  ${({ $hide }) => $hide && VisuallyHide};
`;

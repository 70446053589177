import styled from "styled-components";
import { ButtonsContainer } from "pages/styles/login";

export const BannerContainer = styled.div`
  margin-top: 20px;
`;

export const Row = styled.div`
  height: 87px;
`;

export const ButtonsWrapper = styled(ButtonsContainer)`
  margin-top: 11px;
`;

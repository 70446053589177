import {
  Theme,
  ThemeOptions,
  createTheme as muiCreateTheme,
  unstable_createMuiStrictModeTheme as createThemeStrictMode,
} from "@material-ui/core/styles";
import { CSSProperties } from "react";

type ThemeNameType = "default" | "legacy" | "future";

export interface ThemeType extends Theme {
  colors: { [key: string]: CSSProperties["color"] };
  name: ThemeNameType;
}

export interface ThemeOptionsType extends ThemeOptions {
  colors: ThemeType["colors"];
  name: ThemeNameType;
}

// For dev/test mode, suppress MUI v4 deprecation warnings that appear in strict mode.
// Otherwise use the standard createTheme method in production mode.
// https://mui.com/material-ui/customization/theming/#unstable-createmuistrictmodetheme-options-args-theme
const createThemeFunc =
  process.env.NODE_ENV === "production"
    ? muiCreateTheme
    : createThemeStrictMode;

export const createTheme = ({
  colors,
  typography,
  ...rest
}: ThemeOptionsType): ThemeType =>
  createThemeFunc({
    colors,
    typography,
    ...rest,
  } as ThemeOptionsType) as ThemeType;

import styled, { createGlobalStyle } from "styled-components";
import { tablet } from "styles/breakpoints";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 31px 16px 20px 16px;

  @media (min-width: ${tablet}px) {
    padding: 5vh 20px 20vh 20px;
  }
`;

export const HeaderContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  // TODO: Temporary until Typography component redesign is applied
  && {
    h1 {
      font-size: 28px;
      font-weight: 700;
      text-align: left;
    }
  }
`;

export const ProviderImage = styled.img`
  display: block;
  margin: 0 auto;
  max-height: 15vh;
  max-width: 100%;
  height: auto;
  padding-bottom: 20px;

  @media (min-height: 700px) {
    display: block;
    padding-bottom: 0;
  }
`;

export const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
  max-width: 696px;
`;

export const Button = styled.button`
  display: inline-block;
  padding: 8px;
  color: #fff;
  border: 0px;
  width: 100%;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  background: radial-gradient(circle at 33% 66%, #76be81 0%, #59b99f 100%);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  font-size: 15px;

  @media (min-width: ${tablet}px) {
    font-size: 20px;
  }
`;

export const GlobalStyles = createGlobalStyle`
  // Temporary until we restyle our Storybook button component
  button {
    text-transform: none !important;
  }
`;

export const VideoContainer = styled.div`
  margin: 15px 18px;
  max-width: 485px;
  border: 2px solid #f7f8fb;
`;

export const Paragraph = styled.p`
  line-height: 20px;
  margin-top: 23px;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 20px;
`;

export const GraphicContainer = styled.div`
  margin-top: 39px;
  display: flex;
  justify-content: center;

  @media (min-width: ${tablet}px) {
    margin-top: 170px;

    svg {
      transform: scale(1.75);
    }
  }
`;

import { useState, useCallback } from "react";
import { SurveyKind, useSurveyContext } from "context/survey";
import {
  ErrorType,
  ITHACA_HOSTNAME,
  QUESTIONNAIRE_URL,
  SPECIALIST_HOSTNAME,
} from "env-vars";
import { clearQuestionnaire } from "helpers";
import { StartAssessment } from "components/start-assessment";

export function InitialSurvey() {
  const { surveyKind } = useSurveyContext();
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState<ErrorType | null>(null);

  const startSurvey = useCallback(async () => {
    setSubmitting(true);

    const patientId = localStorage.getItem("ciq_id");
    const idToken = localStorage.getItem("id_token");

    if (!patientId || !idToken) {
      setError(ErrorType.AUTH);

      return;
    }

    try {
      const response = await fetch(
        `${SPECIALIST_HOSTNAME}/api/v1/survey_sessions/create_session`,
        {
          method: "POST",
          body: JSON.stringify({
            person_id: patientId,
            is_remote: true,
            is_initial_screening: surveyKind === SurveyKind.INITIAL,
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
        }
      );

      setSubmitting(false);

      if (!response.ok) {
        console.error("Error initializing survey session");

        setError(response.status === 401 ? ErrorType.AUTH : ErrorType.GENERAL);

        return;
      }

      const json = await response.json();
      localStorage.removeItem("surveyType");

      if (json.survey_type === "remote") {
        // Ensure the questionnaire local storage is cleared
        clearQuestionnaire();

        localStorage.setItem("surveyCode", json.code);
        localStorage.setItem("sessionId", json.id);
        localStorage.setItem("surveyType", "remote");
        localStorage.setItem("ciq-questionnaire.sessionId", json.id);

        // Redirect to Questionnaire
        const patientPortalUrl = window.location.origin;
        window.location.href = `${QUESTIONNAIRE_URL}${json.survey_token}?redirectUrl=${patientPortalUrl}/complete`;
      }
      if (json.survey_type === "remote_rescreen") {
        // Redirect to rescreen wizard in Ithaca
        window.location.href = `${ITHACA_HOSTNAME}/patients/${patientId}/encounters/${patientId}/rescreen-questionnaire?code=${json.code}`;
      }
    } catch (error) {
      setError(ErrorType.GENERAL);
    }

    // TODO: Can we even get to this point?
  }, [surveyKind]);

  const handleStart = useCallback(async () => {
    setSubmitting(true);

    startSurvey();
  }, [startSurvey]);

  if (error === ErrorType.AUTH) {
    window.location.href = "/login/passwordless";
  }

  return (
    <>
      {error === ErrorType.GENERAL && <div>Error loading survey</div>}
      <StartAssessment onStartClick={handleStart} isSubmitting={isSubmitting} />
    </>
  );
}

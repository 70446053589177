import React, { ReactNode } from "react";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import { StyledCheckboxOptionDefault } from "./theme/default";
import { SelectItemType } from "./index";

type OptionProps = {
  value: SelectItemType["value"];
  children: SelectItemType["text"];
};

export function Option({ children }: OptionProps) {
  return <>{children}</>;
}

export type OptionComponentProps = {
  selected: boolean;
  children: ReactNode;
};

export function OptionCheckboxDefault({
  selected,
  children,
}: OptionComponentProps) {
  const Checkbox = selected ? CheckBoxIcon : CheckBoxOutlineBlankIcon;

  return (
    <StyledCheckboxOptionDefault>
      <Checkbox />
      {children}
    </StyledCheckboxOptionDefault>
  );
}

import styled from "styled-components";
import { tablet } from "styles/breakpoints";

export const white = "#fff";
// https://chir.ag/projects/name-that-color/#0379C5
export const lochmaraBlue = "#0379c5";

export type Props = {
  $isActive?: boolean;
  $disabled?: boolean;
};

type RiskSplashProps = {
  $splashUrl: string;
};

export const MainContainer = styled.div`
  background-color: #fbfbfb;
  display: flex;
  flex-direction: column;

  * {
    -webkit-tap-highlight-color: transparent;
    -webkit-text-size-adjust: 100%;
    -webkit-font-smoothing: antialiased;
  }
`;

export const Content = styled.div`
  margin: 0 auto;
  width: 100%;
  max-width: 833px;
  padding: 0 8px 52px 8px;
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const Welcome = styled.h1`
  margin-top: 38px;
  margin-bottom: 20px;
  font-size: 27px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  opacity: 0.8;
  color: #6b6d88;
`;

export const RiskAssessmentPanel = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: solid 1px rgba(119, 56, 92, 0.15);
`;

export const RiskAssessmentHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 60px;
  background-color: #ebebec;
  padding: 20px;
  border-radius: 8px 8px 0px 0px;
`;

export const RiskAssessmentLabel = styled.h2`
  font-size: 24px;
  font-weight: 500;
  color: #85869c;
  line-height: 60px;
`;

export const RiskSplash = styled.div<RiskSplashProps>`
  flex: 1;
  background-image: url(${({ $splashUrl }) => $splashUrl});
  background-size: cover;
  position: relative;
`;

export const RiskSplashOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0px 10px;
  background-image: linear-gradient(
    to top,
    rgba(102, 102, 102, 0.29),
    rgba(0, 0, 0, 0.81)
  );
`;

export const ContentInfo = styled.div`
  padding-top: 43px;
  opacity: 0.9;
`;

export const ContentHeader = styled.h3`
  margin: 0;
  min-height: 76px;
  text-align: center;
  opacity: 0.9;
  font-size: 27px;
  font-weight: 300;
  color: ${white};

  @media (max-width: ${tablet}px) {
    display: none;
  }
`;

export const ContentTitle = styled.h4`
  margin: 0;
  text-align: center;
  padding-top: 27px;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1px;
  color: ${white};
`;

export const TimeRemaining = styled.p`
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1px;
  color: ${white};
  text-align: center;
`;

export const ContinueButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
  padding-bottom: 43px;
  && {
    :hover {
      background-color: ${lochmaraBlue};
    }
  }
`;

export const ContinueButton = styled.button`
  width: 60%;
  height: 35px;
  display: inline-block;
  padding: 6px 12px;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
  border: 1px solid transparent;
  border-radius: 4px;
  background-color: ${lochmaraBlue};
  color: ${white};
  font-size: 18px;
  &:hover {
    background-color: ${lochmaraBlue};
  }
`;

export const TileContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: flex-start;
  align-content: flex-start;
`;

export const AllDoneContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 10px 20px;
  color: #fff;
  border-radius: 5px;
  background-color: #61ba96;
  width: 100%;
  font-size: 16px;

  :first-child {
    margin-right: 8%;
  }
`;

export const ErrorBanner = styled(AllDoneContainer)`
  background-color: #db6068;
`;

import { Redirect, RouteProps } from "react-router-dom";
import { usePatientAuth } from "context/patient-auth";
import { PatientLayout } from "layouts/patient-layout";
import { Loading } from "components/loading";
import { preserveQueryParams } from "helpers";

interface PatientRouteProps extends RouteProps {
  component: React.ComponentType<unknown>;
}

export function PatientRoute({
  component: Component,
  ...rest
}: PatientRouteProps) {
  const { isLoading, isAuthenticated } = usePatientAuth();

  if (isLoading) {
    return <Loading />;
  }

  return (
    <PatientLayout>
      {isAuthenticated ? (
        <Component {...rest} />
      ) : (
        <Redirect to={preserveQueryParams("/login/passwordless")} />
      )}
    </PatientLayout>
  );
}

import React, { useCallback } from "react";
import styled from "styled-components";
import { z } from "zod";
import { Box, CircularProgress } from "@material-ui/core";
import { Modal } from "storybook/components/modal";
import { Button } from "storybook/components/button";
import { useForm } from "storybook/utils/form";
import {
  ErrorType,
  InterestLevel,
  InterestReason,
  usePatientInterest,
} from "context/patient-interest";
import { ErrorBanner } from "./styles";

type DeclineOption = {
  display: string;
  value: InterestReason;
};

const declineOptions: DeclineOption[] = [
  {
    display: "Already Being Followed",
    value: InterestReason.ALREADY_BEING_FOLLOWED,
  },
  {
    display: "Does Not Want to Know",
    value: InterestReason.DOES_NOT_WANT_TO_KNOW,
  },
  {
    display: "Had Genetic Counseling/Testing",
    value: InterestReason.PREVIOUS_PERSONAL_TESTING,
  },
  {
    display: "Family Member Testing",
    value: InterestReason.PREVIOUS_FAMILY_TESTING,
  },
  { display: "Insurance", value: InterestReason.INSURANCE },
  { display: "No Response", value: InterestReason.NONE },
  { display: "Time Constraints", value: InterestReason.TIME_CONSTRAINT },
  { display: "Transportation", value: InterestReason.TRANSPORTATION },
  { display: "Other Reason", value: InterestReason.OTHER },
];

const schema = z.object({
  declineReason: z.nativeEnum(InterestReason),
});

type SchemaType = z.infer<typeof schema>;

type Props = {
  onClose: () => void;
};

const DeclineReasonsWrapper = styled.div`
  display: flex;
  flex-direction: column;

  > :not(:last-child) {
    margin-bottom: 24px;
  }
`;

export function DeclineModal({ onClose }: Props) {
  const { Form, Radio, getValues } = useForm<SchemaType>({ schema });
  const { loading, errorType, saveInterest } = usePatientInterest();

  const value = getValues("declineReason");

  const handleSubmit = useCallback(
    (reason) => {
      saveInterest({
        level: InterestLevel.DECLINED,
        reason: reason.declineReason,
      });
    },
    [saveInterest]
  );

  return (
    <>
      <Form onSubmit={handleSubmit} id="decline-reasons">
        <Modal.Body>
          {errorType === ErrorType.GENERAL && (
            <ErrorBanner role="alert">
              An error occurred. Please try again later.
            </ErrorBanner>
          )}
          <DeclineReasonsWrapper>
            {declineOptions.map((option) => (
              <Radio
                theme="default"
                name="declineReason"
                key={option.value}
                value={option.value}
                displayValue={option.display}
              />
            ))}
          </DeclineReasonsWrapper>
        </Modal.Body>
        {loading ? (
          <Box marginTop="3rem" display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        ) : (
          <Modal.Actions alignButtons="right">
            <Button
              theme="default"
              variant="text"
              color="default"
              onClick={onClose}
            >
              Cancel
            </Button>
            <Button
              type="submit"
              theme="default"
              color="default"
              variant="contained"
              form="decline-reasons"
              disabled={!value}
            >
              Save
            </Button>
          </Modal.Actions>
        )}
      </Form>
    </>
  );
}

import { QUESTIONNAIRE_URL } from "env-vars";

export const getProtocolHost = () =>
  `${window.location.protocol}//${window.location.host}`;

export const preserveQueryParams = (path: string) =>
  `${path}${location.search}`;

export const formatPhone = (value: string): string | null => {
  if (!value) return null;

  const cleaned = value.replace(/\D/g, ""); // removes all non digits
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }

  return cleaned || null;
};

type QueryStringParams = {
  uri: string;
  key: string;
  value: string;
};

const updateQueryStringParameter = ({ uri, key, value }: QueryStringParams) => {
  const re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  const separator = uri.indexOf("?") !== -1 ? "&" : "?";
  if (uri.match(re)) {
    return uri.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    return uri + separator + key + "=" + value;
  }
};

type OpenSurvey = {
  token: string;
  queryParams: object;
  newTab: boolean | null;
  skipLandingPage?: boolean;
};

export const openQuestionnaire = ({
  token,
  queryParams,
  newTab = false,
  skipLandingPage = false,
}: OpenSurvey) => {
  let url = QUESTIONNAIRE_URL + token;

  // We should direct them to /0 if skipLandingPage is true
  if (skipLandingPage) {
    url = url + "/0";
  }

  for (const key in queryParams) {
    if (key) {
      url = updateQueryStringParameter({
        uri: url,
        key,
        value: queryParams[key as keyof OpenSurvey["queryParams"]],
      });
    }
  }

  newTab ? window.open(url, "_blank") : window.open(url, "_self");
};

export const clearQuestionnaire = () => {
  localStorage.removeItem("sessionId");
  localStorage.removeItem("surveyCode");
  localStorage.removeItem("ciq-questionnaire.sessionId");
  localStorage.removeItem("ciq-questionnaire.survey_response_jwt_token");
  localStorage.removeItem("ciq-questionnaire.lastId");
  localStorage.removeItem("ciq-questionnaire.data");
  localStorage.removeItem("ciq-questionnaire.state");
  localStorage.removeItem("ciq-questionnaire.meets_testing_criteria");
  localStorage.removeItem("ciq-questionnaire.meets_mri_criteria");
  localStorage.removeItem("ciq-questionnaire.meets_counseling_criteria");
};

export const getPatientIdentifier = () => {
  const sessionId = localStorage.getItem("ciq-questionnaire.sessionId");
  return sessionId ? sessionId.slice(sessionId.length - 4) : null;
};

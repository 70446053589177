import {
  useState,
  useEffect,
  useMemo,
  useContext,
  createContext,
  ReactElement,
} from "react";

type ScrollbarContextType = {
  scrollbarVisible: boolean;
  scrollbarWidth: number;
};

const ScrollbarContext = createContext<ScrollbarContextType>({
  scrollbarVisible: false,
  scrollbarWidth: 0,
});

type Props = {
  children: ReactElement;
};

export function ScrollbarProvider({ children }: Props) {
  const [scrollbarWidth, setScrollbarWidth] = useState(0);
  const [scrollbarVisible, setScrollbarVisible] = useState(false);

  useEffect(() => {
    // Used to prevent the footer buttons shifting when the scrollbar visibility changes.
    const handleResize = () => {
      const calculatedScrollbarWidth =
        window.innerWidth - document.documentElement.clientWidth;

      setScrollbarVisible(calculatedScrollbarWidth > 0);

      if (calculatedScrollbarWidth > 0) {
        setScrollbarWidth(calculatedScrollbarWidth);
      }
    };

    const observer = new MutationObserver(() => {
      handleResize();
    });

    observer.observe(document.documentElement, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const contextProps = useMemo(
    () => ({
      scrollbarWidth,
      scrollbarVisible,
    }),
    [scrollbarVisible, scrollbarWidth]
  );

  return (
    <ScrollbarContext.Provider value={contextProps}>
      {children}
    </ScrollbarContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export function useScrollbar() {
  if (!ScrollbarContext) {
    throw new Error("useScrollbar must be used within a ScrollbarProvider.");
  }

  return useContext(ScrollbarContext);
}

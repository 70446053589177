import React, { ReactNode, Children, isValidElement } from "react";
import styled, { StyledComponent } from "styled-components";
import { get, useFormContext } from "react-hook-form";
import { FieldErrorDefault } from "storybook/components/field-error";
import { ThemeType } from "storybook/utils/theme";
import { Typography } from "storybook/components/typography";
import { useUniqueId } from "storybook/utils/use-unique-id";

interface InputGroupThemeType extends ThemeType {
  name: Extract<ThemeType["name"], "default">;
}
export interface InputGroupType {
  disabled?: boolean;
  label: ReactNode;
  children: ReactNode;
  className?: string;
  theme: InputGroupThemeType["name"];
  orientation: "vertical" | "horizontal";
}

type StyledComponentMap = Record<
  InputGroupType["theme"],
  StyledComponent<any, any>
>;

const StyledInputsContainer = styled.div``;

const StyledFieldsetHorizontalDefault = styled.fieldset`
  display: flex;
  align-items: center;
  border: none;
  padding: 0;
  margin: 0;
  flex-direction: row;
  > * {
    flex: 1;
  }

  ${StyledInputsContainer} {
    display: grid;
    display: -ms-grid;
    grid-template-columns: 1fr 1fr;
    -ms-grid-columns: 1fr 1fr;
    > :first-child {
      -ms-grid-column: 1;
      margin-right: 0.5rem;
    }
    > :last-child {
      -ms-grid-column: 2;
      margin-left: 0.5rem;
    }
  }
`;

const StyledFieldsetVerticalDefault = styled.fieldset<{
  $orientation: string;
}>`
  display: flex;
  align-items: stretch;
  border: none;
  padding: 0;
  margin: 0;
  flex-direction: column;

  ${StyledInputsContainer} {
    display: flex;
    align-items: stretch;
    flex-direction: inherit;
    ${({ $orientation }) => ($orientation === "vertical" ? "gap: 10px" : "")};
  }
`;

const StyledLegendHorizontalDefault = styled(Typography).attrs(
  ({ $hasError, $isDirty }: { $hasError: boolean; $isDirty: boolean }) => ({
    color: $hasError ? "error" : $isDirty ? "dirtyLabel" : "greyDarkest",
    theme: "default",
    variant: "subtitle1",
    component: "div", // legend has issues with flexbox https://stackoverflow.com/a/71573932
  })
)``;

const StyledLegendVerticalDefault = styled(Typography).attrs(
  ({ $hasError }: { $hasError: boolean }) => ({
    color: $hasError ? "error" : "ciqBrand",
    theme: "default",
    variant: "overline",
    component: "div", // legend has issues with flexbox https://stackoverflow.com/a/71573932
  })
)``;

const styledFieldsetHorizontal: StyledComponentMap = {
  default: StyledFieldsetHorizontalDefault,
};

const styledFieldsetVertical: StyledComponentMap = {
  default: StyledFieldsetVerticalDefault,
};

const styledLegendVertical: StyledComponentMap = {
  default: StyledLegendVerticalDefault,
};

const styledLegendHorizontal: StyledComponentMap = {
  default: StyledLegendHorizontalDefault,
};

const styledInputGroupError: StyledComponentMap = {
  default: FieldErrorDefault,
};

export function InputGroup({
  disabled,
  label,
  children,
  theme,
  className,
  orientation = "vertical",
}: InputGroupType) {
  const instanceId = useUniqueId("InputGroup");
  const { formState } = useFormContext();

  const StyledFieldset =
    orientation === "horizontal"
      ? styledFieldsetHorizontal[theme]
      : styledFieldsetVertical[theme];

  const StyledLegend =
    orientation === "horizontal"
      ? styledLegendHorizontal[theme]
      : styledLegendVertical[theme];

  const StyledInputGroupError = styledInputGroupError[theme];

  const errors = [];
  const dirtyFields = [];
  const arrayChildren = Children.toArray(children);
  for (const child of arrayChildren) {
    if (isValidElement(child)) {
      errors.push(get(formState.errors, child.props.name));
      dirtyFields.push(get(formState.dirtyFields, child.props.name));
    }
  }

  // We need to handle the case where a group of inputs
  // may have some inputs that are valid, while
  // others are invalid. This also assumes the same
  // error message is applicable for the entire group
  const error = errors.filter(Boolean)[0];

  const isDirty = dirtyFields.filter(Boolean).length > 0;

  const isDisabled = disabled || formState.isSubmitting;

  return (
    <>
      <StyledFieldset
        data-testid="InputGroup"
        disabled={isDisabled}
        $orientation={orientation}
        className={className}
        aria-labelledby={instanceId}
      >
        <StyledLegend
          id={instanceId}
          $hasError={Boolean(error)}
          $isDirty={isDirty}
        >
          {label}
        </StyledLegend>
        <StyledInputsContainer>{children}</StyledInputsContainer>
      </StyledFieldset>
      {error?.message && (
        <StyledInputGroupError>{error.message}</StyledInputGroupError>
      )}
    </>
  );
}

import styled from "styled-components";
import { Button } from "storybook/components/button";

export const Header = styled.header`
  display: flex;
  margin-bottom: 20px;
  border-bottom: 2px solid #6a6e86;
`;

export const Nav = styled.nav`
  flex: 1;
  display: flex;
  padding: 10px 0 10px 0;
  align-items: center;
  justify-content: space-between;
`;

export const RestartButton = styled(Button).attrs({
  // @ts-ignore cannot resolve against default theme
  theme: "default",
  color: "default",
})`
  && {
    display: inline-block;
    padding: 8px;
    color: #6a6e86;
    border: 1px solid #6a6e86;
    border-radius: 5px;
    text-align: center;
    outline: none;
    cursor: pointer;
    text-decoration: none;
    background-color: rgba(0, 0, 0, 0);
  }
`;

/**
 * Common Legacy Theme Styled Components
 */

import { css } from "styled-components";

export const StyledDownArray = css`
  &::after {
    content: "";
    width: 0;
    height: 0;
    position: absolute;
    border-left: 4px solid transparent;
    border-right: 4px solid transparent;
  }
`;

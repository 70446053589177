import styled from "styled-components";
import { Column } from "pages/common/styles";

export const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  .red {
    color: red;
    font-weight: 600;
    font-size: 1.5em;
  }

  .orange {
    color: orange;
    font-weight: 600;
    font-size: 1.5em;
  }

  .yellow {
    color: #999900;
    font-weight: 600;
    font-size: 1.5em;
  }

  .green {
    color: #006600;
    font-weight: 600;
    font-size: 1.5em;
  }

  .blue {
    color: #0000cc;
    font-weight: 600;
    font-size: 1.5em;
  }

  .purple {
    color: #800080;
    font-weight: 600;
    font-size: 1.5em;
  }

  .large-text {
    font-weight: 600;
    font-size: 1.5em;
  }
`;

export const VideoContainer = styled.div``;

export const ImageContainer = styled.div`
  display: flex;
  justify-content: center;

  img {
    width: 50vw;
    height: auto;
    margin: 0 auto;
    margin-bottom: 10vh;

    @media (orientation: landscape) {
      height: 10vh;
      width: auto;
      margin: 0;
    }
  }
`;

export const Heading = styled.h1`
  position: absolute;
  top: -10000px;
  left: -10000px;
`;

export const PatientIdentifierContainer = styled.div`
  position: fixed;
  right: 50px;
  bottom: 50px;
`;

export const BioContainerColumn = styled(Column)`
  display: block;

  padding: 5px;
  text-align: center;

  border: 3px solid #fff;
  box-shadow: 0 0 5px #888;

  @media (orientation: portrait) {
    margin-top: 10vh;
    margin-bottom: 10vh;
    width: 100%;
  }

  @media (orientation: landscape) {
    padding: 5px;
    margin-top: 32px;
    margin-right: 16px;
    max-width: 450px;
  }
`;

export const BioImage = styled.img`
  float: left;
  width: 150px;
  height: auto;

  object-fit: contain;
  margin-right: 10px;
`;

export const BioHtmlContainer = styled.div`
  text-align: left;
  padding-left: 5px;
  padding-right: 5px;
`;

export const SchedulerButton = styled.div`
  display: flex;
  margin-top: 20px;
  justify-content: center;
`;

import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import { selector } from "storybook/utils/selector";
import { defaultTheme } from "storybook/themes/default";

interface StyledTypographyDefaultProps {
  $color: keyof typeof defaultTheme.colors;
}

export const StyledTypographyDefault = styled(
  Typography
)<StyledTypographyDefaultProps>`
  &${selector`.MuiTypography-root`} {
    font-family: ${defaultTheme.typography.fontFamily};
    color: ${(props) => defaultTheme.colors[props.$color]};
  }
  &${selector`.MuiTypography-h1`} {
    font-size: 96px;
    font-weight: 300;
    line-height: 1.167;
  }
  &${selector`.MuiTypography-h2`} {
    font-size: 60px;
    font-weight: 300;
    line-height: 1.2;
  }
  &${selector`.MuiTypography-h3`} {
    font-size: 48px;
    font-weight: 400;
    line-height: 1.167;
  }
  &${selector`.MuiTypography-h4`} {
    font-size: 34px;
    font-weight: 400;
    line-height: 1.235;
  }
  &${selector`.MuiTypography-h5`} {
    font-size: 24px;
    font-weight: 400;
    line-height: 1.334;
  }
  &${selector`.MuiTypography-h6`} {
    font-size: 20px;
    font-weight: 500;
    line-height: 1.6;
  }
  &${selector`.MuiTypography-body1`} {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
  }
  &${selector`.MuiTypography-body2`} {
    font-size: 14px;
    font-weight: 400;
    line-height: 1.43;
  }
  &${selector`.MuiTypography-subtitle1`} {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.75;
  }
  &${selector`.MuiTypography-subtitle2`} {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.57;
  }
  &${selector`.MuiTypography-overline`} {
    font-size: 12px;
    font-weight: 700;
    line-height: 2.66;
    text-transform: uppercase;
  }
  &${selector`.MuiTypography-button`} {
    font-size: 14px;
    fontweight: 500;
    line-height: 1.75;
    texttransform: uppercase;
  }
`;

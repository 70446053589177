import React, { useCallback } from "react";
import { Modal } from "storybook/components/modal";
import { Button } from "storybook/components/button";
import { ActionsWrapper, ModalBodyWrapper } from "./styles";

type Props = {
  onClose: () => void;
};

export function ThankYouModal({ onClose }: Props) {
  const handleButtonClick = useCallback(() => {
    onClose();
  }, [onClose]);

  return (
    <>
      <Modal.Body>
        <ModalBodyWrapper>
          Thank you for taking the time to fill out the screening survey!
        </ModalBodyWrapper>
      </Modal.Body>
      <Modal.Actions alignButtons="center">
        <ActionsWrapper>
          <Button
            color="default"
            variant="contained"
            theme="default"
            fullWidth={true}
            onClick={handleButtonClick}
          >
            Close
          </Button>
        </ActionsWrapper>
      </Modal.Actions>
    </>
  );
}

import React from "react";
import styled from "styled-components";
import { get, useFormContext } from "react-hook-form";
import { StyledComponent } from "styled-components";
import { FormFieldType } from "storybook/utils/form";
import { ThemeType } from "storybook/utils/theme";
import { FieldErrorDefault } from "storybook/components/field-error";
import {
  StyledTextareaLabelDefault,
  StyledTextareaDefault,
} from "./themes/default";

interface TextAreaThemeType extends ThemeType {
  name: Extract<ThemeType["name"], "default">;
}

type StyledComponentMap = Record<
  TextareaType<any>["theme"],
  StyledComponent<any, any>
>;

const styledTextareaError: StyledComponentMap = {
  default: FieldErrorDefault,
};

const styledTextareaMap: StyledComponentMap = {
  default: StyledTextareaDefault,
};

const styledTextareaLabelMap: StyledComponentMap = {
  default: StyledTextareaLabelDefault,
};

const StyledHiddenLabelText = styled.span`
  position: absolute;
  top: -9999px;
  left: -9999px;
`;

const StyledTextareaContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 0 1 100%;
`;

export interface TextareaType<T> extends FormFieldType<T> {
  theme: TextAreaThemeType["name"];
  disabled?: boolean;
  label: string;
  hideLabel?: boolean;
  hideErrorText?: boolean;
  placeholder?: string;
  resize?: boolean;
  height?: number;
  hideDirty?: boolean;
}

export function Textarea<T>({
  theme,
  name,
  disabled,
  label,
  hideLabel = false,
  resize,
  hideErrorText,
  placeholder,
  height = 80,
  hideDirty,
  registerOptions = {},
}: TextareaType<T>) {
  const { formState, register } = useFormContext();
  const { ...registerProps } = register(name, registerOptions);

  const StyledTextarea = styledTextareaMap[theme];
  const StyledTextareaLabel = styledTextareaLabelMap[theme];
  const StyledTextareaError = styledTextareaError[theme];

  const error = get(formState.errors, name);

  const isDisabled = disabled || formState.isSubmitting;
  const isDirty = get(formState.dirtyFields, name);

  return (
    <StyledTextareaContainer>
      <StyledTextareaLabel
        $hideLabel={hideLabel}
        disabled={isDisabled}
        $hasError={Boolean(error)}
        $isDirty={hideDirty ? false : isDirty}
      >
        {hideLabel ? (
          <StyledHiddenLabelText data-testid="Textarea__LabelText">
            {label}
          </StyledHiddenLabelText>
        ) : (
          <span data-testid="Textarea__LabelText">{label}</span>
        )}
        <StyledTextarea
          {...registerProps}
          placeholder={placeholder}
          disabled={isDisabled}
          $resize={resize}
          $height={height}
          $hasError={Boolean(error)}
          $isDirty={hideDirty ? false : isDirty}
        />
      </StyledTextareaLabel>
      {!hideErrorText && error?.message && (
        <StyledTextareaError>{error.message}</StyledTextareaError>
      )}
    </StyledTextareaContainer>
  );
}

import styled from "styled-components";

export const PageContainer = styled.div``;

export const MainContainer = styled.main`
  width: 100%;
  display: flex;
  padding: 51px 0 0 0;
  flex-direction: column;
  height: calc(100vh - 51px);
`;

export const HeaderContainer = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1;
  background-color: white;
`;

import styled from "styled-components";
import { tablet } from "styles/breakpoints";

export const Container = styled.div`
  width: 100%;
  display: flex;
  min-width: 320px;
  flex-direction: column;
  padding: 27px 16px 0 16px;

  button {
    width: 100%;
    text-transform: none;
  }
`;

export const HeadingContainer = styled.div`
  && {
    h1 {
      font-weight: 700;
    }
  }
`;

export const RadiosContainer = styled.div`
  margin-top: 32px;
  flex-direction: column;
  display: flex;
  gap: 10px;
`;

export const IconWrapper = styled.div`
  width: 35px;
  height: 35px;
  display: flex;
  margin-right: 5px;
  align-items: center;
  justify-content: center;
`;

export const SurveyButton = styled.button<{ $isActive: boolean }>`
  height: 51px;
  font-size: 14px;
  font-weight: 500;
  border-radius: 12px;
  position: relative;
  border: ${({ $isActive }) =>
    $isActive ? "1px solid #851D58" : "1px solid rgba(232, 232, 255, 0.3)"};
  background: rgba(232, 232, 255, 0.3);
  display: flex;
  align-items: center;
  transition: all 0.3s ease;

  &:hover {
    cursor: pointer;
  }

  &[disabled] {
    color: black;
    opacity: 0.5;
    cursor: not-allowed;
    background-color: rgba(232, 232, 255, 0.3);
    border-color: rgba(232, 232, 255, 0.3);
  }
`;

export const ButtonContainer = styled.div`
  margin-top: 24px;
`;

export const InProgressContainer = styled.div`
  margin-top: 16px;

  && {
    p {
      line-height: 1.35rem;
    }
  }
`;

export const GraphicContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;

  svg {
    margin-top: 40px;
    width: 223px;
  }

  @media (min-width: ${tablet}px) {
    svg {
      margin-top: 60px;
      width: 380px;
    }
  }
`;

export const CompletedContainer = styled.div`
  right: 0;
  color: #155a31;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  padding-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    margin-right: 4px;
  }
`;

export const AlertContainer = styled.div`
  margin-top: 16px;
`;

export const ErrorContainer = styled.div`
  width: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  text-align: center;

  && {
    p {
      font-weight: 700;
      margin-top: 16px;
    }
  }
`;

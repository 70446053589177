import styled from "styled-components";
import { VisuallyHide } from "storybook/styles/common";
import { Typography } from "storybook/components/typography";
import { defaultTheme } from "storybook/themes/default";

const { colors } = defaultTheme;

type StyledCheckboxLabelDefaultProps = {
  $hasError: boolean;
  $isDirty: boolean;
  $disabled: boolean;
  $keyboardFocus: boolean;
  $alignTop: boolean;
};

export const StyledCheckboxLabelDefault = styled(Typography).attrs(
  ({ $hasError, $isDirty }: StyledCheckboxLabelDefaultProps) => ({
    color: $hasError ? "error" : $isDirty ? "dirtyLabel" : "greyDarkest",
    theme: "default",
    variant: "body1",
    component: "label",
  })
)`
  flex: 1;
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  vertical-align: middle;
  margin: 0 16px 0 -11px;

  > input {
    ${VisuallyHide}
  }

  > svg {
    flex-shrink: 0;
    padding: 9px;
    position: relative;
    box-sizing: content-box; // border-box collapses the svg
    color: ${({ $hasError, $isDirty }: StyledCheckboxLabelDefaultProps) =>
      $hasError
        ? colors.error
        : $isDirty
        ? colors.dirtyLabel
        : colors.ciqBrandLight};
    background-color: ${({ $keyboardFocus }) =>
      $keyboardFocus ? colors.inputBorder : "transparent"};

    ${({ $alignTop }) =>
      $alignTop &&
      `
        align-self: flex-start;
        margin-top: -6px;
      `}
  }

  ${({ $disabled }) =>
    $disabled
      ? `
    cursor: default;
    pointer-events: none;
    opacity: 0.7;

  `
      : `
    > svg:hover {
      border-radius: 5px;
    }
  `};
`;

import styled from "styled-components";
import { Typography } from "storybook/components/typography";

type FieldErrorDefaultType = {
  $disabled?: boolean;
  role?: string;
};

export const FieldErrorDefault = styled(Typography).attrs({
  color: "error",
  theme: "default",
  variant: "body1",
  component: "div",
})`
  opacity: ${({ $disabled }: FieldErrorDefaultType) => ($disabled ? 0.7 : 1)};
`;

/**
 * Common Styled Components (Not Theme-Specific)
 */

import { css } from "styled-components";

// https://css-tricks.com/places-its-tempting-to-use-display-none-but-dont/
export const VisuallyHide = css`
  position: absolute;
  top: -9999px;
  left: -9999px;
`;

import styled from "styled-components";
import {
  StyledModal,
  StyledModalHead,
  StyledModalBody,
  StyledModalActions,
  StyledCloseButton,
} from "../styles";
import { selector } from "storybook/utils/selector";
import { defaultTheme } from "storybook/themes/default";

const { colors } = defaultTheme;

export const StyledModalBodyDefault = styled(StyledModalBody)`
  &${selector`.MuiDialogContent-root`} {
    margin-top: 2rem;
  }
`;

export const StyledModalDefault: typeof StyledModal = styled(StyledModal)<{
  $fullScreen: boolean;
}>`
  ${selector`.MuiPaper-root`} {
    padding: 3rem;
    max-height: calc(100% - 144px);
  }

  ${selector`.MuiPaper-rounded`} {
    border-radius: 8px;
  }

  ${selector`.MuiDialogContent-root`} {
    overflow: visible;
  }

  ${({ $fullScreen }) =>
    $fullScreen &&
    `
      ${selector`.MuiPaper-root`} {
        max-height: unset;
        // Place margin amount into padding to prevent
        // clicking of margin area from closing modal
        margin: 0;
        max-width: 710px;
        flex-grow: 1;
        align-items: center;

        // IE11
        *::-ms-backdrop,
        & {
          height: 100%;
          max-height: 100%;
          overflow: visible;
        }

        @media (min-width: 820px) {
          margin: 4.5rem auto;

          // IE11
          *::-ms-backdrop,
          & {
            margin: 4.5rem 0;
          }
        }
      }

      ${selector`.MuiBackdrop-root`} {
        background-color: ${colors.greyLotion};
      }

      ${selector`.MuiDialog-container`} {
        align-items: start;
        overflow-y: auto;
      }

      ${selector`.MuiPaper-rounded`} {
        background-color: ${colors.greyLotion};
        box-shadow: none;
      }

      ${selector`.MuiDialogContent-root`} {
        margin-top: 0.5rem;
      }
  `}
`;

export const StyledModalHeadDefault = styled(StyledModalHead)`
  &${selector`.MuiDialogTitle-root`} {
    padding: 0;
  }

  ${selector`.MuiTypography-root`} {
    color: ${colors.ciqBrand};
    font-size: 34px;
    line-height: 1.235;
    font-weight: 400;
  }
`;

export const StyledModalActionsDefault = styled(StyledModalActions)`
  &${selector`.MuiDialogActions-root`} {
    margin-top: 3rem;
    padding: 0;
  }

  &${selector`.MuiDialogActions-spacing > :not(:first-child)`} {
    margin-left: 0.875rem;
  }
`;

export const StyledCloseButtonDefault = styled(StyledCloseButton)<{
  $fullScreen: boolean;
}>`
  &${selector`.close-btn`} {
    top: 1.5rem;
    right: ${({ $fullScreen }) => ($fullScreen ? "0.5rem" : "1.5rem")};
    padding: 0.5rem;
    border-radius: 3rem;
    font-size: 100%;
    border: none;
    line-height: 0.6rem;
    background-color: transparent;
    transition: background 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

    &:hover,
    &:focus {
      background: ${colors.greyBright};
    }

    &:active {
      background: ${colors.greyDark};
    }

    @media (min-width: 600px) {
      margin-right: ${({ $fullScreen }) => ($fullScreen ? "0" : "1rem")};
    }
  }
`;

import { useState, useEffect } from "react";
import { ZENDESK_API_KEY } from "env-vars";
import { useAdminContent } from "context/admin-content";
import { zendeskSnippet } from "./zendesk-snippet";

export function Zendesk() {
  const adminContent = useAdminContent();
  const [zendeskInitialised, setZendeskInitialised] = useState<boolean | null>(
    null
  );

  useEffect(() => {
    if (zendeskInitialised || !adminContent || adminContent?.isLoading) {
      return;
    }

    if (!ZENDESK_API_KEY || !adminContent.featureFlags?.zendesk_enabled) {
      setZendeskInitialised(false);

      return;
    }

    zendeskSnippet(ZENDESK_API_KEY);

    setZendeskInitialised(true);
  }, [adminContent, zendeskInitialised]);

  return (
    <div style={{ display: "none" }} data-zendesk-init={zendeskInitialised} />
  );
}

export function NoSurveysIcon() {
  return (
    <svg
      width="151"
      height="160"
      viewBox="0 0 151 160"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 79.8479C0 40.4845 31.9103 8.57329 71.2739 8.57329C110.637 8.57329 142.547 40.4845 142.547 79.8479C142.547 119.211 110.637 151.121 71.2739 151.121C31.9103 151.121 0 119.211 0 79.8479Z"
        fill="#FFEFBF"
      />
      <path
        d="M93.8516 113.953L98.2403 118.521C99.5065 119.838 101.608 119.859 102.899 118.565L110.017 111.434C111.286 110.165 111.287 108.107 110.022 106.832L105.369 102.15C108.167 97.9289 110.153 93.354 111.232 87.9515C111.232 87.9515 115.353 87.9515 118.416 87.9498C120.217 87.9498 121.675 86.49 121.675 84.6903V75C121.675 73.1986 120.216 71.7388 118.414 71.7388H111.305C110.264 66.3345 108.243 61.6271 105.453 57.3614L109.98 52.7916C111.242 51.5171 111.239 49.4641 109.973 48.1946L102.872 41.0756C101.573 39.7705 99.4527 39.8011 98.191 41.1402L94.1303 45.4483C89.8603 42.6187 83.848 40.5471 80.2455 39.4782V33.5692C80.2455 31.7695 78.7859 30.3096 76.9851 30.3096H65.4936C63.6929 30.3096 62.2332 31.7695 62.2332 33.5692V39.4782C56.829 40.5471 52.5004 42.6017 48.2302 45.4313L43.9107 41.0196C42.6407 39.7212 40.5543 39.7144 39.2749 41.0026L32.1847 48.1352C30.9243 49.4029 30.9206 51.4508 32.1767 52.7237L36.7526 57.3614C33.9631 61.6271 31.9775 66.3345 30.9349 71.7388H24.065C22.2643 71.7388 20.8035 73.1986 20.8035 75V84.6903C20.8035 86.49 22.2643 87.9498 24.065 87.9515H31.0086C32.0876 93.354 34.1247 97.9289 36.9225 102.15L32.2691 106.832C31.0039 108.107 31.0051 110.165 32.2739 111.434L39.392 118.565C40.6831 119.859 42.7848 119.838 44.0512 118.521L48.4399 113.953C52.6586 116.71 56.829 118.728 62.2332 119.782V126.119C62.2332 127.919 63.6929 129.379 65.4936 129.379H76.9851C78.7859 129.379 80.2455 127.919 80.2455 126.119V119.782C83.848 118.728 89.7021 116.721 93.9208 113.963"
        fill="#FF837A"
      />
      <path
        d="M93.8516 113.953L98.2403 118.521C99.5065 119.838 101.608 119.859 102.899 118.565L110.017 111.434C111.286 110.165 111.287 108.107 110.022 106.832L105.369 102.15C108.167 97.9289 110.153 93.354 111.232 87.9515C111.232 87.9515 115.353 87.9515 118.416 87.9498C120.217 87.9498 121.675 86.49 121.675 84.6903V75C121.675 73.1986 120.216 71.7388 118.414 71.7388H111.305C110.264 66.3345 108.243 61.6271 105.453 57.3614L109.98 52.7916C111.242 51.5171 111.239 49.4641 109.973 48.1946L102.872 41.0756C101.573 39.7705 99.4527 39.8011 98.191 41.1402L94.1303 45.4483C89.8603 42.6187 83.848 40.5471 80.2455 39.4782V33.5692C80.2455 31.7695 78.7859 30.3096 76.9851 30.3096H65.4936C63.6929 30.3096 62.2333 31.7695 62.2333 33.5692V39.4782C56.829 40.5471 52.5004 42.6017 48.2302 45.4313L43.9107 41.0196C42.6407 39.7212 40.5543 39.7144 39.2749 41.0026L32.1847 48.1352C30.9243 49.4029 30.9206 51.4508 32.1767 52.7237L36.7526 57.3614C33.9631 61.6271 31.9775 66.3345 30.9349 71.7388H24.065C22.2643 71.7388 20.8035 73.1986 20.8035 75V84.6903C20.8035 86.49 22.2643 87.9498 24.065 87.9515H31.0086C32.0876 93.354 34.1247 97.9289 36.9225 102.15L32.2691 106.832C31.0039 108.107 31.0051 110.165 32.2739 111.434L39.392 118.565C40.6831 119.859 42.7848 119.838 44.0512 118.521L48.4399 113.953C52.6586 116.71 56.829 118.728 62.2333 119.782V126.119C62.2333 127.919 63.6929 129.379 65.4936 129.379H76.9851C78.7859 129.379 80.2455 127.919 80.2455 126.119V119.782C83.848 118.728 89.7021 116.721 93.9208 113.963"
        stroke="#63105F"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M42.5717 89.153L65.5908 55.1842C68.2869 51.2059 74.1482 51.2059 76.8443 55.1842L99.8634 89.153C102.922 93.6672 99.6887 99.7637 94.2365 99.7637H48.1985C42.7464 99.7637 39.5132 93.6672 42.5717 89.153Z"
        fill="#FFF495"
      />
      <path
        d="M42.5717 89.153L65.5908 55.1842C68.2869 51.2059 74.1482 51.2059 76.8443 55.1842L99.8634 89.153C102.922 93.6672 99.6887 99.7637 94.2365 99.7637H48.1985C42.7464 99.7637 39.5132 93.6672 42.5717 89.153Z"
        stroke="#63105F"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M67.2854 90.9008C67.2854 93.0808 69.0525 94.8477 71.2324 94.8477C73.4122 94.8477 75.1795 93.0808 75.1795 90.9008C75.1795 88.7208 73.4122 86.954 71.2324 86.954C69.0525 86.954 67.2854 88.7208 67.2854 90.9008Z"
        fill="white"
      />
      <path
        d="M67.2854 90.9008C67.2854 93.0808 69.0525 94.8477 71.2324 94.8477C73.4122 94.8477 75.1795 93.0808 75.1795 90.9008C75.1795 88.7208 73.4122 86.954 71.2324 86.954C69.0525 86.954 67.2854 88.7208 67.2854 90.9008Z"
        stroke="#63105F"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M70.6662 61.1099H71.7799C73.647 61.1099 75.1608 62.6234 75.1608 64.4904V80.8505C75.1608 82.7189 73.647 84.2324 71.7799 84.2324H70.6662C68.799 84.2324 67.2852 82.7189 67.2852 80.8505V64.4904C67.2852 62.6234 68.799 61.1099 70.6662 61.1099Z"
        fill="white"
      />
      <path
        d="M70.6662 61.1099H71.7799C73.647 61.1099 75.1608 62.6234 75.1608 64.4904V80.8505C75.1608 82.7189 73.647 84.2324 71.7799 84.2324H70.6662C68.799 84.2324 67.2852 82.7189 67.2852 80.8505V64.4904C67.2852 62.6234 68.799 61.1099 70.6662 61.1099Z"
        stroke="#63105F"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.22949 35.2031C9.57278 30.7374 13.3714 26.5794 17.5904 22.7977C33.2695 8.74086 53.459 0.999884 74.4392 0.999884C77.3798 0.999884 80.361 1.15461 83.3007 1.45836C89.3373 2.08294 95.163 3.32721 100.722 5.11954"
        stroke="#63105F"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="11.73 11.73"
      />
      <path
        d="M75.7642 158.582C73.205 158.604 70.6356 158.511 68.0645 158.3C65.1338 158.06 62.1752 157.662 59.271 157.119C38.994 153.324 21.1558 142.584 8.4118 126.631"
        stroke="#63105F"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="11.73 11.73"
      />
      <path
        d="M148.799 64.8837C150.068 72.3339 150.358 80.0749 149.543 87.951C147.699 105.767 140.36 122.381 128.623 135.644"
        stroke="#63105F"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="11.73 11.73"
      />
    </svg>
  );
}

import styled from "styled-components";
import Popper from "@material-ui/core/Popper";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { VisuallyHide } from "storybook/styles/common";
import { Typography } from "storybook/components/typography";
import { FieldErrorDefault } from "storybook/components/field-error";
import { defaultTheme } from "storybook/themes/default";
import { CSSProperties } from "react";

const { colors } = defaultTheme;

/**
 * Top-level Wrappers
 */

export const FontWrapperDefault = styled(Typography).attrs({
  theme: "default",
  variant: "body1",
  component: "div",
})``;

export const StyledSelectWrapperDefault = styled.div<{
  $labelPlacement: string;
  $labelPosition: string;
}>`
  position: relative;
  ${({ $labelPlacement }) =>
    $labelPlacement === "outside" &&
    `display: flex;
     justify-content: space-evenly;`}

  input[type=hidden] {
    position: absolute;
  }

  ${({ $labelPosition }) =>
    $labelPosition === "top" &&
    `
    flex-direction: column;
    
    label {
      margin-bottom: 3px;
    }

    label, label + div {
      max-width: 100%;
    }
    `}
`;

/**
 * Border & Shrink Label Components
 */

export const StyledSelectBorder = styled.fieldset<{ $error?: boolean }>`
  border-color: ${({ $error }) => ($error ? colors.error : colors.inputBorder)};
  position: absolute;
  pointer-events: none;
  border-radius: inherit;
  border-width: 1px;
  border-style: solid;
  margin-left: 0;
  margin-right: 0;
  top: -5px;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 0 8px;
  overflow: hidden;

  // IE11
  *::-ms-backdrop,
  & {
    top: 0;
  }
`;

export const StyledShinkLabel = styled.legend<{
  $focused: boolean;
  $shrink: boolean;
}>`
  display: block;
  width: auto;
  height: 11px;
  padding: 0;
  color: inherit;
  font-size: 0.75rem;
  text-align: left;
  visibility: hidden;
  white-space: normal;
  box-sizing: border-box;
  max-width: ${({ $shrink }) => ($shrink ? "1000px" : 0)};
  transition: max-width 100ms cubic-bezier(0, 0, 0.2, 1) 50ms;

  &:focus {
    max-width: 1000px;
  }

  &:focus,
  &:hover {
    border: 1px solid ${colors.defaultTextButton};
  }

  > span {
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
  }
`;

/**
 * Label Variations
 */

export const StyledOutsideLabelDefault = styled.label<{
  $dirty: boolean;
  $disabled: boolean;
  $error?: boolean;
}>`
  flex: 1;
  max-width: 50%;
  margin-top: 6px;
  ${({ $disabled }) => $disabled && "opacity: 0.7;"}
  color: ${({ $dirty, $error }) => {
    if ($error) return colors.defaultTextButton;

    return $dirty ? colors.dirtyLabel : colors.defaultTextButton;
  }};
`;

export const StyledInsideLabelDefault = styled.label<{
  $shrink: boolean;
  $error?: boolean;
}>`
  top: 0;
  left: 0;
  z-index: 1;
  position: absolute;
  pointer-events: none;
  padding: 0 7px 0 7px;
  color: ${({ $error }) => ($error ? colors.error : colors.labelText)};
  transform: translate(10px, 8px) scale(1);
  transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
    transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  transform-origin: top left;

  ${({ $shrink, $error }) =>
    $shrink &&
    `color: ${$error ? colors.error : colors.ciqBrand};
     transform: translate(10px, -9px) scale(0.75);
     background-color: transparent;`};
`;

/**
 * Selected Item(s) / Placeholder Components
 */

export const StyledSelectedContainerDefault = styled.div<{
  $multiple: boolean;
}>`
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-self: center;
`;

export const StyledSelectedItemDefault = styled.div<{
  $dirty: boolean;
  $alignText: string;
  $labelPlacement: string;
}>`
  outline: none;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 6px;
  text-align: ${({ $alignText, $labelPlacement }) =>
    $labelPlacement === "inside" || $alignText === "left" ? "left" : "right"};
  color: ${({ $dirty, $labelPlacement }) =>
    $dirty && $labelPlacement === "outside" ? colors.dirtyLabel : "inherit"};
`;

export const StyledPlaceholderDefault = styled.div<{ $alignText: string }>`
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: ${({ $alignText }) => $alignText};
  padding-right: 6px;
`;

/**
 * Select Component Variations
 */

export const StyledSelectDefault = styled.div<{
  $disabled?: boolean;
  $focused: boolean;
  $error?: boolean;
}>`
  flex: 1;
  display: flex;
  justify-content: end;
  border-radius: 8px;
  position: relative;
  color: ${colors.ciqBrand};
  transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-sizing: border-box;
  user-select: none;
  white-space: nowrap;
  line-height: 19px;
  max-width: 50%;

  &:focus {
    outline: none;
  }

  ${({ $disabled, $error }) =>
    !$disabled &&
    `&:hover {
       ${StyledSelectBorder} {
         border-color: ${$error ? colors.error : colors.defaultTextButton};
       }
     }`}

  ${({ $disabled, $error }) =>
    !$disabled &&
    `&:focus {
        ${StyledSelectBorder} {
          border: 2px solid ${$error ? colors.error : colors.ciqBrand};
          background-color: ${colors.focusedInputBg};
        }
      }`}
 
  ${StyledSelectBorder} {
    ${({ $focused, $disabled, $error }) =>
      $focused &&
      !$disabled &&
      `border: 2px solid ${$error ? colors.error : colors.ciqBrand};
       background-color: ${colors.focusedInputBg};`}
  }

  > * {
    overflow: hidden;
  }

  ${({ $disabled }) => $disabled && "opacity: 0.7;"}
`;

export const StyledSingleSelectDefault = styled(StyledSelectDefault)<{
  $focused: boolean;
}>`
  font-weight: 700; // Required by Ithaca
  font-variation-settings: "wght" 700; // Required by Storybook
  padding: 8px 8px 8px 16px;
  height: 37px;

  ${StyledSelectBorder} {
    // IE11
    *::-ms-backdrop,
    & {
      top: 0;
    }
  }
`;

export const StyledMultiSelectDefault = styled(StyledSelectDefault)`
  font-weight: 700; // Required by Ithaca
  font-variation-settings: "wght" 700; // Required by Storybook
  padding: 8px 8px 8px 28px;
  min-height: 37px;
`;

export const StyledSingleSelectWithInsideLabel = styled(StyledSelectDefault)<{
  $focused: boolean;
  $shrink: boolean;
}>`
  z-index: 1;
  height: 37px;
  max-width: 100%;
  padding: 8px 8px 8px 16px;
  font-weight: 400; // Required by Ithaca
  font-variation-settings: "wght" 400; // Required by Storybook
  color: ${colors.defaultTextButton};

  ${StyledSelectedItemDefault} {
    display: flex;
  }

  ${StyledSingleSelectDefault} {
    z-index: 1;
  }

  ${StyledSelectBorder} {
    // IE11
    *::-ms-backdrop,
    & {
      border-radius: 0;
      top: ${({ $shrink }) => ($shrink ? "-5px" : 0)};
    }
  }
`;

export const StyledArrowDefault = styled(ArrowDropDownIcon)<{
  $labelPlacement: string;
}>`
  width: 20px;
  height: 100%;
  display: flex;
  transform: scale(1.2);
  color: ${colors.labelText};
  padding-right: ${({ $labelPlacement }) =>
    $labelPlacement === "inside" ? "3px" : 0};
`;

/**
 * Select Pop-up Menu Components
 */

export const StyledPopperDefault = styled(Popper)<{
  $width: number;
  $maxHeight: CSSProperties["maxHeight"];
}>`
  // In Ithaca, the modal renders at z-index 1300.
  // As there could be multiple modals with custom z-indexes calculated
  // on the fly, this number is high to ensure the select popup appears in front.
  // However, this is brittle and needs to be solved properly.
  z-index: 10000;
  padding: 8px 0;
  overflow: auto;
  background-color: white;
  border-radius: 8px;
  user-select: none;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  min-width: ${({ $width }) => ($width ? `${$width}px` : "auto")};
  max-height: ${({ $maxHeight }) => $maxHeight || "95vh"};

  // Font styling is needed due to the portal not being within the font wrapper.
  font-family: ${defaultTheme.typography.fontFamily};
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;

  // IE11
  *::-ms-backdrop,
  & {
    border: 1px solid ${colors.inputBorder};
  }
`;

export const StyledItemDefault = styled.div<{
  $selected: boolean;
  $focused: boolean;
}>`
  cursor: pointer;
  user-select: none;
  padding: 6px 16px;
  color: ${colors.defaultTextButton};
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover,
  &:focus,
  &:active {
    background-color: ${({ $selected }) =>
      $selected ? colors.focusedInputBg : colors.itemHighlight};
  }

  ${({ $selected }) =>
    $selected && `background-color: ${colors.itemHighlight};`}

  ${({ $focused }) => $focused && `background-color: ${colors.itemHighlight}`}
`;

/**
 * Misc Components
 */

export const StyledErrorTextDefault = styled(FieldErrorDefault)<{
  $hide: boolean;
}>`
  margin: 5px 0 0 0;
  font-size: 12px !important;
  ${({ $hide }) => $hide && VisuallyHide}
`;

export const StyledCheckboxOptionDefault = styled.div`
  display: flex;

  svg {
    margin-right: 12px;
    color: ${colors.ciqBrand};
  }
`;

import React from "react";
import type { TypographyProps as MuiTypographyProps } from "@material-ui/core";
import { ComponentThemeProvider } from "storybook/context/component-theme";
import { defaultTheme } from "storybook/themes/default";
import { legacyTheme } from "storybook/themes/legacy";

import { StyledTypographyDefault } from "./theme/default";
import { StyledTypographyLegacy } from "./theme/legacy";

// TODO: TypeScript does not recognise "component" prop
// despite it being in the docs for v4.
type TypographyPropsBase = Pick<
  MuiTypographyProps,
  "variant" | "children" | "noWrap"
> & { component?: string; role?: string };

export const defaultVariants = [
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "body1",
  "body2",
  "subtitle1",
  "subtitle2",
  "overline",
  "button",
] as const;

export const legacyVariants = [
  "h1",
  "h2",
  "h3",
  "h4",
  "h5",
  "h6",
  "body1",
  "body2",
] as const;

export interface DefaultTypographyProps extends TypographyPropsBase {
  theme: "default";
  color: keyof typeof defaultTheme.colors;
  variant: Extract<
    TypographyPropsBase["variant"],
    typeof defaultVariants[number]
  >;
}

export interface LegacyTypographyProps extends TypographyPropsBase {
  theme: "legacy";
  color: keyof typeof legacyTheme.colors;
  variant: Extract<
    TypographyPropsBase["variant"],
    typeof legacyVariants[number]
  >;
}

export type TypographyProps = DefaultTypographyProps | LegacyTypographyProps;

const styledTypographyMap = {
  default: StyledTypographyDefault,
  legacy: StyledTypographyLegacy,
} as const;

export function Typography({
  theme,
  children,
  color,
  ...rest
}: TypographyProps): JSX.Element {
  const StyledTypography = styledTypographyMap[theme];

  return (
    <ComponentThemeProvider theme={theme}>
      {/* @ts-ignore 'StyledTypography' does not have any construct" error */}
      <StyledTypography {...rest} color="inherit" $color={color}>
        {children}
      </StyledTypography>
    </ComponentThemeProvider>
  );
}

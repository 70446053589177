import { Button as MUIButton } from "@material-ui/core";
import styled from "styled-components";
import { selector } from "../../../utils/selector";
import { futureTheme } from "../../../themes/future";

const { colors } = futureTheme;

export const StyledButtonFuture = styled(MUIButton)`
  &${selector`.MuiButton-root`} {
    border-radius: 8px;
    text-transform: none;
    letter-spacing: 0.75px;

    // Default state is equivalent to .MuiButton-sizeMedium
    font-size: 16px;
    line-height: 20px;
    padding: 8px 16px;
  }
  &${selector`.MuiButton-outlined`} {
    padding: 7px 15px;
  }
  & ${selector`.MuiButton-endIcon`} {
    margin-left: 6px;
  }
  & ${selector`.MuiButton-startIcon`} {
    margin-right: 6px;
  }

  &${selector`.MuiButton-sizeSmall`} {
    line-height: 16px;
    padding: 4px 16px;
    &${selector`.MuiButton-outlined`} {
      padding: 3px 15px;
    }
    & ${selector`.MuiButton-endIcon`} {
      margin-left: 5px;
    }
    & ${selector`.MuiButton-startIcon`} {
      margin-right: 5px;
    }
  }

  &${selector`.MuiButton-sizeLarge`} {
    line-height: 24px;
    padding: 12px 16px;
    &${selector`.MuiButton-outlined`} {
      padding: 11px 15px;
    }
    & ${selector`.MuiButton-endIcon`} {
      margin-left: 10.5px;
    }
    & ${selector`.MuiButton-startIcon`} {
      margin-right: 10.5px;
    }
  }

  & ${selector`.MuiButton-iconSizeSmall`} {
    font-size: 16px;
    > *:first-child {
      font-size: inherit;
    }
  }
  &
    ${selector`.MuiButton-iconSizeMedium`},
    &
    ${selector`.MuiButton-iconSizeLarge`} {
    font-size: 20px;
    > *:first-child {
      font-size: inherit;
    }
  }

  &${selector`.MuiButton-contained`},
    &${selector`.MuiButton-containedPrimary`},
    &${selector`.MuiButton-containedSecondary`} {
    background-color: ${colors.ciqBrand};
    color: ${colors.white};
    &:hover {
      background: ${colors.ciqBrandDark};
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    }
    &:focus {
      background: ${colors.ciqBrandLight};
      box-shadow: none;
    }
    &:disabled {
      background: ${colors.disabledGrey};
    }
  }

  &${selector`.MuiButton-outlined`},
    &${selector`.MuiButton-outlinedPrimary`},
    &${selector`.MuiButton-outlinedSecondary`} {
    background: ${colors.white};
    color: ${colors.ciqBrand};
    border-color: ${colors.graphite};

    &:hover {
      background: ${colors.white};
      color: ${colors.ciqBrandDark};
      border-color: ${colors.graphite};
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    }

    &:focus {
      background: ${colors.white};
      color: ${colors.ciqBrandDark};
      border-color: ${colors.graphite};
      box-shadow: none;
    }

    &:disabled {
      color: ${colors.disabledGrey};
      border-color: ${colors.disabledGrey};
    }
  }

  &${selector`.MuiButton-text`},
    &${selector`.MuiButton-textPrimary`},
    &${selector`.MuiButton-textSecondary`} {
    color: ${colors.ciqBrand};
    background: none;

    &:hover {
      background: ${colors.lightGrey};
      color: ${colors.ciqBrandDark};
      box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
    }

    &:focus {
      background: ${colors.lightGrey};
      color: ${colors.ciqBrandDark};
      box-shadow: none;
    }

    &:disabled {
      color: ${colors.disabledGrey};
      background: none;
    }
  }
`;

import styled from "styled-components";

export const FormRow = styled.div`
  padding-bottom: 10px;

  // Date picker is not sitting flush within modal
  // due to issues with popper and its placemenet.
  .react-datepicker-popper {
    inset: unset !important;
    transform: none !important;
    left: 0;
    bottom: 25px !important;
  }
`;

export const ModalBodyWrapper = styled.div`
  word-break: break-word;
`;

import React, { useEffect, useCallback, useMemo, useState } from "react";
import type { StyledComponent } from "styled-components";
import {
  StyledRadioLabelLegacy,
  StyledRadioLabelDefault,
  StyledRadioButtonLegacy,
  StyledRadioButtonDefault,
  StyledRadioInputLegacy,
  StyledRadioInputHidden,
  StyledRadioIndicator,
  StyledRadioInputContainer,
} from "storybook/components/radio/styles";
import {
  RadioDisplayComponentProps,
  RadioThemeType,
} from "storybook/components/radio/types";
import {
  DefaultButtonOutlinedProps,
  LegacyButtonProps,
} from "storybook/components/button";
import { FormFieldType } from "storybook/utils/form";
import { FieldValues, get, useFormContext } from "react-hook-form";
import { useUniqueId } from "storybook/utils/use-unique-id";

export type RadioButtonDefaultProps = RadioDisplayComponentProps & {
  size?: DefaultButtonOutlinedProps["size"];
};

export type RadioButtonLegacyProps = RadioDisplayComponentProps & {
  size?: LegacyButtonProps["size"];
};

function DefaultComponent({
  children,
  selected,
  keyboardFocus,
  handleMouseMove,
}: RadioDisplayComponentProps) {
  return (
    <StyledRadioInputContainer>
      <StyledRadioIndicator
        selected={selected}
        $keyboardFocus={keyboardFocus}
        onMouseMove={handleMouseMove}
      />
      {children}
    </StyledRadioInputContainer>
  );
}

function DefaultComponentLegacy({ children }: RadioDisplayComponentProps) {
  return <span>{children}</span>;
}

type StyledComponentMap = Record<
  RadioThemeType["name"],
  StyledComponent<any, any>
>;

type DefaultComponentMap = Record<RadioThemeType["name"], any>;

const styledRadioLabelMap: StyledComponentMap = {
  default: StyledRadioLabelDefault,
  legacy: StyledRadioLabelLegacy,
};

const styledRadioInputMap: StyledComponentMap = {
  default: StyledRadioInputHidden,
  legacy: StyledRadioInputLegacy,
};

const defaultComponentMap: DefaultComponentMap = {
  default: DefaultComponent,
  legacy: DefaultComponentLegacy,
};

export interface RadioType<T> extends FormFieldType<T> {
  theme: RadioThemeType["name"];
  value: string;
  displayValue?: string | JSX.Element;
  component?: React.FC<RadioDisplayComponentProps>;
  disabled?: boolean;
  autoFocus?: boolean;
}

// IE11 is not working with multiple registered Radio buttons that use the same name.
// Work around this issue by using a uuid for the Radio name, and having the first
// detected Radio instance for a name acting as the registrar.
const instanceLookups = new Map();

export function Radio<T>({
  name,
  value,
  component,
  disabled,
  autoFocus,
  displayValue,
  theme,
  registerOptions,
}: RadioType<T>): JSX.Element {
  const [keyboardFocus, setKeyboardFocus] = useState(false);
  const { formState, register, getValues, setValue } = useFormContext();

  // TODO - Not sure why yet, but without grabbing the error, radio states
  // don't change - guess it has a side effect. Investigate, but for now,
  // just ignore the unused variable.
  // @ts-ignore reason above
  const error = get(formState.errors, name); // eslint-disable-line @typescript-eslint/no-unused-vars
  const selectedValue = getValues(name);
  const isDisabled = disabled || formState.isSubmitting;

  const Component = component || defaultComponentMap[theme];

  const RadioLabel = styledRadioLabelMap[theme];
  const RadioInput = component
    ? StyledRadioInputHidden
    : styledRadioInputMap[theme];

  const uuid = useUniqueId("Radio");
  useEffect(() => {
    if (!instanceLookups.has(name)) {
      instanceLookups.set(name, uuid);
    }
    return () => {
      if (instanceLookups.get(name) === uuid) {
        instanceLookups.delete(name);
      }
    };
  }, [name, uuid]);

  const onBlur = useMemo(() => {
    let _onBlur;
    if (instanceLookups.get(name) === uuid) {
      const { onBlur } = register(name, registerOptions);
      _onBlur = onBlur;
    }
    return _onBlur;
  }, [name, register, registerOptions, uuid]);

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setKeyboardFocus(false);

      if (onBlur) {
        onBlur(event);
      }
    },
    [onBlur]
  );

  const handleFocus = useCallback(() => {
    if (selectedValue !== value) {
      setKeyboardFocus(true);
    }
  }, [selectedValue, value]);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      setValue(name, e.target.value as FieldValues[typeof name], {
        shouldValidate: true,
        shouldDirty: true,
        shouldTouch: true,
      });
      setKeyboardFocus(false);
    }
  };

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLInputElement>) => {
      // Keyboard events will have [0,0], click events will not.
      setKeyboardFocus(event.screenX === 0 && event.screenY === 0);
    },
    []
  );

  const handleMouseMove = useCallback(() => {
    setKeyboardFocus(false);
  }, []);

  const selected = selectedValue === value;

  return (
    <RadioLabel data-testid="Radio__Label">
      <RadioInput
        name={uuid}
        onChange={onChange}
        data-testid="Radio__Input"
        type="radio"
        value={value}
        disabled={isDisabled}
        // Auto focus is useful in this case to begin keyboard navigation across radio buttons
        // eslint-disable-next-line jsx-a11y/no-autofocus
        autoFocus={autoFocus}
        checked={selected}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onClick={handleClick}
      />
      <Component
        selected={selected}
        disabled={isDisabled}
        keyboardFocus={keyboardFocus}
        handleMouseMove={handleMouseMove}
      >
        {displayValue || value}
      </Component>
    </RadioLabel>
  );
}

export function RadioButtonLegacy({
  selected,
  children,
  disabled,
  size = "large",
  keyboardFocus,
  handleMouseMove,
}: RadioButtonLegacyProps): JSX.Element {
  return (
    <StyledRadioButtonLegacy
      size={size}
      theme="legacy"
      // @ts-ignore
      component="div"
      // This prevents the button from interfering with the natural browser
      // keyboard navigation of the radio buttons, which is the up and down arrows.
      // We don't tab across the radios, we tab onto them once and press up/down to change selection.
      tabIndex={-1}
      fullWidth={true}
      disabled={disabled}
      variant={selected ? "contained" : "outlined"}
      color={selected ? "primary" : "default"}
      $keyboardFocus={keyboardFocus}
      onMouseMove={handleMouseMove}
    >
      {children}
    </StyledRadioButtonLegacy>
  );
}

export function RadioButtonDefault({
  selected,
  children,
  disabled,
  size = "large",
  keyboardFocus,
  handleMouseMove,
}: RadioButtonDefaultProps): JSX.Element {
  return (
    <StyledRadioButtonDefault
      size={size}
      theme="default"
      // @ts-ignore
      component="div"
      // This prevents the button from interfering with the natural browser
      // keyboard navigation of the radio buttons, which is the up and down arrows.
      // We don't tab across the radios, we tab onto them once and press up/down to change selection.
      tabIndex={-1}
      fullWidth={true}
      disabled={disabled}
      variant="outlined"
      selected={selected}
      $keyboardFocus={keyboardFocus}
      onMouseMove={handleMouseMove}
    >
      {children}
    </StyledRadioButtonDefault>
  );
}

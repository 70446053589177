import React, { useCallback } from "react";
import { Header, Nav, RestartButton } from "./styles";
import { useRiskResultsKiosk } from "hooks/use-risk-results";
import { ModalType, usePatientInterest } from "context/patient-interest";

export function ResultsHeader() {
  const { setModalType } = usePatientInterest();
  const results = useRiskResultsKiosk();

  const handleRestartClick = useCallback(() => {
    setModalType(ModalType.LOCK_RESTART);
  }, [setModalType]);

  return (
    <Header>
      <Nav role="nav">
        <img alt="CancerIQ logo" src="/ciq-logo.png" height="30" />
        {/* TODO: Do we need to clear any local storage data when naviagating
                back to the Kiosk landing after seeing results? */}
        {results?.lockRestart ? (
          <RestartButton variant="outlined" onClick={handleRestartClick}>
            Restart
          </RestartButton>
        ) : (
          <RestartButton variant="outlined" href="/institution">
            Restart
          </RestartButton>
        )}
      </Nav>
    </Header>
  );
}

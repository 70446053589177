import styled from "styled-components";
import { defaultTheme } from "../../../themes/default";

const { colors } = defaultTheme;

export const StyledDetailsDefault = styled.details`
  width: 100%;
  padding: 16px;
  max-height: 250px;
  border-radius: 5px;
  box-shadow: 0px 1px 3px 1px #00000026;
  box-shadow: 0px 1px 2px 0px #0000004d;

  &[open] {
    max-height: 100%;
  }

  &[open] > summary > div > svg {
    transform: rotate(180deg);
  }

  &[open] > summary > :last-child {
    display: none;
  }
`;

export const StyledSummaryDefault = styled.summary`
  width: 100%;
  display: flex;
  list-style: none;
  flex-direction: column;
  color: ${colors.ciqBrandDark};
`;

export const StyledTitleContainer = styled.div`
  display: flex;
  margin-bottom: 16px;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
`;

export const StyledPreviewContainer = styled.div`
  opacity: 40%;
  overflow: hidden;
  max-height: 180px;
`;

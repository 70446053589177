import styled from "styled-components";

export const MainContainer = styled.div`
  margin: 0 auto 20px auto;
  max-width: 833px;
  padding: 0 8px;
  -webkit-font-smoothing: antialiased;
`;

export const EditAccountHeader = styled.h1`
  margin-top: 38px;
  margin-bottom: 20px;
  height: 27px;
  opacity: 0.8;
  font-size: 27px;
  font-weight: 300;
  font-style: normal;
  font-stretch: normal;
  color: #6b6d88;
`;

export const PasswordPanel = styled.div`
  background-color: #fff;
  border: solid 1px rgba(119, 56, 92, 0.15);
  margin-bottom: 32px;
  border-radius: 8px;
`;

export const PanelHeaderContainer = styled.div`
  height: 60px;
  background-color: #ebebec;
  border: solid 1px rgba(119, 56, 92, 0.15);
  border-radius: 8px 8px 0px 0px;
`;

export const PanelHeader = styled.span`
  font-size: 24px;
  font-weight: 500;
  color: #85869c;
  padding-left: 20px;
  line-height: 60px;
`;

export const PanelTextContainer = styled.div`
  padding: 10px 20px;

  .panel-text {
    font-size: 14px;
  }

  .panel-text > a {
    cursor: pointer;
    color: #337ab7;
    text-decoration: none;
    background-color: transparent;
  }
`;

export const TryAgainText = styled.div`
  font-size: 14px;
  color: #0379c5;
`;

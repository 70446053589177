import "fontsource-dosis";
import { createTheme, ThemeType } from "storybook/utils/theme";

const colors = {
  ciqBrand: "#6A6E87",
  ciqBrandLight: "#878B9F",
  ciqBrandDark: "#4A4D5E",
  highlight: "#BF2A7F",
  highlightLight: "#CB5498",
  highlightDark: "#851D58",
  error: "#DB6068",
  grey: "#C0C0C0",
  greyLight: "#F0F0F0",
  greyLotion: "#FAFAFA",
  greyBright: "#EEEEEE",
  greyDark: "#B2B2B2",
  greyDarkest: "#171717",
  focusBlue: "#015ECC",
  white: "#FFFFFF",
  hematite: "#141414",
  oldLavendar: "#6a6b80",
  disabledDark: "#A3A3A3",
  disabledLight: "#DCDCDC",
  tableColor: "#757575",
  tableRowBorder: "#EEEEEE",
  defaultTextButton: "rgba(0, 0, 0, 0.87)",
  dirtyLabel: "#FF9800",
  disabledText: "rgba(0, 0, 0, 0.38)",
  black: "#000000",
  ebonyClay: "#2E3646",
  zircon: "#F3F8FF",
  governorBay: "#323EBE",
  monza: "#AF021C",
  apple: "#478C3B",

  // At some point we're going to need to sort out our colour palette.
  inputBorder: "rgba(0, 0, 0, 0.26)",
  labelText: "rgba(0, 0, 0, 0.54)",
  focusedInputBg: "rgba(0, 0, 0, 0.04)",
  itemHighlight: "rgba(0, 0, 0, 0.08)",
  formHeaderText: "#616161",
} as const;

interface DefaultThemeType extends ThemeType {
  colors: typeof colors;
}

export const defaultTheme = createTheme({
  colors,
  name: "default",
  typography: {
    fontFamily: '"Dosis", Arial, "Noto Sans", sans-serif, "Apple Color Emoji"',
  },
  // Using the Tabs component in Ithaca is causing many of the old
  // MUI Typgraphy and button instances there to use MUI's default
  // blue primary colour. This is a workaround to ensure the correct colour is used.
  palette: {
    primary: {
      main: colors.ciqBrand,
    },
  },
  // Temporary override needed because some selects are getting a 4px
  // border radius in Ithaca when the Tabs component wraps the content.
  // These are not the Storybook selects, but MUI's component.
  // Once all selects in Ithaca use the Storybook component, we can remove this.
  overrides: {
    MuiOutlinedInput: {
      root: {
        borderRadius: "8px",
      },
    },
    MuiTypography: {
      overline: {
        fontWeight: 700,
      },
    },
  },
}) as DefaultThemeType;

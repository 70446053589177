import React from "react";
import { Link } from "react-router-dom";
import type { ButtonProps as MuiButtonProps } from "@material-ui/core";
import { ComponentThemeProvider } from "storybook/context/component-theme";
import { checkProtocol } from "storybook/utils/helpers";
import { StyledButtonDefault } from "./theme/default";
import { StyledButtonFuture } from "./theme/future";
import { StyledButtonLegacy } from "./theme/legacy";

type ButtonPropsBase = Pick<
  MuiButtonProps,
  | "type"
  | "size"
  | "children"
  | "startIcon"
  | "endIcon"
  | "fullWidth"
  | "disabled"
  | "href"
  | "ref"
  | "onClick"
  | "onClickCapture"
  | "onMouseEnter"
>;

export interface LegacyButtonProps extends ButtonPropsBase {
  theme: "legacy";
  variant: "outlined" | "contained";
  color: "primary" | "secondary" | "default";
}

export interface DefaultButtonContainedProps extends ButtonPropsBase {
  theme: "default";
  variant: "contained";
  color: "default" | "secondary";
  form?: string;
}

export interface DefaultButtonOutlinedProps extends ButtonPropsBase {
  theme: "default";
  variant: "outlined";
  color?: undefined;
  form?: string;
}

export interface DefaultButtonTextProps extends ButtonPropsBase {
  theme: "default";
  variant: "text";
  color: "inherit" | "default";
}

export interface FutureButtonProps extends ButtonPropsBase {
  theme: "future";
  variant: "outlined" | "contained" | "text";
}

export type ButtonProps =
  | DefaultButtonTextProps
  | DefaultButtonContainedProps
  | DefaultButtonOutlinedProps
  | LegacyButtonProps
  | FutureButtonProps;

const styledButtonMap = {
  default: StyledButtonDefault,
  legacy: StyledButtonLegacy,
  future: StyledButtonFuture,
} as const;

export function Button({
  theme,
  type = "button",
  ...props
}: ButtonProps): JSX.Element {
  const StyledButton = styledButtonMap[theme];

  let linkProps = {};
  if ("href" in props) {
    const hasProtocol = checkProtocol(props.href);

    linkProps = {
      // Button is regular link if it has protocol.
      // Otherwise it's a React Router link.
      component: hasProtocol ? "a" : Link,
      [hasProtocol ? "href" : "to"]: props.href,
    };
  }

  return (
    <ComponentThemeProvider theme={theme}>
      <StyledButton
        {...props}
        {...linkProps}
        type={type}
        ref={props.ref}
        disableElevation={true}
        disableRipple={true}
        disableTouchRipple={true}
        disableFocusRipple={true}
        data-testid="Button"
      />
    </ComponentThemeProvider>
  );
}

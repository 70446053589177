import {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
  createContext,
} from "react";
import { useHistory } from "react-router-dom";
import { jwtDecode, JwtPayload } from "jwt-decode";
// @ts-ignore - auth0-js is not typed
import { default as auth0 } from "auth0-js";
import { getProtocolHost } from "helpers";
import {
  AUTH0_DOMAIN,
  SPECIALIST_HOSTNAME,
  AUTH0_INSTITUTION_CLIENT_ID,
} from "env-vars";

const webAuth = new auth0.WebAuth({
  domain: AUTH0_DOMAIN,
  clientID: AUTH0_INSTITUTION_CLIENT_ID,
  redirectUri: `${getProtocolHost()}/institution`,
  responseType: "token id_token",
  scope: "openid profile email",
});

type IntegratedResponse = {
  is_emr_integrated: boolean;
};

type AuthResult = {
  idToken: string;
  idTokenPayload: {
    app_metadata: {
      location_id: string;
    };
    email: string;
  };
};

type Props = {
  children: React.ReactNode;
};

export type KioskAuthContextType = {
  logout: () => void;
  isLoading: boolean;
  isAuthenticated: boolean;
  isIntegrated: boolean | null;
};

export const KioskAuthContext = createContext<KioskAuthContextType | undefined>(
  undefined
);

export function KioskAuthProvider({ children }: Props) {
  const history = useHistory();
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [isIntegrated, setIntegrated] = useState<boolean | null>(null);
  const [authChecked, setAuthChecked] = useState(false);
  const [integratedChecked, setIntegratedChecked] = useState(false);

  const isLoading = !authChecked || !integratedChecked;

  const logout = useCallback(() => {
    localStorage.clear();

    // End auth0 session. this doesn't affect app behavior, just here for completeness
    webAuth.logout();
  }, []);

  const handleTokenAndRedirect = useCallback(() => {
    const idTokenExp = localStorage.getItem("id_token_exp");
    const idToken = localStorage.getItem("id_token");

    const isTokenValid =
      Boolean(idToken) &&
      Boolean(idTokenExp) &&
      Number(idTokenExp) > Date.now();

    setAuthenticated(isTokenValid);

    if (!isTokenValid) {
      localStorage.clear();
      webAuth.authorize();
      return;
    }

    setAuthChecked(true);
  }, []);

  useEffect(() => {
    webAuth.parseHash((error: unknown, authResult: AuthResult) => {
      if (error) {
        setAuthChecked(true);
        console.error("Error during parseHash: ", error);
        localStorage.clear();
        webAuth.authorize();
        return;
      }

      const idToken = authResult?.idToken;

      if (idToken) {
        localStorage.setItem("id_token", authResult.idToken);

        const { exp = 0 } = jwtDecode<JwtPayload>(idToken);
        const calculatedExpiry = (exp * 1000).toString();

        localStorage.setItem("id_token_exp", calculatedExpiry);

        const appMetaData = authResult?.idTokenPayload?.app_metadata;
        const locationId = appMetaData?.location_id;
        const email = authResult?.idTokenPayload.email;

        // There should never be a case where location_id is not present for a kiosk user
        localStorage.setItem("location_id", locationId);

        if (email) {
          localStorage.setItem("ciq_email", email);
        }
        // Clears hash in URL produced by Auth0 redirect
        history.replace(window.location.pathname + window.location.search);
      }

      handleTokenAndRedirect();
    });
  }, [handleTokenAndRedirect, history]);

  useEffect(() => {
    if (!isAuthenticated) return;

    (async () => {
      const jwt = localStorage.getItem("id_token");

      try {
        const response = await fetch(
          `${SPECIALIST_HOSTNAME}/api/v1/institutions/is_emr_integrated`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${jwt}`,
            },
          }
        );

        if (!response.ok) {
          console.error("Error fetching integrated status");

          if (response.status === 401) {
            setAuthenticated(false);
            logout();
          }

          return;
        }

        const integratedResponse: IntegratedResponse = await response.json();

        setIntegrated(integratedResponse.is_emr_integrated);
        setIntegratedChecked(true);
      } catch (error) {
        console.error("Error fetching integrated status");

        setIntegratedChecked(true);
      }
    })();
  }, [logout, isAuthenticated]);

  const contextProps = useMemo(
    () => ({
      logout,
      isLoading,
      isIntegrated,

      isAuthenticated,
    }),
    [isAuthenticated, isIntegrated, isLoading, logout]
  );

  return (
    <KioskAuthContext.Provider value={contextProps}>
      {children}
    </KioskAuthContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export const useKioskAuth = () => {
  const context = useContext(KioskAuthContext);

  if (context === undefined) {
    throw new Error("useKioskAuth must be used within a KioskAuthProvider");
  }
  return context;
};

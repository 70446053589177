import { useState, useEffect, useRef, useCallback } from "react";
import PlayArrowIcon from "@material-ui/icons/PlayArrow";
import { IconContainer, VideoContainer } from "./styles";

type Props = {
  src?: string;
  label?: string;
};

export function Video({ src, label = "welcome video" }: Props) {
  const videoRef = useRef<HTMLVideoElement>(null);
  const videoContainerRef = useRef<HTMLDivElement>(null);
  const [isPlaying, setPlaying] = useState<boolean>(false);

  const handleVideoEnded = useCallback(() => {
    setPlaying((playing) => !playing);
  }, []);

  const handleLoadedMetadata = useCallback(() => {
    if (videoRef.current) {
      videoRef.current.currentTime = 1;
    }
  }, []);

  const restartVideoTimeout = useCallback(() => {
    const interval = setInterval(() => {
      if (videoRef.current && videoRef.current.paused) {
        videoRef.current.currentTime = 1;
      }
    }, 300000); // 5 minutes

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    const video = videoRef.current;

    if (video) {
      video.addEventListener("ended", handleVideoEnded);
      video.addEventListener("loadedmetadata", handleLoadedMetadata);

      const clearRestartInterval = restartVideoTimeout();

      return () => {
        video.removeEventListener("ended", handleVideoEnded);
        video.removeEventListener("loadedmetadata", handleLoadedMetadata);

        clearRestartInterval();
      };
    }
  }, [handleVideoEnded, handleLoadedMetadata, restartVideoTimeout]);

  const togglePlaying = useCallback(() => {
    const video = videoRef.current;
    const videoContainer = videoContainerRef.current;

    if (video && videoContainer) {
      setPlaying((playing) => !playing);

      if (video.paused) {
        video.play();
      } else {
        video.pause();
      }
    }
  }, []);

  if (!src) return null;

  return (
    <VideoContainer ref={videoContainerRef} onClick={togglePlaying}>
      <IconContainer $isPlaying={isPlaying}>
        <PlayArrowIcon />
      </IconContainer>
      <video src={src} ref={videoRef} preload="true" aria-label={label} />
    </VideoContainer>
  );
}

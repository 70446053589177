import { useCallback } from "react";
import { TileButton, TileInfo, Icon, Wrapper } from "./styles";

type TileButtonComponentProps = {
  selected?: string;
  name: string;
  label: string;
  moduleIcon: string;
  setSelected: (id: string) => void;
  disabled?: boolean;
};

export function SurveyButton({
  name,
  label,
  selected,
  moduleIcon,
  setSelected,
  disabled,
}: TileButtonComponentProps) {
  const handleClick = useCallback(() => {
    setSelected(name);
  }, [name, setSelected]);

  const isActive = selected === name;

  return (
    <Wrapper $disabled={disabled}>
      <TileButton
        role="radio"
        $isActive={isActive}
        onClick={handleClick}
        aria-checked={isActive}
        disabled={disabled}
      >
        <TileInfo $isActive={isActive} $disabled={disabled}>
          <Icon $isActive={isActive} $disabled={disabled}>
            <use href={`/${moduleIcon}`}></use>
          </Icon>
          <p>{label}</p>
        </TileInfo>
      </TileButton>
    </Wrapper>
  );
}

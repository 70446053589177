import "@fontsource/raleway";
import "@fontsource/raleway/700.css";
import { createTheme, ThemeType } from "storybook/utils/theme";

const colors = {
  red: "#ed5565",
  green: "#1AB394",
  greenDark: "#18A689",
  blue: "#1c84c6",
  black: "#000000",
  blueDark: "#1a7bb9",
  mercury: "#E3E3E3",
  grey: "#c2c2c2",
  greyBright: "#EFEFEF",
  greyLynch: "#606C88",
  greyDark: "#bababa",
  greyDarkest: "#515354",
  greyStorm: "#6a6e87",
  iron: "#e5e6e7",
  darkCharcoal: "#333333",
  platinum: "#E5E5E5",
  white: "#FFFFFF",
  wildSand: "#F5F5F5",
  cosmic: "#76395C",
  mineShaft: "#262626",
} as const;

interface LegacyThemeType extends ThemeType {
  colors: typeof colors;
}

export const legacyTheme = createTheme({
  colors,
  name: "legacy",
  typography: {
    fontFamily: '"Raleway", sans-serif',
  },
}) as LegacyThemeType;

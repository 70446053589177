import { useAdminContent } from "context/admin-content";
import { Button } from "storybook/components/button";
import { Typography } from "storybook/components/typography";
import { Video } from "components/video";
import { Loading } from "components/loading";
import { RiskEvaluationIcon } from "components/icons/risk-evaluation";
import {
  Container,
  Paragraph,
  MainContent,
  GlobalStyles,
  HeaderContent,
  VideoContainer,
  ButtonContainer,
  GraphicContainer,
} from "./styles";

type Props = {
  onStartClick: () => void;
  isSubmitting: boolean;
};

// Used by both patient and kiosk user pages
export function StartAssessment({ onStartClick, isSubmitting }: Props) {
  const adminData = useAdminContent();

  if (adminData?.isLoading) {
    return <Loading />;
  }

  const pageTitle = adminData?.landing.pageTitle;
  const disclaimer = adminData?.landing.disclaimer;
  const videoUrl = adminData?.landing.videoUrl;
  const videoEnabled = adminData?.landing.videoEnabled;

  return (
    <Container className="start-assessment">
      <GlobalStyles />
      <MainContent>
        <HeaderContent>
          <Typography
            variant="h5"
            component="h1"
            theme="default"
            color="ciqBrandDark"
          >
            {pageTitle}
          </Typography>
        </HeaderContent>
        <Paragraph>{disclaimer}</Paragraph>
        {videoEnabled && (
          <VideoContainer>
            <Video src={videoUrl} />
          </VideoContainer>
        )}
        <ButtonContainer>
          <Button
            size="large"
            color="default"
            theme="default"
            fullWidth={true}
            variant="contained"
            onClick={onStartClick}
            disabled={isSubmitting}
          >
            Start
          </Button>
        </ButtonContainer>
        <GraphicContainer>
          <RiskEvaluationIcon />
        </GraphicContainer>
      </MainContent>
    </Container>
  );
}

import { z } from "zod";

const today = new Date();
const minAge = new Date(
  today.getFullYear() - 18,
  today.getMonth(),
  today.getDate()
);

enum BirthSex {
  MALE = 1,
  FEMALE = 0,
}

export const accountSchema = z.object({
  account: z.object({
    firstName: z.string().min(1, { message: "Please enter your first name" }),
    lastName: z.string().min(1, { message: "Please enter your last name" }),
    dateOfBirth: z
      .string({ required_error: "Please enter your date of birth" })
      .refine(
        (dateString) => {
          const [year, month, day] = dateString.split("-").map(Number);
          const date = new Date(year, month - 1, day);

          const validationResult =
            date <= minAge &&
            date.getFullYear() === year &&
            date.getMonth() === month - 1 &&
            date.getDate() === day;

          return validationResult;
        },
        {
          message: "You must be at least 18 years old and provide a valid date",
        }
      ),
    phoneNumber: z
      .string({
        required_error: "Please enter your phone number",
        invalid_type_error: "Please enter your phone number",
      })
      .min(10, { message: "Please enter a valid phone number" }),
    birthSex: z.nativeEnum(BirthSex, {
      required_error: "Please select your birth sex",
      invalid_type_error: "Please select your birth sex",
    }),
  }),
});

export type Account = z.infer<typeof accountSchema>;

import { useState, useEffect } from "react";
import { SEND_EVENTS_TO_PENDO, PENDO_API_KEY } from "env-vars";
import { useAdminContent } from "context/admin-content";
import { pendoSnippet } from "./pendo-snippet";

type Props = {
  isRemote: boolean;
};

export function Pendo({ isRemote }: Props) {
  const adminContent = useAdminContent();
  const [pendoInitialised, setPendoInitialised] = useState<boolean | null>(
    null
  );

  useEffect(() => {
    if (pendoInitialised || !adminContent || adminContent?.isLoading) {
      return;
    }

    if (
      !PENDO_API_KEY ||
      !SEND_EVENTS_TO_PENDO ||
      !adminContent.featureFlags?.pendo_enabled
    ) {
      setPendoInitialised(false);

      return;
    }

    const patientId = localStorage.getItem("ciq_id");
    const email = localStorage.getItem("ciq_email");

    const user = {
      id: patientId,
      email: email,
    };

    const location = {
      systemName: adminContent.systemName,
      organizationId: adminContent.organizationId,
      institutionName: adminContent.locationName,
      organizationName: adminContent.organizationName,
    };

    pendoSnippet({ apiKey: PENDO_API_KEY, user, location, isRemote });

    setPendoInitialised(true);
  }, [isRemote, adminContent, pendoInitialised]);

  return <div style={{ display: "none" }} data-pendo-init={pendoInitialised} />;
}

import React, {
  useState,
  ChangeEvent,
  useCallback,
  useEffect,
  CSSProperties,
} from "react";
import { useFormContext } from "react-hook-form";
import { FormFieldType } from "storybook/utils/form";
import { ThemeType } from "storybook/utils/theme";
import { useUniqueId } from "storybook/utils/use-unique-id";
import { Input } from "storybook/components/input";
import { Select } from "storybook/components/select";
import { useDateState } from "./hooks/use-date-state";
import {
  Row,
  Column,
  Container,
  StyledError,
  StyledLegend,
  StyledFieldset,
} from "./styles";

export interface InputThemeType extends ThemeType {
  name: "default";
}

export interface DateOfBirthType<T> extends FormFieldType<T> {
  label?: string;
  disabled?: boolean;
  theme: InputThemeType["name"];
  maxMonthListHeight?: CSSProperties["maxHeight"];
  disablePortal?: boolean;
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

const dayName = "dob-day";
const monthName = "dob-month";
const yearName = "dob-year";

/**
 * Validation rules are normally driven by the Zod schema and the form state.
 * However, in this case, internal validation is done on day, month, and year (non-React Hook Form inputs).
 * The error state from React Hook Form is only read to get the error message, if it exists.
 */
export function DateOfBirth<T>({
  theme,
  name,
  disabled,
  maxMonthListHeight,
  label = "Date of Birth",
  disablePortal,
}: DateOfBirthType<T>) {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { formState, getFieldState } = useFormContext();
  const instanceId = useUniqueId("Input");
  const { date, dayError, monthError, yearError, updateDate } =
    useDateState(name);

  const { error } = getFieldState(name, formState);

  const showErrorMessage =
    formSubmitted && (error || dayError || monthError || yearError);

  const handleDayChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      updateDate("day", e.target.value);
    },
    [updateDate]
  );

  const handleMonthChange = useCallback(
    (selectedValue: string | number | boolean | null) => {
      updateDate("month", selectedValue as string);
    },
    [updateDate]
  );

  const handleYearChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      updateDate("year", e.target.value);
    },
    [updateDate]
  );

  useEffect(() => {
    // Workaround because formState.isSubmitted is getting reset to false
    // and it should always be true when the form is submitted once.
    if (formState.isSubmitted) {
      setFormSubmitted(true);
    }
  }, [formState.isSubmitted]);

  return (
    <Container>
      <input
        name={name}
        type="hidden"
        id={instanceId}
        value={date.formattedValue || ""}
      />
      <StyledFieldset>
        <StyledLegend>{label}</StyledLegend>
        <Row>
          <Column>
            <Select
              theme={theme}
              label="Month"
              name={monthName}
              disabled={disabled}
              hideDirty={true}
              labelPlacement="inside"
              onChange={handleMonthChange}
              maxListHeight={maxMonthListHeight}
              error={!!error || (monthError && formSubmitted)}
              disablePortal={disablePortal}
            >
              {months.map((monthName, index) => (
                <Select.Option
                  key={monthName}
                  value={String(index + 1).padStart(2, "0")}
                >
                  {monthName}
                </Select.Option>
              ))}
            </Select>
          </Column>
          <Column>
            <Input
              min={1}
              max={31}
              label="Day"
              theme={theme}
              type="tel"
              name={dayName}
              placeholder="DD"
              shrinkLabel={true}
              disabled={disabled}
              hideDirty={true}
              autoComplete="off"
              registerOptions={{
                onChange: handleDayChange,
              }}
              error={!!error || (dayError && formSubmitted)}
            />
          </Column>
          <Column>
            <Input
              min={1900}
              label="Year"
              type="tel"
              theme={theme}
              name={yearName}
              placeholder="YYYY"
              shrinkLabel={true}
              disabled={disabled}
              hideDirty={true}
              autoComplete="off"
              registerOptions={{
                onChange: handleYearChange,
              }}
              error={!!error || (yearError && formSubmitted)}
            />
          </Column>
        </Row>
        <Row>
          {showErrorMessage && (
            <StyledError>
              {error?.message || "Please enter a valid date"}
            </StyledError>
          )}
        </Row>
      </StyledFieldset>
    </Container>
  );
}

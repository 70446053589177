import styled from "styled-components";
import { Variant } from ".";

const borderColorMap: Record<Variant, string> = {
  info: "#949494",
  success: "#4CAF50",
  warning: "#F16D09",
  error: "#C71000",
};

const backgroundColorMap: Record<Variant, string> = {
  info: "#FAFAFA",
  success: "#F6FBF6",
  warning: "#FEF3E4",
  error: "#FDF4F2",
};

export const Container = styled.div<{
  $variant: Variant;
  $fullWidth?: boolean;
  $closeButton?: boolean;
}>`
  padding: 12px;
  border-radius: 4px;
  position: relative;
  padding: 12px 12px 12px 52px;
  background-color: ${({ $variant }) => backgroundColorMap[$variant]};
  border: 1px solid ${({ $variant }) => borderColorMap[$variant]};
  display: ${({ $fullWidth }) => ($fullWidth ? "block" : "inline-block")};
  ${({ $closeButton }) => $closeButton && "padding-right: 40px;"}
`;

export const TitleContainer = styled.div`
  display: flex;

  && {
    p {
      font-size: 14px;
      font-weight: 700;
      color: #000000b2;
    }
  }
`;

export const IconContainer = styled.div`
  position: absolute;
  top: 10px;
  left: 15px;
`;

export const ContentContainer = styled.div<{ $childrenAsText?: boolean }>`
  margin-top: 8px;

  ${({ $childrenAsText }) =>
    $childrenAsText &&
    `
      && {
        p {
          font-size: 14px;
          color: #000000B2;
        }
      }
    `}
`;

export const CloseIconContainer = styled.div`
  cursor: pointer;
  position: absolute;
  top: 10px;
  right: 13px;
`;

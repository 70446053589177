import { ReactElement } from "react";
import { Header } from "components/header";
import { ScrollbarProvider } from "context/scrollbar";
import { MainContainer, HeaderContainer } from "./styles";

type Props = {
  children: ReactElement;
};

export function PatientLayout({ children }: Props) {
  return (
    <ScrollbarProvider>
      <>
        <HeaderContainer>
          <Header />
        </HeaderContainer>
        <MainContainer>{children}</MainContainer>
      </>
    </ScrollbarProvider>
  );
}

import styled from "styled-components";
import { tablet } from "styles/breakpoints";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  svg {
    width: 250px;
    height: 92.8px;
  }

  @media (min-width: ${tablet}px) {
    svg {
      width: 387px;
      height: 151.39px;
    }
  }
`;

export function HeaderGraphic(): JSX.Element {
  return (
    <Wrapper>
      <svg viewBox="0 0 324 128" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M235.35 64.5877C235.35 43.4108 251.514 26.2439 271.454 26.2439C291.393 26.2439 307.558 43.4108 307.558 64.5877C307.558 85.7647 291.393 102.932 271.454 102.932C251.514 102.932 235.35 85.7647 235.35 64.5877Z"
          fill="#B6B8F1"
          fillOpacity="0.5"
        />
        <path
          d="M215.407 90.804C206.072 112.278 185.613 127.178 161.877 127.178C138.142 127.178 117.681 112.278 108.341 90.8048"
          stroke="#503769"
          strokeWidth="1.60438"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M227.457 39.2129C235.981 22.3637 252.737 10.9092 272.007 10.9092C291.278 10.9092 308.033 22.3637 316.557 39.2129"
          stroke="#503769"
          strokeWidth="1.60438"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M2 64.5879C2 94.2356 24.6302 118.27 52.5455 118.27C80.4613 118.27 103.092 94.2356 103.092 64.5879C103.092 64.5879 103.089 62.6734 103.131 61.7286C104.607 28.4905 130.35 1.99724 161.998 1.99724C193.422 1.99724 219.106 28.1124 220.843 61.021C220.868 61.4914 220.908 64.5879 220.908 64.5879C220.908 94.2356 243.538 118.27 271.454 118.27C299.369 118.27 322 94.2356 322 64.5879"
          stroke="#503769"
          strokeWidth="2.13918"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M7.99609 39.2129C16.5202 22.3637 33.2755 10.9092 52.5462 10.9092C71.8169 10.9092 88.5721 22.3637 97.0957 39.2129"
          stroke="#503769"
          strokeWidth="1.60438"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M117.873 64.5895C117.873 38.7068 137.629 17.7248 162 17.7248C186.371 17.7248 206.127 38.7068 206.127 64.5895C206.127 90.4723 186.371 111.455 162 111.455C137.629 111.455 117.873 90.4723 117.873 64.5895Z"
          fill="#B6B6C1"
        />
        <path
          d="M16.4424 64.5877C16.4424 43.4108 32.6068 26.2439 52.5462 26.2439C72.4861 26.2439 88.651 43.4108 88.651 64.5877C88.651 85.7647 72.4861 102.932 52.5462 102.932C32.6068 102.932 16.4424 85.7647 16.4424 64.5877Z"
          fill="#B6B8F1"
          fillOpacity="0.5"
        />
        <path
          d="M287.746 67.0089V37.4794C287.746 36.1354 286.729 35.0451 285.475 35.0451H256.348L245.935 46.2022V84.8137C245.935 86.1585 246.952 87.248 248.207 87.248H266.359"
          fill="white"
        />
        <path
          d="M287.746 67.0089V37.4794C287.746 36.1354 286.729 35.0451 285.475 35.0451H256.348L245.935 46.2022V84.8137C245.935 86.1585 246.952 87.248 248.207 87.248H266.359"
          stroke="#503769"
          strokeWidth="1.60438"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M256.348 46.2031H245.935L256.348 35.046V46.2031Z"
          fill="#F8D19A"
        />
        <path
          d="M256.348 46.2031H245.935L256.348 35.046V46.2031Z"
          stroke="#503769"
          strokeWidth="1.60438"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M269.598 41.6973H260.554"
          stroke="#503769"
          strokeWidth="1.60438"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M269.598 49.4023H250.403"
          stroke="#503769"
          strokeWidth="1.60438"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M283.448 57.1113H250.403"
          stroke="#503769"
          strokeWidth="1.60438"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M280.674 64.8184H250.403"
          stroke="#503769"
          strokeWidth="1.60438"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M261.713 72.5254H250.403"
          stroke="#503769"
          strokeWidth="1.60438"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M260.789 80.2317L250.403 80.2324"
          stroke="#503769"
          strokeWidth="1.60438"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M274.444 47.5222C274.626 48.4756 275.414 49.1934 276.358 49.1934H281.495C282.574 49.1934 283.449 48.2564 283.449 47.1003V41.5961C283.449 40.4408 282.574 39.5031 281.495 39.5031H276.358C275.279 39.5031 274.404 40.4408 274.404 41.5961V44.1541"
          fill="#ED58AB"
        />
        <path
          d="M274.444 47.5222C274.626 48.4756 275.414 49.1934 276.358 49.1934H281.495C282.574 49.1934 283.449 48.2564 283.449 47.1003V41.5961C283.449 40.4408 282.574 39.5031 281.495 39.5031H276.358C275.279 39.5031 274.404 40.4408 274.404 41.5961V44.1541"
          stroke="#503769"
          strokeWidth="1.60438"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M283.067 70.8375L285.577 67.838C286.018 67.3105 286.649 67.0083 287.312 67.0083H293.686C294.973 67.0083 296.016 68.1261 296.016 69.5045V71.668V88.9257C296.016 90.3041 294.973 91.4219 293.686 91.4219H268.183C266.896 91.4219 265.854 90.3041 265.854 88.9257V74.1641C265.854 72.7849 266.896 71.668 268.183 71.668H281.332C281.994 71.668 282.625 71.3658 283.067 70.8375Z"
          fill="white"
        />
        <path
          d="M283.067 70.8375L285.577 67.838C286.018 67.3105 286.649 67.0083 287.312 67.0083H293.686C294.973 67.0083 296.016 68.1261 296.016 69.5045V71.668V88.9257C296.016 90.3041 294.973 91.4219 293.686 91.4219H268.183C266.896 91.4219 265.854 90.3041 265.854 88.9257V74.1641C265.854 72.7849 266.896 71.668 268.183 71.668H281.332C281.994 71.668 282.625 71.3658 283.067 70.8375Z"
          stroke="#503769"
          strokeWidth="1.60438"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M277.444 84.4232C279.147 86.7182 281.191 88.2656 281.191 88.2656C281.191 88.2656 283.331 86.7776 285.056 84.4232L287.148 79.4919C287.179 79.2188 287.194 78.9417 287.194 78.6615C287.194 76.8252 285.805 75.3372 284.092 75.3372C282.764 75.3372 281.634 76.2318 281.191 77.4881C280.748 76.2318 279.619 75.3372 278.291 75.3372C276.578 75.3372 275.188 76.8252 275.188 78.6615C275.188 78.9401 275.206 79.2172 275.241 79.4919L277.444 84.4232Z"
          fill="white"
        />
        <path
          d="M275.241 79.4922C275.206 79.2174 275.188 78.9404 275.188 78.6617C275.188 76.8254 276.578 75.3374 278.291 75.3374C279.619 75.3374 280.748 76.2321 281.191 77.4884C281.634 76.2321 282.764 75.3374 284.092 75.3374C285.805 75.3374 287.194 76.8254 287.194 78.6617C287.194 78.9419 287.179 79.219 287.148 79.4922"
          stroke="#503769"
          strokeWidth="1.60438"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M285.056 84.4232C283.332 86.7776 281.192 88.2656 281.192 88.2656C281.192 88.2656 279.148 86.7182 277.444 84.4232"
          stroke="#503769"
          strokeWidth="1.60438"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M292.59 82.071H285.207L283.985 78.8915L282.34 83.7773L280.707 80.2981L279.481 82.071H269.537"
          stroke="#503769"
          strokeWidth="1.60438"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M48.7219 49.6958L44.2215 44.3646C43.4289 43.4253 42.2978 42.8891 41.1104 42.8891H29.6791C27.3716 42.8891 25.501 44.8757 25.501 47.3264V51.1712V81.8498C25.501 84.3005 27.3716 86.2871 29.6791 86.2871H75.4128C77.7204 86.2871 79.5909 84.3005 79.5909 81.8498V55.6086C79.5909 53.1586 77.7204 51.1712 75.4128 51.1712H51.8329C50.6456 51.1712 49.5144 50.635 48.7219 49.6958Z"
          fill="#F8D19A"
        />
        <path
          d="M48.7219 49.6958L44.2215 44.3646C43.4289 43.4253 42.2978 42.8891 41.1104 42.8891H29.6791C27.3716 42.8891 25.501 44.8757 25.501 47.3264V51.1712V81.8498C25.501 84.3005 27.3716 86.2871 29.6791 86.2871H75.4128C77.7204 86.2871 79.5909 84.3005 79.5909 81.8498V55.6086C79.5909 53.1586 77.7204 51.1712 75.4128 51.1712H51.8329C50.6456 51.1712 49.5144 50.635 48.7219 49.6958Z"
          stroke="#503769"
          strokeWidth="1.60438"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M45.8242 73.8456C48.8779 77.9245 52.5447 80.6758 52.5447 80.6758C52.5447 80.6758 56.3823 78.0309 59.4748 73.8456L63.2269 65.0798C63.2815 64.5945 63.3102 64.1014 63.3102 63.6035C63.3102 60.3395 60.8191 57.6939 57.7458 57.6939C55.3652 57.6939 53.3392 59.2844 52.5447 61.5175C51.7502 59.2844 49.7245 57.6939 47.3436 57.6939C44.2705 57.6939 41.7793 60.3395 41.7793 63.6035C41.7793 64.099 41.8119 64.5913 41.8736 65.0798L45.8242 73.8456Z"
          fill="white"
        />
        <path
          d="M62.7567 65.0801C62.8186 64.5917 62.8511 64.0993 62.8511 63.6038C62.8511 60.3398 60.36 57.6942 57.2874 57.6942C54.9061 57.6942 52.88 59.2847 52.0856 61.5179C51.2913 59.2847 49.2654 57.6942 46.8846 57.6942C43.8115 57.6942 41.3203 60.3398 41.3203 63.6038C41.3203 64.1017 41.349 64.5948 41.4034 65.0801"
          stroke="#503769"
          strokeWidth="1.60438"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M45.1553 73.8456C48.2478 78.0309 52.0847 80.6758 52.0847 80.6758C52.0847 80.6758 55.752 77.9245 58.8055 73.8456"
          stroke="#503769"
          strokeWidth="1.60438"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M72.6517 69.6673H59.4113L57.2209 64.0159L54.2691 72.7012L51.343 66.516L49.1438 69.6673H31.3105"
          stroke="#503769"
          strokeWidth="1.60438"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M142.254 97.7539V28.7769C142.254 28.2509 142.656 27.8251 143.15 27.8251H177.513C178.142 27.8251 178.71 28.1249 179.088 28.5921L188.08 38.248C188.392 38.5924 188.582 39.0558 188.582 39.5645V96.8021C188.582 97.3273 188.181 97.7539 187.686 97.7539H172.789"
          fill="white"
        />
        <path
          d="M142.254 97.7539V28.7769C142.254 28.2509 142.656 27.8251 143.15 27.8251H177.513C178.142 27.8251 178.71 28.1249 179.088 28.5921L188.08 38.248C188.392 38.5924 188.582 39.0558 188.582 39.5645V96.8021C188.582 97.3273 188.181 97.7539 187.686 97.7539H172.789"
          stroke="#503769"
          strokeWidth="1.60438"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M179.602 37.6657H186.79C187.268 37.6657 187.701 37.8582 188.021 38.1901L188.079 38.248L179.088 28.5922L179.127 28.6329C179.424 29.0188 179.602 29.5072 179.602 30.0442V37.6657Z"
          fill="#ED58AB"
        />
        <path
          d="M179.602 37.6657H186.79C187.268 37.6657 187.701 37.8582 188.021 38.1901L188.079 38.248L179.088 28.5922L179.127 28.6329C179.424 29.0188 179.602 29.5072 179.602 30.0442V37.6657Z"
          stroke="#503769"
          strokeWidth="1.60438"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M135.419 38.5618L135.418 96.1133C135.418 97.0189 136.109 97.7539 136.963 97.7539H172.789C173.642 97.7539 174.334 97.0189 174.334 96.1133V72.5122C174.334 70.4654 175.099 68.5023 176.462 67.0542L180.448 61.5884C181.811 60.1411 182.576 58.1772 182.576 56.1304V43.1166C182.576 42.211 181.884 41.476 181.031 41.476H141.007"
          fill="#F8D19A"
        />
        <path
          d="M135.419 38.5618L135.418 96.1133C135.418 97.0189 136.109 97.7539 136.963 97.7539H172.789C173.642 97.7539 174.334 97.0189 174.334 96.1133V72.5122C174.334 70.4654 175.099 68.5023 176.462 67.0542L180.448 61.5884C181.811 60.1411 182.576 58.1772 182.576 56.1304V43.1166C182.576 42.211 181.884 41.476 181.031 41.476H141.007"
          stroke="#503769"
          strokeWidth="1.60438"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M138.249 35.455C136.654 35.455 135.369 36.8576 135.419 38.5624C135.468 40.2085 136.82 41.4766 138.371 41.4766H142.254V35.455H138.249Z"
          fill="#ED58AB"
        />
        <path
          d="M138.249 35.455C136.654 35.455 135.369 36.8576 135.419 38.5624C135.468 40.2085 136.82 41.4766 138.371 41.4766H142.254V35.455H138.249Z"
          stroke="#503769"
          strokeWidth="1.60438"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M161.479 73.484C165.406 77.5604 169.452 80.3321 169.452 80.3321L164.411 89.0469C164.411 89.0469 160.766 84.8043 156.617 79.4676"
          fill="#ED58AB"
        />
        <path
          d="M161.479 73.484C165.406 77.5604 169.452 80.3321 169.452 80.3321L164.411 89.0469C164.411 89.0469 160.766 84.8043 156.617 79.4676"
          stroke="#39286B"
          strokeWidth="1.05278"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M146.039 51.8076C147.564 49.8836 151.14 46.6789 156.494 46.5741H156.475C161.955 46.7533 165.189 49.5618 166.764 51.4715C166.902 51.6389 167.034 51.8114 167.161 51.9882C167.936 55.3953 166.733 57.0543 164.74 58.0149C163.558 55.7264 161.956 51.8389 156.427 51.8186L156.452 51.8317C151.546 51.8389 149.334 55.5243 148.476 58.0352C146.19 57.4111 144.998 55.7099 146.039 51.8076Z"
          fill="#ED58AB"
        />
        <path
          d="M146.039 51.8076C147.564 49.8836 151.14 46.6789 156.494 46.5741H156.475C161.955 46.7533 165.189 49.5618 166.764 51.4715C166.902 51.6389 167.034 51.8114 167.161 51.9882C167.936 55.3953 166.733 57.0543 164.74 58.0149C163.558 55.7264 161.956 51.8389 156.427 51.8186L156.452 51.8317C151.546 51.8389 149.334 55.5243 148.476 58.0352C146.19 57.4111 144.998 55.7099 146.039 51.8076Z"
          stroke="#39286B"
          strokeWidth="1.05278"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M164.74 58.0149C163.558 55.7268 161.956 51.8389 156.427 51.8186L156.452 51.8317C151.546 51.8389 149.334 55.5243 148.477 58.0352"
          stroke="#39286B"
          strokeWidth="1.05278"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M151.498 73.4238C150.118 71.7636 147.767 67.9679 146.351 65.8303C145.706 64.8579 144.933 63.138 144.449 61.5256C143.801 59.3698 143.763 57.0467 144.402 54.8884C144.494 54.5776 144.592 54.2918 144.698 54.0534L144.709 54.0263C145.061 53.2277 145.505 52.4815 146.039 51.8072L145.764 52.2232C147.223 58.085 154.583 66.0256 156.589 68.4727"
          fill="#ED58AB"
        />
        <path
          d="M151.498 73.4238C150.118 71.7636 147.767 67.9679 146.351 65.8303C145.706 64.8579 144.933 63.138 144.449 61.5256C143.801 59.3698 143.763 57.0467 144.402 54.8884C144.494 54.5776 144.592 54.2918 144.698 54.0534L144.709 54.0263C145.061 53.2277 145.505 52.4815 146.039 51.8072L145.764 52.2232C147.223 58.085 154.583 66.0256 156.589 68.4727"
          stroke="#39286B"
          strokeWidth="1.05278"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M167.161 51.9883C167.034 51.8116 166.902 51.6391 166.763 51.4716C165.188 49.562 161.955 46.7534 156.474 46.5742H156.493C151.139 46.679 147.564 49.8837 146.039 51.8078"
          stroke="#39286B"
          strokeWidth="1.05278"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M167.37 52.329C165.912 58.1912 158.747 65.8968 156.74 68.3438L151.584 73.6057L151.521 73.681C147.594 77.757 143.74 80.3321 143.74 80.3321L148.781 89.0469C148.781 89.0469 152.333 84.9848 156.482 79.6481L156.474 79.6346L161.851 73.0439L161.79 73.0324C163.171 71.3722 165.284 68.2635 166.627 66.0731C167.284 64.9997 168.088 63.1377 168.572 61.5253C169.219 59.3695 169.258 57.0463 168.619 54.8881C168.527 54.5773 168.428 54.2915 168.323 54.0531L168.311 54.026C167.995 53.306 167.613 52.6186 167.161 51.9874"
          fill="#ED58AB"
        />
        <path
          d="M167.37 52.329C165.912 58.1912 158.747 65.8968 156.74 68.3438L151.584 73.6057L151.521 73.681C147.594 77.757 143.74 80.3321 143.74 80.3321L148.781 89.0469C148.781 89.0469 152.333 84.9848 156.482 79.6481L156.474 79.6346L161.851 73.0439L161.79 73.0324C163.171 71.3722 165.284 68.2635 166.627 66.0731C167.284 64.9997 168.088 63.1377 168.572 61.5253C169.219 59.3695 169.258 57.0463 168.619 54.8881C168.527 54.5773 168.428 54.2915 168.323 54.0531L168.311 54.026C167.995 53.306 167.613 52.6186 167.161 51.9874"
          stroke="#39286B"
          strokeWidth="1.05278"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Wrapper>
  );
}

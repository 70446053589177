import React, {
  useRef,
  useMemo,
  useState,
  useCallback,
  ReactElement,
  Children,
  CSSProperties,
} from "react";
import { useTheme } from "@material-ui/styles";
import type { StyledComponent } from "styled-components";
import { useSelect, useMultipleSelection } from "downshift";
import { get, useFormContext, FieldValues } from "react-hook-form";
import useMergedRef from "@react-hook/merged-ref";
import { ComponentThemeProvider } from "storybook/context/component-theme";
import { Option, OptionComponentProps, OptionCheckboxDefault } from "./options";
import { ThemeType } from "storybook/utils/theme";
import { FormFieldType } from "storybook/utils/form";
import {
  StyledShinkLabel,
  StyledSelectBorder,
  FontWrapperDefault,
  StyledItemDefault,
  StyledArrowDefault,
  StyledPopperDefault,
  StyledErrorTextDefault,
  StyledPlaceholderDefault,
  StyledInsideLabelDefault,
  StyledOutsideLabelDefault,
  StyledSingleSelectDefault,
  StyledMultiSelectDefault,
  StyledSelectedItemDefault,
  StyledSelectWrapperDefault,
  StyledSelectedContainerDefault,
  StyledSingleSelectWithInsideLabel,
} from "./theme/default";
import {
  StyledItemLegacy,
  FontWrapperLegacy,
  StyledArrowLegacy,
  StyledLabelLegacy,
  StyledPopperLegacy,
  StyledNativeSelect,
  StyledErrorTextLegacy,
  StyledPlaceholderLegacy,
  StyledMultiSelectLegacy,
  StyledSingleSelectLegacy,
  StyledSelectedItemLegacy,
  StyledSelectWrapperLegacy,
  StyledSelectedContainerLegacy,
} from "./theme/legacy";
import {
  FontWrapperFuture,
  StyledItemFuture,
  StyledArrowFuture,
  StyledPopperFuture,
  StyledErrorTextFuture,
  StyledPlaceholderFuture,
  StyledLabelFuture,
  StyledSingleSelectFuture,
  StyledMultiSelectFuture,
  StyledSelectedItemFuture,
  StyledSelectWrapperFuture,
  StyledSelectedContainerFuture,
} from "./theme/future";

export interface SelectThemeType extends ThemeType {
  name: Extract<ThemeType["name"], "default" | "legacy" | "future">;
}

export type StyledComponentMap = Record<
  SelectThemeType["name"],
  StyledComponent<any, any>
>;

export type SelectItemType = {
  value: string | number | null | boolean;
  text: string; // TODO - is there any benefit to locking this to a string?
};

export type SelectedComponentProps = {
  dirty: boolean;
  invert?: boolean;
  focused: boolean;
  multiple: boolean;
  placeholder?: string;
  alignText?: "left" | "right";
  selectedItems: SelectItemType[];
  labelPlacement: LabelPlacementType;
};

type LabelPlacementType = "inside" | "outside";

interface BaseSelectType<T> extends FormFieldType<T> {
  theme: SelectThemeType["name"];
  label: string;
  disabled?: boolean;
  inner?: boolean;
  error?: boolean;
  placeholder?: string;
  hideLabel?: boolean;
  hideErrorText?: boolean;
  alignText?: "left" | "right";
  component?: React.FC<OptionComponentProps>;
  children: ReactElement | Array<ReactElement>;
  onChange?: (selectedValue: SelectItemType["value"]) => void;
  selectedComponent?: React.FC<SelectedComponentProps>;
  hideDirty?: boolean;
  className?: string;
  labelPosition?: "left" | "top";
  maxListHeight?: CSSProperties["maxHeight"];
}

export interface DefaultSelectType<T> extends BaseSelectType<T> {
  theme: "default";
  multiple?: boolean;
  labelPlacement?: LabelPlacementType;
  // Forcing these prop values as they do not apply in the default select.
  // Omitting them causes TS to complain when we destructure the Select props.
  native?: false;
  invert?: false;
  fitContent?: false;
  // used to prevent jittering while hovering across select options
  disablePortal?: boolean;
}

export interface LegacySelectType<T> extends BaseSelectType<T> {
  theme: "legacy";
  native?: boolean;
  invert?: boolean;
  // Forcing these prop values as they do not apply in the legacy select.
  // Omitting them causes TS to complain when we destructure the Select props.
  multiple?: false;
  placeholder?: undefined;
  labelPlacement?: "outside";
  fitContent?: boolean;
  disablePortal?: boolean;
}

export interface FutureSelectType<T> extends BaseSelectType<T> {
  theme: "future";
  multiple?: boolean;
  labelPlacement?: LabelPlacementType;
  // Forcing these prop values as they do not apply in the default select.
  // Omitting them causes TS to complain when we destructure the Select props.
  native?: false;
  invert?: false;
  fitContent?: false;
  disablePortal?: boolean;
}

export type SelectType<T> =
  | DefaultSelectType<T>
  | LegacySelectType<T>
  | FutureSelectType<T>;

const fontWrapperMap: StyledComponentMap = {
  default: FontWrapperDefault,
  legacy: FontWrapperLegacy,
  future: FontWrapperFuture,
};

const styledSelectWrapperMap: StyledComponentMap = {
  default: StyledSelectWrapperDefault,
  legacy: StyledSelectWrapperLegacy,
  future: StyledSelectWrapperFuture,
};

const styledSingleSelectMap: StyledComponentMap = {
  default: StyledSingleSelectDefault,
  legacy: StyledSingleSelectLegacy,
  future: StyledSingleSelectFuture,
};

const styledMultiSelectMap: StyledComponentMap = {
  default: StyledMultiSelectDefault,
  legacy: StyledMultiSelectLegacy,
  future: StyledMultiSelectFuture,
};

const styledSelectPopperMap: StyledComponentMap = {
  default: StyledPopperDefault,
  legacy: StyledPopperLegacy,
  future: StyledPopperFuture,
};

const styledArrowMap: StyledComponentMap = {
  default: StyledArrowDefault,
  legacy: StyledArrowLegacy,
  future: StyledArrowFuture,
};

type LabelMapType = {
  default: {
    inside: StyledComponent<any, any>;
    outside: StyledComponent<any, any>;
  };
  legacy: {
    inside: StyledComponent<any, any>;
    outside: StyledComponent<any, any>;
  };
  future: {
    inside: StyledComponent<any, any>;
    outside: StyledComponent<any, any>;
  };
};

const styledLabelMap: LabelMapType = {
  default: {
    inside: StyledInsideLabelDefault,
    outside: StyledOutsideLabelDefault,
  },
  legacy: {
    inside: StyledLabelLegacy,
    outside: StyledLabelLegacy,
  },
  future: {
    inside: StyledLabelFuture,
    outside: StyledLabelFuture,
  },
};

const styledItemMap: StyledComponentMap = {
  default: StyledItemDefault,
  legacy: StyledItemLegacy,
  future: StyledItemFuture,
};

const styledSelectedItemMap: StyledComponentMap = {
  default: StyledSelectedItemDefault,
  legacy: StyledSelectedItemLegacy,
  future: StyledSelectedItemFuture,
};

const styledSelectedContainerMap: StyledComponentMap = {
  default: StyledSelectedContainerDefault,
  legacy: StyledSelectedContainerLegacy,
  future: StyledSelectedContainerFuture,
};

const styledPlaceholderMap: StyledComponentMap = {
  default: StyledPlaceholderDefault,
  legacy: StyledPlaceholderLegacy,
  future: StyledPlaceholderFuture,
};

const styledErrorTextMap: StyledComponentMap = {
  default: StyledErrorTextDefault,
  legacy: StyledErrorTextLegacy,
  future: StyledErrorTextFuture,
};

const FallbackOption = ({ children }: OptionComponentProps) => <>{children}</>;

function DefaultSelectedComponent({
  dirty,
  multiple,
  alignText,
  placeholder,
  selectedItems,
  labelPlacement,
}: SelectedComponentProps) {
  const theme: SelectThemeType = useTheme();
  const StyledPlaceholder = styledPlaceholderMap[theme.name];
  const StyledSelectedItem = styledSelectedItemMap[theme.name];
  const StyledSelectedContainer = styledSelectedContainerMap[theme.name];

  const hasPlaceholder = Boolean(placeholder && placeholder.trim().length > 0);

  return (
    <StyledSelectedContainer
      data-testid="Select__SelectedItems"
      $multiple={multiple}
    >
      {/* Placeholder is only shown on initial render if there is
          no selection and placeholder text has beeen provided.
          After a selection is made, the placeholder is no longer shown. */}
      {hasPlaceholder && !dirty && selectedItems.length === 0 ? (
        <StyledPlaceholder data-testid="Select__Placeholder">
          {labelPlacement !== "inside" ? placeholder : ""}
        </StyledPlaceholder>
      ) : (
        selectedItems.map((item, index) => {
          return (
            <StyledSelectedItem
              key={index}
              $dirty={dirty}
              $alignText={alignText}
              $labelPlacement={labelPlacement}
              data-testid="Select__SelectedItem"
            >
              {item.text}
            </StyledSelectedItem>
          );
        })
      )}
    </StyledSelectedContainer>
  );
}

function Select<T>({
  name,
  label,
  theme,
  error,
  native,
  invert,
  onChange,
  disabled,
  children,
  component,
  className,
  hideLabel,
  fitContent,
  placeholder,
  inner = true,
  hideErrorText,
  maxListHeight,
  multiple = false,
  selectedComponent,
  alignText = "right",
  labelPosition = "left",
  labelPlacement = "outside",
  hideDirty = false,
  disablePortal = false,
  registerOptions,
}: SelectType<T>): JSX.Element {
  const StyledItem = styledItemMap[theme];
  const StyledArrow = styledArrowMap[theme];
  const FontWrapper = fontWrapperMap[theme];
  const StyledPopper = styledSelectPopperMap[theme];
  const StyledLabel = styledLabelMap[theme][labelPlacement];
  const StyledErrorText = styledErrorTextMap[theme];
  const StyledSingleSelect = styledSingleSelectMap[theme];
  const StyledMultiSelect = styledMultiSelectMap[theme];
  const StyledSelectWrapper = styledSelectWrapperMap[theme];
  const StyledSelect = multiple
    ? StyledMultiSelect
    : labelPlacement === "inside"
    ? StyledSingleSelectWithInsideLabel
    : StyledSingleSelect;

  const [focused, setFocused] = useState<boolean>(false);
  const { register, formState, setValue, getValues } = useFormContext();

  const fieldError = get(formState.errors, name);
  const hasError = error || Boolean(fieldError);

  const isDirty =
    !hideDirty &&
    get(formState.dirtyFields, name) &&
    !(
      getValues(name) === null &&
      get(formState.defaultValues, name) === getValues(name)
    ); // TODO - RHF is detecting a null -> null transition as dirty, this quickfixes it

  // Options extracted as items array for Downshift.
  const items = useMemo(
    () =>
      Children.toArray(children)
        // TODO: TS doesn't like the inferred type because the children are
        // strings and therefore don't have a "type" property, but they do
        // after being processed through Children.toArray().
        .map((child: any) => {
          return {
            value: child.props.value,
            text: child.props.children,
          };
        })
        .filter(Boolean) as SelectItemType[],
    [children]
  );

  const possibleValuesArray = items.map(({ value }) => value);

  let currentValueArray: SelectItemType["value"][] =
    (multiple ? getValues(name) : [getValues(name)]) || [];

  // Ensure that we only have valid possible values in currentValueArray.
  currentValueArray = currentValueArray.filter((value) =>
    possibleValuesArray.includes(value)
  );

  // The select item when the menu opens is the furthest selected item
  // in the list, not the most recently selected item. Fallback to 0.
  let defaultHighlightedIndex: number = 0;

  // For selected item display, we do want to persist user selection order
  // firstly filter items down to only the selected ones.
  const unsortedSelectedItems = items.filter(({ value }, index) => {
    const isSelected = currentValueArray.includes(value);
    if (isSelected) {
      // We can increment defaultHighlightedIndex as we detect items, whilst we're here.
      defaultHighlightedIndex = index;
    }
    return isSelected;
  });

  // We can now sort unsortedSelectedItems into user selected order by iterating
  // currentValueArray
  const selectedItems = currentValueArray.map((selectedValue) =>
    unsortedSelectedItems.find(({ value }) => value === selectedValue)
  ) as SelectItemType[];

  const { getDropdownProps } = useMultipleSelection<SelectItemType>({
    selectedItems,
  });

  const {
    isOpen,
    getMenuProps,
    getItemProps,
    getLabelProps,
    highlightedIndex,
    getToggleButtonProps,
  } = useSelect<SelectItemType>({
    // selectedItem is intentionally set to null, selection state is managed by RHF
    // and not providing this value to Downshift causes it to ignore a second click
    // on the last selected option.
    selectedItem: null,
    items,
    defaultHighlightedIndex,
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useSelect.stateChangeTypes.ItemClick:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
          return {
            ...changes,
            isOpen: multiple,
            highlightedIndex: state.highlightedIndex,
          };
      }

      return changes;
    },
    onStateChange: ({ type, selectedItem: newSelectedItem }) => {
      switch (type) {
        case useSelect.stateChangeTypes.ItemClick:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownEnter:
        case useSelect.stateChangeTypes.ToggleButtonKeyDownSpaceButton:
          if (newSelectedItem) {
            let newValue: FieldValues[typeof name];
            if (multiple) {
              if (currentValueArray.includes(newSelectedItem.value)) {
                newValue = currentValueArray.filter(
                  (value) => value !== newSelectedItem.value
                );
              } else {
                newValue = [...currentValueArray, newSelectedItem.value];
              }
            } else {
              newValue = newSelectedItem.value;
            }

            // TODO: Existing Bug: When newValue is an empty string, the selectedItems array changes
            // to empty for single select. Shouldn't empty string be allowed as a selection?
            // This means the "Not Answered" option in our Select stories can never be selected.
            setValue(name, newValue, {
              shouldValidate: true,
              shouldTouch: true,
              shouldDirty: true,
            });
          }
      }
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (onChange) {
        onChange(selectedItem?.value as string);
      }
    },
  });

  const buttonRef: React.Ref<HTMLDivElement> = useRef(null);
  const { ref: downshiftBtnRef, ...buttonProps } = getToggleButtonProps(
    getDropdownProps({ preventKeyAction: isOpen, disabled })
  );
  const { ref: menuRef, ...menuProps } = getMenuProps(
    {},
    { suppressRefError: true }
  );
  const ref = useMergedRef(buttonRef, downshiftBtnRef as React.RefObject<any>);

  const Component = component || FallbackOption;

  // Inside label placement is a particular case so here we're not
  // allowing the selectedComponent prop to be used if it's set.
  const SelectedComponent =
    selectedComponent && labelPlacement === "outside"
      ? selectedComponent
      : DefaultSelectedComponent;

  const handleFocus = useCallback(() => {
    setFocused(!disabled);
  }, [disabled]);

  const handleBlur = useCallback(
    (e) => {
      setFocused(false);

      if (buttonProps.onBlur) {
        buttonProps.onBlur(e);
      }
    },
    [buttonProps]
  );

  const shrinkLabel =
    labelPlacement === "inside" && (focused || Boolean(selectedItems.length));

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLSelectElement>) => {
      const newValue: FieldValues[typeof name] = event.target.value;

      setValue(name, newValue, {
        shouldValidate: true,
        shouldTouch: true,
        shouldDirty: true,
      });

      if (onChange) {
        onChange(newValue);
      }
    },
    [name, onChange, setValue]
  );

  if (native) {
    const { id } = getToggleButtonProps();

    return (
      <ComponentThemeProvider theme={theme}>
        <FontWrapper data-testid="Select">
          <StyledSelectWrapper>
            <StyledLabel
              htmlFor={id}
              $error={error}
              $disabled={disabled}
              $hide={hideLabel}
              $native={true}
            >
              {label}
            </StyledLabel>
            <StyledNativeSelect
              $error={hasError}
              disabled={disabled}
              $disabled={disabled}
              $hideLabel={hideLabel}
              onChange={handleChange}
              id={id}
            >
              {items.map((item) => (
                <option key={`${item.value}`} value={`${item.value}`}>
                  {item.text}
                </option>
              ))}
            </StyledNativeSelect>
          </StyledSelectWrapper>
          {hasError && (
            <StyledErrorText $hide={hideErrorText}>
              {fieldError?.message}
            </StyledErrorText>
          )}
        </FontWrapper>
      </ComponentThemeProvider>
    );
  }

  return (
    <ComponentThemeProvider theme={theme}>
      <FontWrapper data-testid="Select" className={className}>
        <StyledSelectWrapper
          $labelPlacement={labelPlacement}
          $labelPosition={labelPosition}
        >
          {/* It's important that an input is registered, without it RHF reset does not work correctly */}
          <input type="hidden" {...register(name, registerOptions)} />
          <StyledLabel
            {...getLabelProps()}
            $shrink={shrinkLabel}
            $disabled={disabled}
            $hide={hideLabel}
            $invert={invert}
            $dirty={isDirty}
            $error={error}
          >
            {label}
          </StyledLabel>
          <StyledSelect
            {...buttonProps}
            ref={ref}
            $error={error}
            onFocus={handleFocus}
            onBlur={handleBlur}
            $invert={invert}
            $native={native}
            $focused={focused}
            $disabled={disabled}
            $shrink={shrinkLabel}
            $hideLabel={hideLabel}
            $fitContent={fitContent}
          >
            <SelectedComponent
              dirty={isDirty}
              invert={invert}
              focused={focused}
              multiple={multiple}
              alignText={alignText}
              placeholder={placeholder}
              selectedItems={selectedItems}
              labelPlacement={labelPlacement}
            />
            {theme === "default" && (
              <>
                <StyledArrow $labelPlacement={labelPlacement} />
                <StyledSelectBorder aria-hidden="true" $error={hasError}>
                  <StyledShinkLabel $focused={focused} $shrink={shrinkLabel}>
                    <span>{label}</span>
                  </StyledShinkLabel>
                </StyledSelectBorder>
              </>
            )}
            {theme === "future" && (
              <>
                <StyledArrow />
              </>
            )}
          </StyledSelect>
        </StyledSelectWrapper>
        <StyledPopper
          $maxHeight={maxListHeight}
          keepMounted={true} // To reduce ref errors outputting in console, described below at ref prop.
          open={Boolean(isOpen)}
          anchorEl={buttonRef.current}
          $width={buttonRef?.current?.clientWidth}
          placement={theme === "legacy" ? "top-end" : "top-start"}
          disablePortal={disablePortal}
          modifiers={{
            // This modifier is important for keeping the menu fixed while
            // multiple items are added to the select. However, it has the
            // knock-on affect is disabling flipping, so this needs more investigation.
            inner: {
              enabled: inner && theme === "default",
            },
            // This modifier keeps the open select options visible
            // when the user scrolls beyond the select input.
            preventOverflow: {
              enabled: true,
              boundariesElement: "viewport",
            },
          }}
          {...menuProps}
          // This ref is not being applied correctly, as per the error output by Downshift.
          // This seems to be because of Popper not rendering anything until there is content
          // for the portal. However, when this ref goes on a wrapper div, along with the
          // menuProps spread, select items click events are no longer fired (with portal disabled).
          ref={menuRef}
        >
          {items.map((item, index) => {
            const isSelected = currentValueArray.includes(item.value);

            return (
              <StyledItem
                $selected={isSelected}
                key={item.value}
                $focused={highlightedIndex === index}
                {...getItemProps({ item, index, isSelected })}
                // Downshift provides aria-selected but it is always false
                aria-selected={isSelected}
              >
                <Component selected={isSelected}>{item.text}</Component>
              </StyledItem>
            );
          })}
        </StyledPopper>
        {hasError && (
          <StyledErrorText $hide={hideErrorText}>
            {fieldError?.message}
          </StyledErrorText>
        )}
      </FontWrapper>
    </ComponentThemeProvider>
  );
}

const SelectComponent = Object.assign(Select, {
  Option,
});

export { SelectComponent as Select };

export type { OptionComponentProps };

export { OptionCheckboxDefault };

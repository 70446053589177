import React, { useMemo, useCallback } from "react";
import CalendarTodayOutlinedIcon from "@material-ui/icons/CalendarTodayOutlined";
import { z } from "zod";
import { Button } from "storybook/components/button";
import { Modal } from "storybook/components/modal";
import { Typography } from "storybook/components/typography";
import { useForm } from "storybook/utils/form";
import {
  ErrorType,
  ModalType,
  usePatientInterest,
} from "context/patient-interest";
import { FormSection } from ".";
import { ErrorBanner, InputsContainer, ModalBodyWrapper } from "../styles";
import { FormRow } from "./styles";

const dateFormat = "M/dd/yyyy h:mm aa";

const calendarSchema = z
  .object({
    date1: z.date().nullable().optional(),
    date2: z.date().nullable().optional(),
    date3: z.date().nullable().optional(),
  })
  .refine(
    (data) => {
      // At least one date must be selected
      if (!data.date1 && !data.date2 && !data.date3) {
        return false;
      }

      return true;
    },
    {
      message: "At least one date must be selected",
    }
  );

type Calendar = z.infer<typeof calendarSchema>;

type Props = {
  dates: Date[];
  onClose: () => void;
  isSubmitting: boolean;
  onContinue: (data: unknown, formSection: FormSection) => void;
};

export function CalendarForm({
  dates,
  onClose,
  onContinue,
  isSubmitting,
}: Props) {
  const initialValues = useMemo(() => {
    if (dates?.length) {
      const [date1, date2, date3] = dates;

      return {
        date1: date1 || null,
        date2: date2 || null,
        date3: date3 || null,
      };
    }

    return {
      date1: null,
      date2: null,
      date3: null,
    };
  }, [dates]);

  const { errorType, setModalType } = usePatientInterest();

  const { Form, DateInput, getValues } = useForm<Calendar>({
    schema: calendarSchema,
    values: initialValues,
  });

  const handleContinue = useCallback(
    (formData) => {
      setModalType(ModalType.CONFIRMATION);

      const dates = [formData.date1, formData.date2, formData.date3].filter(
        Boolean
      );

      onContinue({ dates }, FormSection.CALENDAR);
    },
    [onContinue, setModalType]
  );

  const date1 = getValues("date1");
  const date2 = getValues("date2");
  const date3 = getValues("date3");

  const date1Selected = date1 && date1.getHours() !== 0;
  const date2Selected = date2 && date2.getHours() !== 0;

  const hasSelectedDateTime = useMemo(() => {
    if (!date1 && !date2 && !date3) {
      return false;
    }

    const invalidDates = [date1, date2, date3].filter((date) => {
      // Time range of 9am to 5pm (technically to 4pm, but intervals are one hour)
      return date && (date.getHours() < 9 || date.getHours() >= 17);
    });

    return invalidDates.length === 0;
  }, [date1, date2, date3]);

  const filterTimeRange = useCallback((time: Date) => {
    // Time range of 9am to 5pm (technically to 4pm, but intervals are one hour)
    const hours = time.getHours();

    return hours >= 9 && hours < 17;
  }, []);

  const today = new Date();

  return (
    <Form onSubmit={handleContinue}>
      <Modal.Body>
        <ModalBodyWrapper>
          {errorType === ErrorType.GENERAL && (
            <ErrorBanner role="alert">
              Something went wrong, please try again
            </ErrorBanner>
          )}
          <div>
            <Typography theme="default" variant="body1" color="black">
              Please select up to three dates.
            </Typography>
            <InputsContainer data-id="InputsContainer">
              <FormRow>
                <DateInput
                  name="date1"
                  theme="default"
                  label="Select Date And Time You Are Available"
                  labelPosition="top"
                  minDate={today}
                  hideDirty={true}
                  alignText="left"
                  timeIntervals={60}
                  showTimeSelect={true}
                  dateFormat={dateFormat}
                  disabled={isSubmitting}
                  placeholder="Date and Time"
                  filterTime={filterTimeRange}
                  suffix={<CalendarTodayOutlinedIcon />}
                />
              </FormRow>
              <FormRow>
                <DateInput
                  name="date2"
                  theme="default"
                  label="Select Date And Time You Are Available"
                  labelPosition="top"
                  minDate={today}
                  hideDirty={true}
                  alignText="left"
                  timeIntervals={60}
                  showTimeSelect={true}
                  dateFormat={dateFormat}
                  placeholder="Date and Time"
                  filterTime={filterTimeRange}
                  disabled={!date1Selected || isSubmitting}
                  suffix={<CalendarTodayOutlinedIcon />}
                />
              </FormRow>
              <FormRow>
                <DateInput
                  name="date3"
                  theme="default"
                  label="Select Date And Time You Are Available"
                  labelPosition="top"
                  minDate={today}
                  hideDirty={true}
                  alignText="left"
                  timeIntervals={60}
                  showTimeSelect={true}
                  dateFormat={dateFormat}
                  placeholder="Date and Time"
                  filterTime={filterTimeRange}
                  disabled={!date2Selected || isSubmitting}
                  suffix={<CalendarTodayOutlinedIcon />}
                />
              </FormRow>
            </InputsContainer>
          </div>
        </ModalBodyWrapper>
      </Modal.Body>
      <Modal.Actions alignButtons="right">
        <Button
          color="default"
          variant="text"
          theme="default"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          type="submit"
          color="default"
          variant="contained"
          theme="default"
          disabled={!hasSelectedDateTime}
        >
          Done
        </Button>
      </Modal.Actions>
    </Form>
  );
}

import React, { ReactElement, ReactNode, useEffect, useMemo } from "react";
import {
  FieldPath,
  FieldValues,
  FormProvider,
  RegisterOptions,
  SubmitHandler,
  useForm as rhfUseForm,
  UseFormProps as RHFUseFormProp,
  useFormContext,
  useWatch,
  DeepPartial,
} from "react-hook-form";
import { zodResolver } from "@hookform/resolvers/zod";
import { ZodType } from "zod";

import { Input, InputType } from "storybook/components/input";
import { InputGroup, InputGroupType } from "storybook/components/input-group";
import { DateInput, DateInputType } from "storybook/components/date-input";
import { Checkbox, CheckboxType } from "storybook/components/checkbox";
import { Radio, RadioType } from "storybook/components/radio";
import {
  SelectType,
  Select as SelectComponent,
} from "storybook/components/select";
import { Textarea, TextareaType } from "storybook/components/textarea";
import {
  DateOfBirth,
  DateOfBirthType,
} from "storybook/components/date-of-birth";
import {
  MultiSelectAutocomplete,
  MultiSelectAutocompleteType,
} from "storybook/components/multi-select-autocomplete";

declare global {
  interface Window {
    debugForm?: boolean;
  }
}

interface FormProps<T> {
  // @ts-ignore - Type 'T' does not satisfy the constraint 'FieldValues'.ts(2344)
  onSubmit: SubmitHandler<T>;
  children: ReactNode;
  methods: any;
  id?: string;
  debug?: boolean;
  disableAutocomplete?: boolean;
}

interface UseFormProps<T> {
  schema: ZodType;
  values?: DeepPartial<T>;
  shouldFocusError?: RHFUseFormProp["shouldFocusError"];
  isSubmitting?: boolean;
  mode?: "onChange" | "onBlur" | "onSubmit" | "onTouched" | "all";
}

interface FormProviderProps {
  children: ReactNode;
}

function Debug<T extends FieldValues>() {
  const methods = useFormContext<T>();

  const { formState } = methods;
  const watcher = useWatch();
  return (
    <>
      Errors:
      <pre>
        {JSON.stringify(
          formState.errors,
          (key, value) => (key === "ref" ? undefined : value),
          2
        )}
      </pre>
      State:
      <pre>{JSON.stringify(watcher, null, 2)}</pre>
    </>
  );
}

function Form<T extends FieldValues>({
  id,
  onSubmit,
  children,
  methods,
  debug = false,
  disableAutocomplete,
}: FormProps<T>): ReactElement {
  return (
    // @ts-ignore - it's just too complex for ts to handle, doesn't appear to impact consumers negatively
    <FormProvider {...methods}>
      <form
        id={id}
        data-testid="CIQ__Form"
        noValidate={true} // this is intentional, we use Zod and custom warning UI for validation, not browser implementation
        onSubmit={methods.handleSubmit(onSubmit)}
        autoComplete={disableAutocomplete ? "off" : undefined}
      >
        {children}
      </form>
      {(debug || window.debugForm) && <Debug<T> />}
    </FormProvider>
  );
}

export function useForm<FormType extends FieldValues>({
  schema,
  values,
  shouldFocusError = false,
  isSubmitting = false,
  mode,
}: UseFormProps<FormType>) {
  const methods = rhfUseForm<FormType>({
    resolver: zodResolver(schema),
    shouldFocusError,
    // @ts-ignore - Type 'DeepPartial<FormType> | undefined' is not assignable to type
    defaultValues: values,
    mode,
  });

  useEffect(() => {
    methods.reset(
      // @ts-ignore - incompatible types
      values,
      // TODO - this logic means that dirty gets reset during submit, perhaps an enum would be better.
      //  I think they should remain dirty until submit succeeds
      //  Edit - actually in rescreen mode they *do* remain dirty until persisted, so for that use case this is a regression
      !isSubmitting
        ? {
            keepDirtyValues: true, // Don't reset edited fields
            keepDirty: true, // Keep form dirty state in place - useful if we have a button that only shows when a form is dirty
            keepErrors: true, // Keep any validation errors in place
          }
        : undefined
    );
  }, [values, methods, isSubmitting]);

  return useMemo(() => {
    return {
      ...methods,
      Form: function ({
        children,
        onSubmit,
        id,
        disableAutocomplete,
      }: Omit<FormProps<FormType>, "methods">) {
        return (
          <Form<FormType>
            id={id}
            onSubmit={onSubmit}
            methods={methods}
            disableAutocomplete={disableAutocomplete}
          >
            {children}
          </Form>
        );
      },
      FormProvider: ({ children }: FormProviderProps) => {
        /* @ts-ignore 'TFieldValues' is assignable to the constraint of type 'FormType', but 'FormType' could be instantiated with a different subtype of constraint 'FieldValues'. */
        return <FormProvider<FormType> {...methods}>{children}</FormProvider>;
      },
      Input: (props: InputType<FormType>) => <Input<FormType> {...props} />,
      DateInput: (props: DateInputType<FormType>) => (
        <DateInput<FormType> {...props} />
      ),
      InputGroup: (props: InputGroupType) => <InputGroup {...props} />,
      Checkbox: (props: CheckboxType<FormType>) => (
        <Checkbox<FormType> {...props} />
      ),
      Radio: (props: RadioType<FormType>) => <Radio<FormType> {...props} />,
      Select: Object.assign(
        (props: SelectType<FormType>) => (
          <SelectComponent<FormType> {...props} />
        ),
        {
          Option: SelectComponent.Option,
          displayName: "Select",
        }
      ),
      Textarea: (props: TextareaType<FormType>) => (
        <Textarea<FormType> {...props} />
      ),
      DateOfBirth: (props: DateOfBirthType<FormType>) => (
        <DateOfBirth<FormType> {...props} />
      ),
      MultiSelectAutocomplete: (
        props: MultiSelectAutocompleteType<FormType>
      ) => <MultiSelectAutocomplete<FormType> {...props} />,
    };
  }, [methods]);
}

export interface FormFieldType<T> {
  // @ts-ignore - Type 'T' does not satisfy the constraint 'FieldValues'
  name: FieldPath<T>;
  registerOptions?: RegisterOptions<FieldValues, string>;
}

import { StylesProvider } from "@material-ui/core/styles";
import { GlobalStyle } from "./styles/global-styles";
import { Router } from "./router";

function App() {
  return (
    <StylesProvider injectFirst>
      <GlobalStyle />
      <Router />
    </StylesProvider>
  );
}

export default App;

import styled from "styled-components";

export const FinishedContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 50px;
`;

export const Content = styled.div`
  max-width: 600px;
  display: flex;
  flex-direction: column;
`;

export const CopyContainer = styled.div`
  margin-top: 20px;
`;

export const GraphicContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const ButtonContainer = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;

  button {
    text-transform: none;
  }
`;

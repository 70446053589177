import { useContext } from "react";
import { KioskAuthContext } from "context/kiosk-auth";
import { PatientAuthContext } from "context/patient-auth";

export function useAuthenticated() {
  const kioskContext = useContext(KioskAuthContext);
  const patientContext = useContext(PatientAuthContext);

  if (kioskContext) {
    return { isAuthenticated: kioskContext.isAuthenticated };
  } else if (patientContext) {
    return { isAuthenticated: patientContext.isAuthenticated };
  } else {
    throw new Error(
      "Must be used within either a KioskAuthProvider or a PatientAuthProvider"
    );
  }
}

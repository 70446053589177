import styled from "styled-components";
import {
  StyledModal,
  StyledModalHead,
  StyledModalBody,
  StyledModalActions,
  StyledCloseButton,
} from "../styles";

export const StyledModalFuture: typeof StyledModal = styled(StyledModal)``;

export const StyledModalHeadFuture = styled(StyledModalHead)``;

export const StyledModalBodyFuture = styled(StyledModalBody)``;

export const StyledModalActionsFuture = styled(StyledModalActions)``;

export const StyledCloseButtonFuture = styled(StyledCloseButton)``;

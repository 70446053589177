export function MedicalHistoryIcon() {
  return (
    <svg
      width="21"
      height="20"
      fill="none"
      viewBox="0 0 21 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3561 4H14.2849V2C14.2849 0.9 13.3689 0 12.2493 0H8.17808C7.05849 0 6.14247 0.9 6.14247 2V4H2.07125C0.951668 4 0.0356445 4.9 0.0356445 6V18C0.0356445 19.1 0.951668 20 2.07125 20H18.3561C19.4757 20 20.3917 19.1 20.3917 18V6C20.3917 4.9 19.4757 4 18.3561 4ZM8.17808 2H12.2493V4H8.17808V2ZM18.3561 18H2.07125V6H18.3561V18Z"
        fill="#323232"
      />
      <path
        d="M11.2316 8H9.19599V11H6.14258V13H9.19599V16H11.2316V13H14.285V11H11.2316V8Z"
        fill="#323232"
      />
    </svg>
  );
}

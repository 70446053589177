import styled from "styled-components";
import { defaultTheme } from "storybook/themes/default";
import { mobile, tablet } from "styles/breakpoints";

const { colors } = defaultTheme;

export const SentConfirmationText = styled.div`
  margin-top: 20px;

  && {
    p {
      margin-bottom: 16px;
      line-height: 24px;

      strong {
        word-break: break-word;
      }
    }
  }
`;

export const ResendContainer = styled.div`
  margin-top: 40px;

  button {
    text-transform: none;
  }
`;

export const PasswordlessLink = styled.div`
  margin: 22px 0 0 0;

  a {
    font-weight: 700;
    color: ${colors.ebonyClay};
  }
`;

export const StyledHeading = styled.div`
  margin-top: 18px;
  position: relative;

  && {
    h1 {
      font-size: 28px;
      font-weight: 700;

      @media (min-width: ${mobile}px) {
        font-size: 36px;
        text-align: center;
      }

      @media (min-width: ${tablet}px) {
        font-size: 36px;
      }
    }
  }

  @media (min-width: ${tablet}px) {
    margin-top: 0;
  }
`;

export const BannerContainer = styled.div`
  margin-top: 30px;
`;

export const BackContainer = styled.div`
  margin-top: 18px;

  svg {
    margin-right: 8px;
  }

  a {
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 600;
    text-decoration: none;
    letter-spacing: 0.5px;
    color: ${colors.ebonyClay};
  }

  @media (min-width: ${tablet}px) {
    margin-top: 100px;
  }
`;

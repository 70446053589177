import styled from "styled-components";
import { tablet } from "styles/breakpoints";

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  svg {
    width: 87.34px;
    height: 97px;
  }

  @media (min-width: ${tablet}px) {
    svg {
      width: 150px;
      height: 166.59px;
    }
  }
`;

export function EmailGraphic(): JSX.Element {
  return (
    <Wrapper>
      <svg
        width="152"
        height="169"
        fill="none"
        viewBox="0 0 152 169"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1 34.4092C4.26568 30.0471 7.97609 25.9857 12.0972 22.2917C27.4123 8.56117 47.1332 0.999889 67.6264 0.999889C70.4987 0.999889 73.4107 1.15103 76.2822 1.44772C82.1787 2.05781 87.8691 3.27319 93.2987 5.02391"
          stroke="#39286B"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="14.91 14.91"
        />
        <path
          d="M75.3077 167.245C72.818 167.471 70.3093 167.586 67.7894 167.586C64.9171 167.586 62.0051 167.435 59.1344 167.138C39.0914 165.064 20.8677 156.033 7.18677 141.52"
          stroke="#39286B"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="14.91 14.91"
        />
        <path
          d="M149.827 70.3348C151.066 77.6121 151.349 85.1734 150.553 92.8667C148.752 110.269 141.584 126.497 130.118 139.452"
          stroke="#39286B"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeDasharray="14.91 14.91"
        />
        <path
          d="M6.8125 77.9912C10.293 44.3592 40.3787 19.9167 74.01 23.3975C107.642 26.8783 132.084 56.9635 128.604 90.5947C125.123 124.227 95.0377 148.669 61.4057 145.188C27.7745 141.708 3.33189 111.622 6.8125 77.9912Z"
          fill="#E2F4DA"
        />
        <path
          d="M30.4709 71.2245L62.0201 45.8189C63.4951 44.6377 65.595 44.6377 67.07 45.8189L99.1442 71.6495L99.8442 72.637C100.05 73.0807 100.169 73.5682 100.169 74.087V115.249C100.169 117.148 98.6255 118.68 96.7193 118.68H32.3645C30.4646 118.68 28.9209 117.148 28.9209 115.249V74.087C28.9209 73.5682 29.0334 73.0807 29.2396 72.637L30.4709 71.2245Z"
          fill="#F8D19A"
        />
        <path
          d="M30.4709 71.2246L62.0201 45.8189C63.4951 44.6377 65.595 44.6377 67.07 45.8189L99.1442 71.6495L99.8442 72.637C100.05 73.0807 100.169 73.5682 100.169 74.087V115.249C100.169 117.148 98.6255 118.68 96.7193 118.68H32.3645C30.4646 118.68 28.9209 117.148 28.9209 115.249V74.087C28.9209 73.5682 29.0334 73.0807 29.2396 72.637L30.4709 71.2246Z"
          stroke="#000547"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M29.2458 72.6366L62.0201 98.9297C63.4951 100.117 65.595 100.117 67.07 98.9297L99.8443 72.6366"
          fill="#F8D19A"
        />
        <path
          d="M29.2458 72.6366L62.0201 98.9297C63.4951 100.117 65.595 100.117 67.07 98.9297L99.8443 72.6366"
          stroke="#000547"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path d="M29.928 117.68L54.6836 93.049L29.928 117.68Z" fill="#F8D19A" />
        <path
          d="M29.928 117.68L54.6836 93.049"
          stroke="#000547"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M99.1571 117.68L74.4077 93.049L99.1571 117.68Z"
          fill="#F8D19A"
        />
        <path
          d="M99.1571 117.68L74.4077 93.049"
          stroke="#000547"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M38.3079 79.9114L62.0198 98.9297C63.4948 100.117 65.5947 100.117 67.0697 98.9297L90.7754 79.9114L90.7942 79.9301V56.1807C90.7942 54.112 89.113 52.4308 87.0443 52.4308H42.0453C39.9704 52.4308 38.2954 54.112 38.2954 56.1807V79.9301"
          fill="white"
        />
        <path
          d="M38.3079 79.9114L62.0198 98.9297C63.4948 100.117 65.5947 100.117 67.0697 98.9297L90.7754 79.9114L90.7942 79.9301V56.1807C90.7942 54.112 89.113 52.4308 87.0443 52.4308H42.0453C39.9704 52.4308 38.2954 54.112 38.2954 56.1807V79.9301"
          stroke="#000547"
          strokeWidth="2"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M106.419 109.789C106.419 117.46 100.2 123.68 92.5285 123.68C84.8572 123.68 78.6377 117.46 78.6377 109.789C78.6377 102.118 84.8572 95.898 92.5285 95.898C100.2 95.898 106.419 102.118 106.419 109.789Z"
          fill="#44ABF4"
        />
        <path
          d="M106.419 109.789C106.419 117.46 100.2 123.68 92.5285 123.68C84.8572 123.68 78.6377 117.46 78.6377 109.789C78.6377 102.118 84.8572 95.898 92.5285 95.898C100.2 95.898 106.419 102.118 106.419 109.789Z"
          stroke="#000547"
          strokeWidth="1.43393"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M86.7144 111.586L91.6321 116.062L101.266 106.428"
          stroke="#000547"
          strokeWidth="1.43393"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </Wrapper>
  );
}

import React, { useCallback, useState } from "react";
import { get, useFormContext, useWatch } from "react-hook-form";
import styled, { StyledComponent } from "styled-components";
import { CheckedIcon } from "./icons/checked-icon";
import { UncheckedIcon } from "./icons/unchecked-icon";
// import CheckBoxOutlinedIcon from "@material-ui/icons/CheckBoxOutlined";
// import CheckBoxOutlineBlankOutlinedIcon from "@material-ui/icons/CheckBoxOutlineBlankOutlined";
import { FormFieldType } from "storybook/utils/form";
import { ThemeType } from "storybook/utils/theme";
import { FieldErrorDefault } from "storybook/components/field-error";
import { StyledCheckboxLabelDefault } from "./themes/default";

interface CheckboxThemeType extends ThemeType {
  name: Extract<ThemeType["name"], "default">;
}

type StyledComponentMap = Record<
  CheckboxType<any>["theme"],
  StyledComponent<any, any>
>;

const styledCheckboxError: StyledComponentMap = {
  default: FieldErrorDefault,
};
const styledCheckboxLabel: StyledComponentMap = {
  default: StyledCheckboxLabelDefault,
};

const StyledContainer = styled.div`
  display: flex;
`;

export interface CheckboxType<T> extends FormFieldType<T> {
  theme: CheckboxThemeType["name"];
  label: string | JSX.Element;
  disabled?: boolean;
  hideDirty?: boolean;
  hideErrorText?: boolean;
  alignTop?: boolean;
}

export function Checkbox<T>({
  theme,
  label,
  name,
  disabled,
  hideDirty,
  hideErrorText,
  alignTop,
  registerOptions = {},
}: CheckboxType<T>) {
  const [keyboardFocus, setKeyboardFocus] = useState(false);
  const { formState, register } = useFormContext();

  const error = get(formState.errors, name);
  const hasError = Boolean(error);
  const isDirty = get(formState.dirtyFields, name);
  const isDisabled = disabled || formState.isSubmitting;
  const isChecked = useWatch({ name }) === true;

  const StyledCheckboxLabel = styledCheckboxLabel[theme];
  const StyledCheckboxError = styledCheckboxError[theme];

  const Checkbox = isChecked ? CheckedIcon : UncheckedIcon;

  const handleFocus = useCallback(() => {
    setKeyboardFocus(true);
  }, []);

  const { onBlur, ...registerProps } = register(name, registerOptions);

  const handleBlur = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      setKeyboardFocus(false);
      onBlur(event);
    },
    [onBlur]
  );

  const handleClick = useCallback(
    (event: React.MouseEvent<HTMLInputElement>) => {
      // Keyboard events will have [0,0], click events will not.
      setKeyboardFocus(event.screenX === 0 && event.screenY === 0);
    },
    []
  );

  const handleMouseMove = useCallback(() => {
    setKeyboardFocus(false);
  }, []);

  return (
    <>
      <StyledContainer data-testid="Checkbox">
        <StyledCheckboxLabel
          data-testid="Checkbox__Label"
          $hasError={hasError}
          $disabled={isDisabled}
          $keyboardFocus={keyboardFocus}
          $isDirty={hideDirty ? false : isDirty}
          $alignTop={alignTop}
        >
          <input
            {...registerProps}
            data-testid="Checkbox__Input"
            type="checkbox"
            disabled={isDisabled}
            aria-invalid={hasError}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onClick={handleClick}
          />
          <Checkbox
            isDirty={isDirty}
            hideDirty={hideDirty}
            onMouseMove={handleMouseMove}
          />
          <div>{label}</div>
        </StyledCheckboxLabel>
      </StyledContainer>
      {!hideErrorText && error?.message && (
        <StyledCheckboxError
          data-testid="Checkbox__Error"
          $disabled={isDisabled}
        >
          {error.message}
        </StyledCheckboxError>
      )}
    </>
  );
}

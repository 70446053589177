import styled, { css, createGlobalStyle } from "styled-components";
import { mobile, tablet } from "styles/breakpoints";

export const Banner = styled.div`
  padding: 6px 16px;
  border-radius: 4px;
  text-align: center;
  line-height: 22px;
`;

export const ErrorBanner = styled(Banner)`
  color: #9f4f4b;
  background-color: #efdfde;
  border: 1px solid #e6cdd1;
`;

export const LogoContainer = styled.div`
  margin-top: 20px;
  margin-left: 20px;

  img {
    width: 150px;
  }
`;

export const Container = styled.div`
  padding: 0 16px;
  margin: 0 auto;
`;

export const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding: 40px 10px 20px 10px;

  @media (min-width: ${tablet}px) {
    padding: 5vh 20px 20vh 20px;
  }

  @media (orientation: landscape) {
    padding-left: 5%;
    padding-right: 5%;
  }
`;

export const HeaderContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const Heading = styled.h1`
  font-size: 30px;
  font-weight: 500;
  text-align: center;
  text-transform: uppercase;
`;

export const ProviderImage = styled.img`
  display: block;
  margin: 0 auto;
  max-height: 15vh;
  max-width: 100%;
  height: auto;
  padding-bottom: 20px;

  @media (min-height: 700px) {
    display: block;
    padding-bottom: 0;
  }
`;

const ColumnsCss = css`
  @media (min-width: ${tablet}px) {
    gap: 40px;
    flex-direction: row;
  }
`;

export const MainContent = styled.div<{ $videoEnabled?: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  // padding-left: 20px;
  // padding-right: 20px;
  align-self: center;
  max-width: ${({ $videoEnabled }) => ($videoEnabled ? "1728px" : "600px")};
  ${({ $videoEnabled }) => $videoEnabled && ColumnsCss};
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;

  p {
    color: #333;
    font-weight: 400;
    font-size: 14px;
    text-align: justify;
    text-wrap: wrap;
    white-space-collapse: preserve-breaks;

    @media (min-width: ${tablet}px) {
      font-size: 1.5rem;
      line-height: 30px;
    }
  }
`;

export const LeftColumn = styled(Column)`
  video {
    margin: 0 auto;
    max-width: 730px;
  }
`;

export const RightColumn = styled(Column)``;

export const Button = styled.button`
  display: inline-block;
  padding: 8px;
  color: #fff;
  border: 0px;
  width: 100%;
  outline: none;
  cursor: pointer;
  font-weight: 500;
  text-align: center;
  white-space: nowrap;
  text-decoration: none;
  background: radial-gradient(circle at 33% 66%, #76be81 0%, #59b99f 100%);
  box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.16), 0 2px 5px 0 rgba(0, 0, 0, 0.26);
  font-size: 15px;

  @media (min-width: ${tablet}px) {
    font-size: 20px;
  }
`;

export const GlobalStyles = createGlobalStyle`
  body {
    background-color: #f5f8fa;
  }
`;

export const VideoContainer = styled.div<{ $zendeskEnabled?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;

  @media (min-width: ${tablet}px) {
    min-height: 360px;
  }
`;

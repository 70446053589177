import { useState, useCallback } from "react";
import { z } from "zod";
import { Button } from "storybook/components/button";
import { Modal } from "storybook/components/modal";
import { useForm } from "storybook/utils/form";
import { SPECIALIST_HOSTNAME } from "env-vars";
import { useKioskAuth } from "context/kiosk-auth";
import { openQuestionnaire } from "helpers";
import {
  Banner,
  CodePrompt,
  TextWrapper,
  CodeButtons,
  GlobalModalStyles,
} from "../styles";

enum ErrorType {
  GENERAL = "GENERAL",
  NOT_FOUND = "NOT_FOUND",
}

type Props = {
  onClose: () => void;
};

const codeSchema = z
  .object({
    code: z.string().min(1, "Please enter your survey code"),
  })
  .required();

type Code = z.infer<typeof codeSchema>;

export function CodeModal({ onClose }: Props) {
  const { logout } = useKioskAuth();
  const [formValues] = useState<Code>({
    code: "",
  });
  const [isSubmitting, setSubmitting] = useState(false);
  const [error, setError] = useState<ErrorType | null>(null);

  const { Form, Input } = useForm<Code>({
    schema: codeSchema,
    values: formValues,
  });

  const handleSubmit = useCallback(
    async (data: Code) => {
      setSubmitting(true);

      const jwt = localStorage.getItem("id_token");

      const response = await fetch(
        `${SPECIALIST_HOSTNAME}/api/v1/survey_sessions/lock`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
          body: JSON.stringify({
            code: data.code,
          }),
        }
      );

      if (!response.ok) {
        if (response.status === 404) {
          setError(ErrorType.NOT_FOUND);
        } else if (response.status === 401) {
          logout();
        } else {
          setError(ErrorType.GENERAL);
        }

        setSubmitting(false);
        return;
      }

      const sessionData = await response.json();

      if (!sessionData.survey_token) {
        setError(ErrorType.GENERAL);

        setSubmitting(false);
        return;
      }

      const currentUrl = document.location.href.split("?")[0];
      const redirectUrl = `${currentUrl}?complete=true`;
      const queryParams = {
        survey_code: data.code,
        redirectUrl: encodeURIComponent(redirectUrl),
        restartUrl: encodeURIComponent(currentUrl),
      };

      openQuestionnaire({
        queryParams,
        newTab: false,
        token: sessionData.survey_token,
        skipLandingPage: true,
      });
    },
    [logout]
  );

  return (
    <>
      <GlobalModalStyles />
      <Modal
        width={400}
        open={true}
        theme="default"
        onClose={onClose}
        disableBackdropClick={true}
      >
        <Form onSubmit={handleSubmit}>
          <Modal.Head alignTitle="left">
            <TextWrapper>Start Survey</TextWrapper>
          </Modal.Head>
          <Modal.Body>
            {error === ErrorType.NOT_FOUND && (
              <Banner role="alert">The provided code was invalid</Banner>
            )}
            {error === ErrorType.GENERAL && (
              <Banner role="alert">An error occurred. Please try again.</Banner>
            )}
            <CodePrompt>
              If your provider has given you a code to begin your assessment,
              enter it below.
            </CodePrompt>
            <Input
              name="code"
              theme="default"
              hideDirty={true}
              labelPosition="top"
              alignText="left"
              label="Survey Code"
              autoComplete="off"
            />
          </Modal.Body>
          <Modal.Actions alignButtons="center">
            <CodeButtons>
              <Button
                theme="default"
                variant="contained"
                color="default"
                type="button"
                onClick={onClose}
                disabled={isSubmitting}
              >
                Cancel
              </Button>
              <Button
                theme="default"
                variant="contained"
                color="default"
                type="submit"
                disabled={isSubmitting}
              >
                Authenticate
              </Button>
            </CodeButtons>
          </Modal.Actions>
        </Form>
      </Modal>
    </>
  );
}

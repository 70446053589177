import React from "react";
import { defaultTheme } from "storybook/themes/default";

const { colors } = defaultTheme;

type Props = {
  isDirty: boolean;
  hideDirty?: boolean;
  onMouseMove?: React.MouseEventHandler<SVGSVGElement>;
};

export function CheckedIcon({ isDirty, hideDirty, onMouseMove }: Props) {
  const color = !isDirty || hideDirty ? colors.ciqBrand : colors.dirtyLabel;

  return (
    <svg
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      onMouseMove={onMouseMove}
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_10566_972)">
        <rect width="24" height="24" rx="4" fill="white" />
        <path
          stroke={color}
          strokeWidth="1.5"
          strokeLinejoin="round"
          d="M20 6.5L9 17.5L4 12.5"
        />
      </g>
      <rect x="0.5" y="0.5" width="23" height="23" rx="3.5" stroke={color} />
      <defs>
        <clipPath id="clip0_10566_972">
          <rect width="24" height="24" rx="4" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

import { ReactElement } from "react";
import { MainContainer } from "./styles";

type Props = {
  children: ReactElement;
};

export function RootLayout({ children }: Props) {
  return <MainContainer>{children}</MainContainer>;
}

import React, { Suspense } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { RootLayout } from "layouts/root-layout";
import { AccountLayout } from "layouts/account-layout";
import { preserveQueryParams } from "helpers";
import { PatientAuthProvider } from "context/patient-auth";
import { KioskAuthProvider } from "context/kiosk-auth";
import { AdminContentProvider } from "context/admin-content";
import { PatientRoute } from "./patient-route";
import { Loading } from "components/loading";

import PatientLanding from "pages/patient-landing";
import KioskLanding from "pages/kiosk-landing";
import Passwordless from "pages/passwordless";
import EmailPassword from "pages/email-password";
import PasswordlessConfirm from "pages/passwordless-confirm";
import MatchPatient from "pages/match-patient";
import { Settings } from "pages/settings";
import PatientResults from "pages/patient-results";
import { PatientHub } from "pages/kiosk-landing/kiosk-results-page/patient-hub";
import { Pendo } from "components/pendo";
import { Zendesk } from "components/zendesk";

const KioskRoutes = () => (
  <KioskAuthProvider>
    <AdminContentProvider>
      <>
        <Switch>
          <Route path="/institution" exact>
            <KioskLanding />
          </Route>
          <Route path="/institution/patient-hub" exact>
            <PatientHub />
          </Route>
        </Switch>
        <Pendo isRemote={false} />
      </>
    </AdminContentProvider>
  </KioskAuthProvider>
);

const PatientRoutes = () => (
  <PatientAuthProvider>
    <AdminContentProvider>
      <>
        <Switch>
          <PatientRoute path="/" exact component={PatientLanding} />
          <PatientRoute exact path="/settings" component={Settings} />
          <PatientRoute exact path="/complete" component={PatientResults} />
          <Route
            path="/login"
            render={() => (
              <AccountLayout>
                <Switch>
                  <Route
                    exact
                    path="/login/passwordless"
                    component={Passwordless}
                  />
                  <Route
                    exact
                    path="/login/passwordless/confirm"
                    component={PasswordlessConfirm}
                  />
                  <Route
                    exact
                    path="/login/password"
                    component={EmailPassword}
                  />
                  <Route exact path="/login/info" component={MatchPatient} />
                  <Redirect to="/login/passwordless" />
                </Switch>
              </AccountLayout>
            )}
          />
          <Redirect to="/" />
        </Switch>
        <Pendo isRemote />
        <Zendesk />
      </>
    </AdminContentProvider>
  </PatientAuthProvider>
);

export const Router = () => {
  return (
    <RootLayout>
      <Suspense fallback={<Loading />}>
        <Switch>
          <Route path="/institution" component={KioskRoutes} />
          <Route path="/" component={PatientRoutes} />
          <Redirect to={preserveQueryParams("/")} />
        </Switch>
      </Suspense>
    </RootLayout>
  );
};

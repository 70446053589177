import {
  useMemo,
  useState,
  useEffect,
  useContext,
  createContext,
  ReactElement,
} from "react";
import { ErrorType, SPECIALIST_HOSTNAME } from "env-vars";
import { usePatientAuth } from "context/patient-auth";

// eslint-disable-next-line react-refresh/only-export-components
export enum SurveyKind {
  INITIAL = "initial_survey",
  RESCREEN = "rescreen_survey",
  LONG = "long_survey",
}

export type SurveyContextType = {
  isLoading: boolean;
  error: ErrorType | null;
  surveyKind: SurveyKind | null;
};

const SurveyContext = createContext<SurveyContextType | undefined>(undefined);

type Props = {
  children: ReactElement;
};

export function SurveyProvider({ children }: Props) {
  const { isAuthenticated } = usePatientAuth();
  const [isLoading, setLoading] = useState(true);
  const [error, setError] = useState<ErrorType | null>(null);
  const [surveyKind, setSurveyKind] = useState<SurveyKind | null>(null);

  useEffect(() => {
    if (!isAuthenticated) {
      setLoading(false);

      return;
    }

    const patientId = localStorage.getItem("ciq_id");
    const idToken = localStorage.getItem("id_token");

    if (!patientId) {
      setLoading(false);
      setError(ErrorType.GENERAL);

      console.error("Error: No patient ID found in local storage");

      return;
    }

    if (!idToken) {
      setLoading(false);

      console.error("Error: No id token found in local storage");

      return;
    }

    // Prevent duplicate requests.
    if (surveyKind) {
      setLoading(false);

      return;
    }

    // Fetch survey info
    (async () => {
      try {
        const response = await fetch(
          `${SPECIALIST_HOSTNAME}/api/v1/people/${patientId}/next_survey_info`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${idToken}`,
            },
          }
        );

        if (!response.ok) {
          console.error("Error fetching survey info");

          setLoading(false);

          setError(
            response.status === 401 ? ErrorType.AUTH : ErrorType.GENERAL
          );

          return;
        }

        const { can_rescreen, long_survey } = await response.json();

        // use kind from launch params if provided, otherwise select based on patient profile state
        let kind = localStorage.getItem("launch_survey_kind") as SurveyKind;
        if (!kind) {
          if (long_survey?.status == "in_progress") {
            kind = SurveyKind.LONG;
          } else if (can_rescreen) {
            kind = SurveyKind.RESCREEN;
          } else {
            kind = SurveyKind.INITIAL;
          }
        }

        if (kind) {
          setSurveyKind(kind);
        } else {
          console.error("Error fetching survey info: no survey kind found");

          setError(ErrorType.GENERAL);
        }
      } catch (error) {
        console.error("Error fetching survey info: ", error);

        setError(ErrorType.GENERAL);
      }

      setLoading(false);
    })();
  }, [surveyKind, isAuthenticated, isLoading]);

  const contextProps = useMemo(
    () => ({
      error,
      isLoading,
      surveyKind,
    }),
    [error, isLoading, surveyKind]
  );

  return (
    <SurveyContext.Provider value={contextProps}>
      {children}
    </SurveyContext.Provider>
  );
}

// eslint-disable-next-line react-refresh/only-export-components
export const useSurveyContext = () => {
  const context = useContext(SurveyContext);

  if (context === undefined) {
    throw new Error("useSurveyContext must be used within a SurveyProvider");
  }
  return context;
};

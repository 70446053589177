import styled from "styled-components";
import Popper from "@material-ui/core/Popper";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { VisuallyHide } from "storybook/styles/common";
import { Typography } from "storybook/components/typography";
import { FieldErrorDefault } from "storybook/components/field-error";
import { defaultTheme } from "storybook/themes/default";

const { colors } = defaultTheme;

/**
 * Top-level Wrappers
 */

export const FontWrapperFuture = styled(Typography).attrs({
  theme: "default",
  variant: "button",
  component: "div",
})``;

export const StyledSelectWrapperFuture = styled.div`
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  input[type="hidden"] {
    position: absolute;
  }
`;

/**
 * Label Variations
 */

export const StyledLabelFuture = styled.label<{
  $disabled: boolean;
  $error: boolean;
}>`
  ${({ $disabled }) => $disabled && "opacity: 0.7;"}
  color: ${({ $error }) =>
    $error ? colors.defaultTextButton : colors.ciqBrandLight};
  line-height: 19px;
`;

/**
 * Selected Item(s) / Placeholder Components
 */

export const StyledSelectedContainerFuture = styled.div<{
  $multiple: boolean;
}>`
  flex-grow: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-self: center;
`;

export const StyledSelectedItemFuture = styled.div`
  outline: none;
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: 6px;
  text-align: right;
  color: inherit;
`;

export const StyledPlaceholderFuture = styled.div`
  user-select: none;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: right;
  padding-right: 6px;
`;

/**
 * Select Component Variations
 */

export const StyledSelectFuture = styled.div<{
  $disabled: boolean;
  $focused: boolean;
}>`
  display: flex;
  align-items: center;
  border-radius: 8px;
  position: relative;
  color: ${colors.ciqBrand};
  transition: border-color 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  box-sizing: border-box;
  user-select: none;
  white-space: nowrap;
  line-height: 19px;
  cursor: pointer;

  &:focus {
    outline: none;
  }

  > * {
    overflow: hidden;
  }

  ${({ $disabled }) => $disabled && "opacity: 0.7;"}
`;

export const StyledSingleSelectFuture = styled(StyledSelectFuture)<{
  $focused: boolean;
}>`
  color: ${colors.hematite};
  margin-left: 0.5rem;
`;

export const StyledMultiSelectFuture = styled(StyledSelectFuture)`
  color: ${colors.hematite};
  margin-left: 0.5rem;
`;

export const StyledArrowFuture = styled(ExpandMoreIcon)`
  width: 20px;
  height: 100%;
  display: flex;
  transform: scale(1.2);
  color: ${colors.labelText};
  padding-right: 0;
`;

/**
 * Select Pop-up Menu Components
 */

export const StyledPopperFuture = styled(Popper)<{
  $width: number;
}>`
  // In Ithaca, the modal renders at z-index 1300.
  // As there could be multiple modals with custom z-indexes calculated
  // on the fly, this number is high to ensure the select popup appears in front.
  // However, this is brittle and needs to be solved properly.
  z-index: 10000;
  max-height: 95vh;
  padding: 8px 0;
  overflow: auto;
  background-color: white;
  border-radius: 8px;
  user-select: none;
  box-shadow: 0px 5px 5px -3px rgb(0 0 0 / 20%),
    0px 8px 10px 1px rgb(0 0 0 / 14%), 0px 3px 14px 2px rgb(0 0 0 / 12%);
  min-width: ${({ $width }) => ($width ? `${$width}px` : "auto")};

  // Font styling is needed due to the portal not being within the font wrapper.
  font-family: ${defaultTheme.typography.fontFamily};
  font-size: 16px;
  font-weight: 400;
  line-height: 1.5;

  // IE11
  *::-ms-backdrop,
  & {
    border: 1px solid ${colors.inputBorder};
  }
`;

export const StyledItemFuture = styled.div<{
  $selected: boolean;
  $focused: boolean;
}>`
  cursor: pointer;
  user-select: none;
  padding: 6px 16px;
  color: ${colors.defaultTextButton};
  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  &:hover,
  &:focus,
  &:active {
    background-color: ${({ $selected }) =>
      $selected ? colors.focusedInputBg : colors.itemHighlight};
  }

  ${({ $selected }) =>
    $selected && `background-color: ${colors.itemHighlight};`}

  ${({ $focused }) => $focused && `background-color: ${colors.itemHighlight}`}
`;

/**
 * Misc Components
 */

export const StyledErrorTextFuture = styled(FieldErrorDefault)<{
  $hide: boolean;
}>`
  margin: 5px 0 0 0;
  text-transform: none;
  ${({ $hide }) => $hide && VisuallyHide}
`;

export const StyledCheckboxOptionDefault = styled.div`
  display: flex;

  svg {
    margin-right: 12px;
    color: ${colors.ciqBrand};
  }
`;

import React from "react";
import styled from "styled-components";
import MuiTooltip from "@material-ui/core/Tooltip";
import { selector } from "../../../utils/selector";
import { defaultTheme } from "../../../themes/default";

const { colors } = defaultTheme;

// Styling MUITooltip with Styled Components requires a bit of weirdness.
// This pattern is explained here https://github.com/mui/material-ui/issues/11467#issuecomment-423845900
export const StyledTooltipDefault = styled(
  ({ children, className, ...rest }) => (
    <MuiTooltip classes={{ popper: className }} {...rest}>
      {children}
    </MuiTooltip>
  )
)`
  & ${selector`.MuiTooltip-tooltip`} {
    color: ${colors.greyDarkest};
    background-color: ${colors.white};
    border: 1px solid ${colors.greyDarkest};
    border-radius: 8px;
    font-size: 1rem;
    padding: 0.625rem;
  }
  & ${selector`.MuiTooltip-arrow`} {
    color: white;
    &:before {
     border: 1px solid ${colors.greyDarkest};
    },
  }
  & ${selector`.MuiTooltip-tooltipPlacementLeft`},
  & ${selector`.MuiTooltip-tooltipPlacementRight`} {
    margin: 0 14px;
  }
  & ${selector`.MuiTooltip-tooltipPlacementTop`},
  & ${selector`.MuiTooltip-tooltipPlacementBottom`} {
    margin: 14px 0;
  }
`;

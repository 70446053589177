import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import { selector } from "storybook/utils/selector";
import { legacyTheme } from "storybook/themes/legacy";

interface StyledTypographyLegacyProps {
  $color: keyof typeof legacyTheme.colors;
}

export const StyledTypographyLegacy = styled(
  Typography
)<StyledTypographyLegacyProps>`
  &${selector`.MuiTypography-root`} {
    font-family: ${legacyTheme.typography.fontFamily};
    color: ${(props) => legacyTheme.colors[props.$color]};
  }
  &${selector`.MuiTypography-h1`} {
    font-size: 30px;
    font-weight: 500;
  }
  &${selector`.MuiTypography-h2`} {
    font-size: 24px;
    font-weight: 100;
  }
  &${selector`.MuiTypography-h3`} {
    font-size: 16px;
    font-weight: 600;
  }
  &${selector`.MuiTypography-h4`} {
    font-size: 14px;
    font-weight: 600;
  }
  &${selector`.MuiTypography-h5`} {
    font-size: 12px;
    font-weight: 600;
  }
  &${selector`.MuiTypography-h6`} {
    font-size: 10px;
    font-weight: 400;
  }
  &${selector`.MuiTypography-body1`} {
    font-size: 14px;
    font-weight: 400;
  }
  &${selector`.MuiTypography-body2`} {
    font-size: 13px;
    font-weight: 400;
  }
`;

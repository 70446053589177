import { useState, useCallback, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { Typography } from "storybook/components/typography";
import { Button } from "storybook/components/button";
import { usePatientAuth } from "context/patient-auth";
import { Container, InfoBanner } from "pages/styles/login";
import {
  StyledHeading,
  BackContainer,
  BannerContainer,
  ResendContainer,
  PasswordlessLink,
  SentConfirmationText,
} from "./styles";

export default function PasswordlessConfirm() {
  const history = useHistory();
  const [isSubmitting, setSubmitting] = useState(false);
  const { emailAddress, isEmailSent, sendMagicLink } = usePatientAuth();
  const [sendCount, setSendCount] = useState<number>(0);

  const handleResend = useCallback(() => {
    setSubmitting(true);

    if (!emailAddress) {
      history.push("/login/passwordless");
    }

    sendMagicLink(emailAddress);
  }, [emailAddress, history, sendMagicLink]);

  useEffect(() => {
    if (isEmailSent) {
      setSendCount((prevCount: number) => prevCount + 1);
    }
  }, [isEmailSent, setSendCount]);

  return (
    <Container>
      <StyledHeading>
        <Typography
          theme="default"
          variant="h3"
          color="ebonyClay"
          component="h1"
        >
          Check your email
        </Typography>
      </StyledHeading>
      {sendCount > 1 && isEmailSent && (
        <BannerContainer>
          <InfoBanner role="alert">Email with link sent!</InfoBanner>
        </BannerContainer>
      )}
      <SentConfirmationText>
        <Typography
          theme="default"
          variant="subtitle1"
          color="ebonyClay"
          component="p"
          role="contentinfo"
        >
          We've just sent an email to <strong>{emailAddress}</strong> with a
          unique, time-sensitive link that enables secure login without the need
          for a password.
        </Typography>
        <Typography
          theme="default"
          variant="subtitle1"
          color="ebonyClay"
          component="p"
        >
          Click the link to sign in before it expires in 15 min and check your
          spam folder if you don't see it right away.
        </Typography>
      </SentConfirmationText>
      <ResendContainer>
        <Button
          type="submit"
          theme="default"
          variant="contained"
          color="secondary"
          fullWidth={true}
          onClick={handleResend}
          disabled={isSubmitting && !isEmailSent}
        >
          Resend Email
        </Button>
      </ResendContainer>
      <PasswordlessLink>
        <Typography
          theme="default"
          variant="subtitle1"
          color="ebonyClay"
          component="p"
        >
          Can't find the email?{" "}
          <Link to="/login/password">Sign in using your password instead.</Link>
        </Typography>
      </PasswordlessLink>
      <BackContainer>
        <Link to="/login/passwordless">
          <ArrowBackIcon />
          Back
        </Link>
      </BackContainer>
    </Container>
  );
}

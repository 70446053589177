import styled from "styled-components";
import LockIcon from "@material-ui/icons/Lock";
import { defaultTheme } from "storybook/themes/default";
import { Typography } from "storybook/components/typography";

const { colors } = defaultTheme;

type LockIconProps = {
  $valid: boolean;
};

export const LockContainer = styled.div`
  display: flex;
  justify-content: center;
  align-itmes: center;
  flex-direction: column;
`;

export const StyledLockIcon = styled(LockIcon)<LockIconProps>`
  margin-left: 50px;
  font-size: 8rem;
  color: ${({ $valid }) => ($valid ? colors.ciqBrand : colors.error)};
`;

export const ErrorText = styled(Typography).attrs({
  // @ts-ignore cannot resolve against default theme
  theme: "default",
})`
  text-align: center;
  padding-top: 8px;
`;

import { Button as MUIButton } from "@material-ui/core";
import styled from "styled-components";
import { selector } from "storybook/utils/selector";
import { legacyTheme } from "storybook/themes/legacy";

const { colors } = legacyTheme;

export const StyledButtonLegacy = styled(MUIButton)`
  &${selector`.MuiButton-root`} {
    text-transform: none;
    border-radius: 3px;

    // Default state is equivalent to .MuiButton-sizeMedium
    padding: 9px 15px;
    border-width: 1px;
    border-style: solid;
  }
  & ${selector`.MuiButton-endIcon`} {
    margin-left: 7px;
  }
  & ${selector`.MuiButton-startIcon`} {
    margin-right: 7px;
  }

  &${selector`.MuiButton-sizeSmall`} {
    font-size: 14px;
    padding: 4px 9px;
    & ${selector`.MuiButton-endIcon`} {
      margin-left: 5px;
    }
    & ${selector`.MuiButton-startIcon`} {
      margin-right: 5px;
    }
  }

  &${selector`.MuiButton-sizeLarge`} {
    padding: 9px 15px;
    & ${selector`.MuiButton-endIcon`} {
      margin-left: 7.5px;
    }
    & ${selector`.MuiButton-startIcon`} {
      margin-right: 7.5px;
    }
  }

  & ${selector`.MuiButton-iconSizeSmall`} {
    font-size: 16px;
    > *:first-child {
      font-size: inherit;
    }
  }

  &
    ${selector`.MuiButton-iconSizeMedium`},
    &
    ${selector`.MuiButton-iconSizeLarge`} {
    font-size: 20px;
    > *:first-child {
      font-size: inherit;
    }
  }

  &${selector`.Mui-disabled`} {
    opacity: 0.65;
  }

  &:focus,
  &:hover:active {
    outline: thin dotted;
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
    box-shadow: inset 0 3px 5px rgb(0 0 0 / 13%);
  }

  &${selector`.MuiButton-contained`} {
    color: ${colors.greyDarkest};
    background-color: ${colors.greyBright};
    border-color: ${colors.greyBright};
  }
  &${selector`.MuiButton-containedPrimary`} {
    color: ${colors.white};
    background-color: ${colors.green};
    border-color: ${colors.green};
    &:focus,
    &:hover {
      background-color: ${colors.greenDark};
      border-color: ${colors.greenDark};
    }

    &${selector`.Mui-disabled`} {
      color: ${colors.white};
      background-color: ${colors.green};
    }
  }
  &${selector`.MuiButton-containedSecondary`} {
    color: ${colors.white};
    background-color: ${colors.blue};
    border-color: ${colors.blue};
    &:focus,
    &:hover {
      background-color: ${colors.blueDark};
      border-color: ${colors.blueDark};
    }

    &${selector`.Mui-disabled`} {
      color: ${colors.white};
      background-color: ${colors.blue};
    }
  }

  // Our outlined buttons transition to a contained style on hover
  &${selector`.MuiButton-outlined`} {
    color: ${colors.greyDarkest};
    background-color: ${colors.white};
    border-color: ${colors.grey};
    &:focus,
    &:hover {
      color: ${colors.white};
      border-color: ${colors.greyDark};
      background-color: ${colors.greyDark};
    }
  }
  &${selector`.MuiButton-outlinedPrimary`} {
    color: ${colors.green};
    background-color: ${colors.white};
    border-color: ${colors.green};
    &:focus,
    &:hover {
      color: ${colors.white};
      border-color: ${colors.greenDark};
      background-color: ${colors.greenDark};
    }
  }
  &${selector`.MuiButton-outlinedSecondary`} {
    color: ${colors.blue};
    background-color: ${colors.white};
    border-color: ${colors.blue};
    &:focus,
    &:hover {
      color: ${colors.white};
      border-color: ${colors.blueDark};
      background-color: ${colors.blueDark};
    }
  }
`;

import { useMemo } from "react";

// IE11 does not support the latest version of uuid - use a random number / time based
// id generator
export const useUniqueId = (componentName: string) => {
  return useMemo(
    () =>
      `${componentName}_${Date.now()}_${
        1000 + Math.floor(Math.random() * 9000)
      }`,
    [componentName]
  );
};

import styled from "styled-components";
import { lochmaraBlue, white, Props } from "../styles";

export const TileButton = styled.button<Props>`
  width: 124px;
  height: 112px;
  border: 1px solid ${lochmaraBlue};
  border-radius: 4px;
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: ${({ $isActive }) => ($isActive ? lochmaraBlue : white)};
  &:hover {
    background-color: ${lochmaraBlue};
    > div > svg {
      fill: ${white} !important;
    }
    > div > p {
      color: ${white};
    }
  }
  &:active:focus {
    outline: 5px auto -webkit-focus-ring-color;
    outline-offset: -2px;
  }

  &[disabled] {
    background-color: rgba(235, 235, 236, 0.5);
    border: solid 1px #d9cfd6;
    opacity: 1;
    cursor: not-allowed;
    &:hover {
      > div > svg {
        fill: #b6b6c4 !important;
      }
    }
  }
`;

export const TileInfo = styled.div<Props>`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  white-space: break-spaces;
  font-weight: bold;
  margin: 0;

  :last-child {
    color: ${({ $isActive }) => ($isActive ? white : lochmaraBlue)};
  }

  ${({ $disabled }) =>
    $disabled &&
    `
    && {
      :last-child {
        color: #b6b6c4;
      }
    }
  `};
`;

export const Icon = styled.svg<Props>`
  fill: ${({ $isActive }) => ($isActive ? white : lochmaraBlue)};
  width: 35px;
  height: 35px;

  ${({ $disabled }) =>
    $disabled &&
    `
      && {
        fill: #b6b6c4;
      }
  `};
`;

export const Wrapper = styled.div<Props>`
  ${({ $disabled }) =>
    $disabled &&
    `
      cursor: not-allowed;
  `};
`;

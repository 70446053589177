import { Redirect } from "react-router-dom";
import { Typography } from "storybook/components/typography";
import { Button } from "storybook/components/button";
import { Notification } from "storybook/components/notification";
import { BasicInfoIcon } from "./icons/basic-info";
import { LifestyleIcon } from "./icons/lifestyle";
import { ScreeningsIcon } from "./icons/screenings";
import { FamilyHistoryIcon } from "./icons/family-history";
import { MedicalHistoryIcon } from "./icons/medical-history";
import { LowHighRiskIcon } from "./icons/low-high-risk";
import { CheckedIcon } from "./icons/checked";
import { NoSurveysIcon } from "./icons/no-surveys";
import {
  LevelOfDone,
  usePatientLongSurvey,
} from "./hooks/use-patient-long-survey";
import {
  Container,
  IconWrapper,
  SurveyButton,
  ErrorContainer,
  AlertContainer,
  ButtonContainer,
  RadiosContainer,
  GraphicContainer,
  HeadingContainer,
  CompletedContainer,
  InProgressContainer,
} from "./styles";

const iconMap: Record<string, React.FC> = {
  "Basic Information": BasicInfoIcon,
  Lifestyle: LifestyleIcon,
  Screenings: ScreeningsIcon,
  "Family Builder": FamilyHistoryIcon,
  "Medical History": MedicalHistoryIcon,
};

type Props = {
  userId: string;
};

export function PatientLongSurvey({ userId }: Props) {
  const {
    error,
    levelOfDone,
    surveyModules,
    handleContinue,
    selectedSurvey,
    setSelectedSurvey,
  } = usePatientLongSurvey({ userId });

  if (error) {
    return (
      <ErrorContainer role="alert">
        <NoSurveysIcon />
        <Typography
          variant="body2"
          component="p"
          theme="default"
          color="ciqBrandDark"
        >
          The questionnaire is not available. <br />
          If this is unexpected, please contact your healthcare provider
        </Typography>
      </ErrorContainer>
    );
  }

  return (
    <Container>
      <HeadingContainer>
        <Typography
          variant="h5"
          component="h1"
          theme="default"
          color="ciqBrandDark"
        >
          Risk Assessment
        </Typography>
      </HeadingContainer>
      {levelOfDone === LevelOfDone.DONE && <Redirect to="/complete" />}
      {levelOfDone === LevelOfDone.EMPTY && (
        <AlertContainer>
          <Notification
            theme="default"
            variant="info"
            title="No surveys available. If this is unexpected, please contact your
            healthcare provider."
          />
        </AlertContainer>
      )}
      {levelOfDone === LevelOfDone.NOT_DONE && (
        <InProgressContainer>
          <Typography variant="body1" theme="default" color="ciqBrandDark">
            Continue your self assessment survey to learn about your risks.
          </Typography>
          <RadiosContainer>
            {surveyModules?.map((survey) => {
              const setSelectedId = (name: string) => {
                setSelectedSurvey(
                  surveyModules.find((survey) => survey.surveyName === name)
                );
              };

              const Icon = iconMap[survey.displayName];
              const isActive = selectedSurvey?.surveyName === survey.surveyName;
              const isCompleted = survey.surveyStatus === true;

              return (
                <SurveyButton
                  role="radio"
                  $isActive={isActive}
                  aria-checked={isActive}
                  key={survey.surveyName}
                  disabled={survey.surveyStatus}
                  onClick={() => setSelectedId(survey.surveyName)}
                >
                  <IconWrapper>
                    <Icon />
                  </IconWrapper>
                  {survey.displayName}
                  {isCompleted && (
                    <CompletedContainer>
                      <CheckedIcon />
                      Completed
                    </CompletedContainer>
                  )}
                </SurveyButton>
              );
            })}
          </RadiosContainer>
          <ButtonContainer>
            <Button
              size="large"
              color="default"
              theme="default"
              variant="contained"
              onClick={handleContinue}
              disabled={!selectedSurvey}
            >
              Continue
            </Button>
          </ButtonContainer>
          <GraphicContainer>
            <LowHighRiskIcon />
          </GraphicContainer>
        </InProgressContainer>
      )}
    </Container>
  );
}

import styled from "styled-components";
import { mobile, tablet, desktop } from "styles/breakpoints";

export const HeadingContainer = styled.div`
  margin-top: 75px;
  padding-bottom: 20px;

  @media (min-width: ${mobile}px) {
    padding-bottom: 30px;
  }

  @media (min-width: ${tablet}px) {
    margin-top: 20px;
  }

  && {
    h1 {
      font-size: 28px;
      font-weight: 700;

      @media (min-width: ${tablet}px) {
        font-size: 32px;
      }
    }
  }
`;

export const TextContainer = styled.div`
  margin: 20px 0 20px 0;
`;

export const FormContainer = styled.div`
  [data-testid="Select__Placeholder"] {
    opacity: 0.5;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  position: relative;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 30px;

  button {
    width: 100%;
    text-transform: none;
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BannerContainer = styled.div`
  margin-bottom: 15px;

  @media (min-width: ${mobile}px) {
    margin-bottom: 20px;
  }

  @media (min-width: ${desktop}px) {
    margin-bottom: 25px;
  }
`;

export const FieldsContainer = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
`;

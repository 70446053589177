export function FamilyHistoryIcon() {
  return (
    <svg
      width="25"
      height="24"
      fill="none"
      viewBox="0 0 25 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.0495 18.0393C18.9494 18.0393 18.8499 18.0021 18.7723 17.9283C16.6141 15.8535 14.0615 15.8187 14.0358 15.8187C11.449 15.7095 9.80137 14.9853 9.1229 13.6665C8.08596 11.6511 9.74274 9.04109 9.95404 8.72249C11.5192 5.31869 10.4346 3.74729 10.4237 3.73169C10.2985 3.55769 10.3375 3.31529 10.5134 3.18989C10.6887 3.06509 10.933 3.09989 11.0624 3.27089C11.1204 3.34769 12.4585 5.18969 10.6624 9.07169L10.6307 9.12749C10.6136 9.15209 8.95619 11.6199 9.83068 13.3161C10.3705 14.3637 11.7916 14.9439 14.0536 15.0399H14.0554C14.1836 15.0399 16.9415 15.0789 19.3256 17.3715C19.4819 17.5221 19.485 17.7681 19.3317 17.9217C19.2541 17.9997 19.1515 18.0387 19.0483 18.0387L19.0495 18.0393Z"
        fill="#231F20"
      />
      <path
        d="M13.7001 21.2733C13.5798 21.2733 13.462 21.2193 13.3844 21.1173C13.3264 21.0405 11.9884 19.1985 13.7844 15.3165L13.8162 15.2607C13.8333 15.2361 15.4906 12.7683 14.6162 11.0721C14.0763 10.0245 12.6553 9.44428 10.3933 9.34828C10.2907 9.34828 7.51941 9.32308 5.12065 7.01668C4.96431 6.86668 4.96126 6.62008 5.11454 6.46648C5.26721 6.31288 5.51881 6.31048 5.67454 6.46048C7.84368 8.54608 10.3841 8.57008 10.4098 8.57008C12.9972 8.67928 14.6449 9.40348 15.3233 10.7223C16.3603 12.7377 14.7035 15.3477 14.4922 15.6663C12.927 19.0701 14.0116 20.6415 14.0226 20.6565C14.1478 20.8305 14.1087 21.0735 13.9328 21.1983C13.862 21.2487 13.7801 21.2733 13.6995 21.2733H13.7001Z"
        fill="#231F20"
      />
      <path
        d="M5.83082 7.41748C5.69769 7.41748 5.567 7.35148 5.4925 7.23088C5.3783 7.04728 5.43754 6.80788 5.62379 6.69568L10.7041 3.64768C10.8909 3.53608 11.1346 3.59368 11.2488 3.77728C11.363 3.96088 11.3038 4.20028 11.1175 4.31248L6.03723 7.36048C5.97249 7.39888 5.90104 7.41748 5.83082 7.41748Z"
        fill="#231F20"
      />
      <path
        d="M7.24338 8.38933C7.10842 8.38933 6.97651 8.32153 6.90262 8.19913C6.79086 8.01433 6.85254 7.77553 7.04063 7.66573L10.9478 5.38453C11.1359 5.27533 11.3789 5.33533 11.4907 5.52013C11.6024 5.70493 11.5408 5.94373 11.3527 6.05353L7.44551 8.33473C7.382 8.37193 7.31239 8.38933 7.24338 8.38933Z"
        fill="#231F20"
      />
      <path
        d="M13.4753 20.705C13.341 20.705 13.2097 20.6378 13.1352 20.5154C13.0228 20.3306 13.0839 20.0918 13.2714 19.9814L18.3785 16.9772C18.566 16.8668 18.809 16.9268 18.922 17.111C19.0344 17.2958 18.9733 17.5346 18.7858 17.645L13.6787 20.6492C13.6152 20.687 13.5449 20.7044 13.4753 20.7044V20.705Z"
        fill="#231F20"
      />
      <path
        d="M13.256 19.0235C13.1229 19.0235 12.9934 18.9575 12.9183 18.8381C12.8035 18.6551 12.8615 18.4151 13.0478 18.3023L16.9189 15.9629C17.1052 15.8507 17.3494 15.9071 17.4642 16.0907C17.5791 16.2737 17.521 16.5137 17.3348 16.6265L13.4637 18.9653C13.3989 19.0043 13.3275 19.0229 13.256 19.0229V19.0235Z"
        fill="#231F20"
      />
      <path
        d="M9.77586 14.1458C9.64151 14.1458 9.51021 14.078 9.43571 13.9562C9.32334 13.7714 9.38441 13.5326 9.57189 13.4222L14.679 10.4186C14.8665 10.3082 15.1102 10.3682 15.2225 10.5524C15.3349 10.7372 15.2738 10.976 15.0864 11.0864L9.97922 14.09C9.91571 14.1278 9.84548 14.1452 9.77586 14.1452V14.1458Z"
        fill="#231F20"
      />
      <path
        d="M11.3581 15.3741C11.225 15.3741 11.0955 15.3081 11.0204 15.1887C10.9056 15.0057 10.9636 14.7657 11.1498 14.6529L15.021 12.3135C15.2066 12.2013 15.4515 12.2577 15.5663 12.4413C15.6811 12.6243 15.6231 12.8643 15.4368 12.9771L11.5657 15.3165C11.501 15.3555 11.4295 15.3741 11.3581 15.3741Z"
        fill="#231F20"
      />
      <path
        d="M9.16812 12.4736C9.0356 12.4736 8.88232 12.4196 8.83102 12.2894C8.8072 12.2282 8.773 11.8664 8.95926 11.7536L13.143 9.20779C13.3293 9.09439 13.5736 9.15139 13.6884 9.33379C13.8038 9.51679 13.7464 9.75679 13.5601 9.86959L9.37636 12.4154C9.31163 12.455 9.23896 12.4736 9.16812 12.4736Z"
        fill="#231F20"
      />
    </svg>
  );
}

import styled from "styled-components";
import { mobile, tablet } from "styles/breakpoints";

export const PageContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px 0 20px;

  @media (min-width: ${mobile}px) {
    padding: 0;
    width: 80vw;
  }
`;

export const MainContent = styled.main`
  display: flex;
  flex-direction: column;
  height: calc(100vh - 100px);
`;

export const ColumnsContainer = styled.div`
  height: 100%;
  flex: 0.75;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media (min-width: ${tablet}px) {
    flex-direction: row;
  }
`;

export const Column = styled.div<{ $single?: boolean }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 0;
  max-width: 900px;
  align-self: center;
  justify-content: ${({ $single }) => ($single ? "flex-start" : "center")};
`;

export const CopyContainer = styled.div`
  color: #333;
  font-size: 14px;
  line-height: 20px;
  text-align: center;

  @media (orientation: portrait) {
    flex: 1;
    padding-left: 1em;
    padding-right: 1em;
    max-width: 900px;
    text-align: center;
  }
`;

export const ButtonContainer = styled.div`
  text-align: center;
  margin-top: 20px;

  button {
    width: 100%;
    text-transform: capitalize;
  }

  @media (min-width: ${tablet}px) {
    button {
      width: 130px;
    }
  }
`;

import { ReactElement } from "react";
import { useLocation } from "react-router-dom";
import { Typography } from "storybook/components/typography";
import { HeaderGraphic } from "./header-graphic";
import { Logo } from "components/logo";
import {
  Header,
  Content,
  IconContainer,
  MainContainer,
  FooterContainer,
  InnerContainer,
  ContentContainer,
  GraphicContainer,
} from "./account-layout-styles";
import { EmailGraphic } from "./email-graphic";

type Props = {
  children: ReactElement;
};

const currentYear = new Date().getFullYear();

export function AccountLayout({ children }: Props) {
  const location = useLocation();

  const isConfirmPage = location.pathname.includes("/confirm");
  const isMatchingPage = location.pathname.includes("/info");

  return (
    <>
      <MainContainer>
        <ContentContainer>
          <InnerContainer>
            <Header>
              <IconContainer>
                <Logo />
              </IconContainer>
            </Header>
            {!isMatchingPage && (
              <GraphicContainer>
                {isConfirmPage ? <EmailGraphic /> : <HeaderGraphic />}
              </GraphicContainer>
            )}
            <Content>{children}</Content>
          </InnerContainer>
        </ContentContainer>
      </MainContainer>
      <FooterContainer>
        <Typography
          theme="default"
          variant="subtitle1"
          color="ciqBrandLight"
          component="p"
        >
          © {currentYear} CancerIQ. All Rights Reserved.{" "}
          <a
            href="https://www.canceriq.com/terms-of-use-agreement"
            target="_blank"
          >
            Terms of Service
          </a>
        </Typography>
      </FooterContainer>
    </>
  );
}

import styled from "styled-components";
import { Link } from "react-router-dom";
import { tablet, mobile } from "styles/breakpoints";
import { IconButton } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";

export const Container = styled.nav<{
  $scrollbarWidth: number;
  $scrollbarVisible: boolean;
}>`
  background-color: #fff;
  padding-top: 10px;
  min-height: 50px;
  display: flex;
  padding-top: 20px;
  align-items: center;
  justify-content: center;
  margin-right: ${({ $scrollbarVisible, $scrollbarWidth }) =>
    $scrollbarVisible ? 0 : $scrollbarWidth}px;
`;

export const NavContent = styled.div`
  max-width: 1200px;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-between;
`;

export const NavSectionLeft = styled.div`
  display: flex;
  align-items: center;
  padding-left: 25px;

  > img {
    margin-top: 6px;
    margin-right: 40px;
  }
`;

export const MenuButtonIcon = styled(IconButton)`
  margin-top: -8px;
`;

export const HomeMenuItem = styled(MenuItem)`
  &.home {
    @media (min-width: ${tablet}px) {
      display: none;
    }
  }
`;

export const NavMenuItemLink = styled(Link)`
  font-size: 16px;
  text-decoration: none;
  color: inherit;
`;

export const NavMenuItem = styled.span`
  font-size: 14px;
  display: flex;
  align-items: center;
`;

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  max-width: 1200px;
  position: absolute;
  right: 0;

  button {
    border-radius: 8px;
  }
`;

export const AccountText = styled.span`
  color: #48495c;
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  margin-left: 5px;
  display: none;

  @media (min-width: ${mobile}px) {
    display: flex;
  }
`;

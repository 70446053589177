import React from "react";
import { Typography } from "storybook/components/typography";
import { InfoIcon } from "./icons/info-icon";
import { SuccessIcon } from "./icons/success-icon";
import { WarningIcon } from "./icons/warning-icon";
import { ErrorIcon } from "./icons/error-icon";
import { CloseIcon } from "./icons/close-icon";
import {
  Container,
  IconContainer,
  TitleContainer,
  ContentContainer,
  CloseIconContainer,
} from "./styles";

export type Variant = "info" | "success" | "error" | "warning";

const iconMap: Record<Variant, React.FC> = {
  info: InfoIcon,
  success: SuccessIcon,
  warning: WarningIcon,
  error: ErrorIcon,
};

export type NotificationType = {
  theme: "default";
  title: string;
  variant: Variant;
  fullWidth?: boolean;
  closeButton?: boolean;
  onClose?: () => void;
  children?: React.ReactNode;
};

export function Notification({
  theme,
  title,
  variant,
  onClose,
  children,
  fullWidth,
  closeButton,
}: NotificationType) {
  const Icon = iconMap[variant];

  const childrenAsText = typeof children === "string";

  const content = childrenAsText ? (
    <Typography theme={theme} variant="body1" color="black">
      {children}
    </Typography>
  ) : (
    children
  );

  return (
    <Container
      role="alert"
      $variant={variant}
      $fullWidth={fullWidth}
      $closeButton={closeButton}
      data-testid="Notification"
    >
      <IconContainer>
        <Icon />
      </IconContainer>
      <TitleContainer>
        <Typography theme={theme} variant="body1" color="black">
          {title}
        </Typography>
      </TitleContainer>
      {content && (
        <ContentContainer $childrenAsText={childrenAsText}>
          {content}
        </ContentContainer>
      )}
      {closeButton && onClose && (
        <CloseIconContainer role="button" onClick={onClose} title="Close">
          <CloseIcon />
        </CloseIconContainer>
      )}
    </Container>
  );
}

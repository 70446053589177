import styled from "styled-components";
import { Typography } from "storybook/components/typography";
import { legacyTheme } from "storybook/themes/legacy";
import { selector } from "storybook/utils/selector";

const { colors } = legacyTheme;

type InputLabelLegacyProps = {
  $hasError: boolean;
  $hideLabel: boolean;
  $disabled: boolean;
  $size: "small" | "medium";
};

export const InputLabelLegacy = styled(Typography).attrs(
  ({ $hasError, $size }: InputLabelLegacyProps) => ({
    color: $hasError ? "error" : "defaultTextButton",
    theme: "legacy",
    variant: $size === "small" ? "body2" : "body1",
    component: "label",
  })
)`
  display: flex;
  flex: 1;
  align-items: center;
  > * {
    flex: 0 0
      ${({ $hideLabel }: InputLabelLegacyProps) => ($hideLabel ? 100 : 50)}%;
  }

  .MuiInputBase-input.MuiOutlinedInput-input::placeholder {
    opacity: 0.8;
  }

  ${selector`.MuiInputBase-root`} {
    padding: ${({ $size }) => ($size === "small" ? "0px" : "0.375rem 0.75rem")};
    border-radius: ${({ $variant }) => ($variant === "round" ? "3px" : "1px")};
    height: ${({ $size }) => ($size === "small" ? "20.56px" : "34px")};
    font-size: inherit;

    ${selector`.MuiOutlinedInput-notchedOutline`} {
      border: 1px solid
        ${({ $hasError }: InputLabelLegacyProps) =>
          $hasError ? colors.red : colors.iron};
    }

    &${selector`.Mui-focused`} {
      ${({ $size }) =>
        $size === "small" &&
        `outline: -webkit-focus-ring-color auto 1px; outline-offset: 1px;`}

      .MuiOutlinedInput-notchedOutline {
        border-color: ${({ $hasError, $disabled }: InputLabelLegacyProps) => {
          if ($disabled) return "auto";

          return $hasError ? colors.red : colors.iron;
        }};
      }

      &${selector`.Mui-focused`} .MuiOutlinedInput-notchedOutline {
        transition: border-color 0.15s ease-in-out 0s,
          box-shadow 0.15s ease-in-out 0s;

        ${({ $size, $hasError }) =>
          $size === "small"
            ? `border: none;`
            : `border: 1px solid ${$hasError ? colors.red : colors.green};`}
      }
    }

    ${selector`.MuiOutlinedInput-input`} {
      padding: 0;
      text-align: left;
      color: ${({ $hasError }: InputLabelLegacyProps) =>
        $hasError ? colors.red : colors.greyDarkest};

      ${({ $size }) =>
        $size === "small" &&
        `font-weight: bold; 
         font-variation-settings: "wght" 700;
         padding-left: 1px;
        `}
    }

    input[type="number"] + ${selector`.MuiInputAdornment-positionEnd`} {
      margin-left: 0;
    }
  }
`;

import styled from "styled-components";
import { defaultTheme } from "storybook/themes/default";
import { mobile, tablet, desktop } from "styles/breakpoints";

const { colors } = defaultTheme;

export const Container = styled.div<{ $serverError?: boolean }>`
  margin-top: 0;

  [data-testid="Input"] {
    // We currently don't have a way in the Storybook input component
    // to set the error state outside of the form state, so this is workaround.
    ${({ $serverError }) =>
      $serverError &&
      `
    .MuiOutlinedInput-notchedOutline {
      border-color: ${colors.error};
    }
    `}

    // Hack to get around a bug where MUI's styles are loaded after our
    // Storybook component styles, causing margin to be overwritten.
    label + div.MuiTypography-root {
      margin-top: 5px;
    }
  }
`;

export const FormContainer = styled.div`
  margin-top: 12px;

  @media (min-width: ${mobile}px) {
    margin-top: 22px;
  }

  && {
    input {
      text-align: left;
    }

    button {
      text-transform: none;
    }
  }
`;

export const TextContainer = styled.div`
  && {
    p {
      line-height: 24px;
    }
  }
`;

export const StyledHeadings = styled.div`
  padding-bottom: 23px;

  && {
    h1 {
      font-weight: 700;
      font-size: 28px;
      line-height: 38.14px;

      @media (min-width: ${mobile}px) {
        font-size: 32px;
        text-align: center;
      }

      @media (min-width: ${tablet}px) {
        font-size: 32px;
      }
    }
  }
`;

export const ButtonsContainer = styled.div`
  width: 100%;
  display: flex;
  gap: 16px;
  position: relative;
  flex-direction: column;

  button {
    flex: 1;
  }
`;

export const PromptText = styled.div`
  margin: 30px 0 0 0;

  @media (min-width: ${tablet}px) {
    margin-top: 43px;
  }
`;

export const InputContainer = styled.div`
  margin-top: 16px;
`;

export const ErrorMessage = styled.div`
  margin-top: 5px;

  && {
    p {
      color: ${colors.error};
      font-size: 12px;
    }
  }
`;

export const Banner = styled.div`
  padding: 6px 16px;
  border-radius: 4px;
  text-align: center;
  line-height: 22px;
`;

export const ErrorBanner = styled(Banner)`
  color: #9f4f4b;
  background-color: #efdfde;
  border: 1px solid #e6cdd1;
`;

export const InfoBanner = styled(Banner)`
  color: #3a842d;
  background-color: #4a7c3033;
  border: 1px solid #4a7c3033;
`;

export const SuccessBanner = styled(Banner)`
  color: #3a842d;
  background-color: #4a7c3033;
  border: 1px solid #4a7c3033;
`;

export const ProgressContainer = styled.div`
  top: 3px;
  right: 5px;
  position: absolute;

  @media (min-width: ${mobile}px) {
    right: -45px;
  }
`;

export const ButtonSeparator = styled.div`
  margin-top: 2px;
  padding-top: 12px;
  margin-bottom: 16px;
  border-bottom: 1px solid #d5d4d7;
  display: flex;
  justify-content: center;
  position: relative;

  .MuiTypography-root {
    top: 0;
    font-size: 16px;
    padding: 0 16px;
    position: absolute;
    background-color: white;
  }
`;

import { useState, useCallback, useEffect } from "react";
import { Redirect, useHistory } from "react-router-dom";
import { z } from "zod";
import { CircularProgress } from "@material-ui/core";
import { useForm } from "storybook/utils/form";
import { Button } from "storybook/components/button";
import { Typography } from "storybook/components/typography";
import { usePatientAuth, emailLinkErrorMessage } from "context/patient-auth";
import { PrivacyLink } from "components/privacy-link";
import { preserveQueryParams } from "helpers";
import {
  Container,
  PromptText,
  ErrorBanner,
  ErrorMessage,
  FormContainer,
  TextContainer,
  StyledHeadings,
  ButtonSeparator,
  ButtonsContainer,
} from "pages/styles/login";
import { ProgressContainer } from "pages/styles/login";
import { Row, ContinueButton, BannerContainer } from "./styles";

const patientSchema = z.object({
  patient: z
    .object({
      email: z.string().email({ message: "Please enter a valid email " }),
    })
    .required()
    .strict(),
});

type Patient = z.infer<typeof patientSchema>;

export default function Passwordless() {
  const history = useHistory();
  const {
    isSubmitting,
    setSubmitting,
    sendMagicLink,
    emailLinkError,
    isAuthenticated,
    passwordlessError,
    setEmailLinkError,
    setPasswordlessError,
    setEmailPasswordError,
  } = usePatientAuth();
  const [formState, setFormState] = useState<Patient>({
    patient: {
      email: "",
    },
  });

  const { Form, Input, getFieldState } = useForm<Patient>({
    schema: patientSchema,
    values: formState,
    shouldFocusError: true,
  });

  const fieldError = Boolean(getFieldState("patient.email").error);

  const handleSubmit = useCallback(
    (data: Patient) => {
      setSubmitting(true);
      setPasswordlessError(false);
      setEmailLinkError(undefined);

      setFormState(data);

      // This method does not return a Promise, therefore we don't know when it finishes.
      // We rely on state updates in the auth context to control what happens next in this component.
      sendMagicLink(data.patient.email);
    },
    [sendMagicLink, setEmailLinkError, setPasswordlessError, setSubmitting]
  );

  const handleManualSignInClick = useCallback(() => {
    setEmailPasswordError(undefined);
    setEmailLinkError(undefined);

    history.push({
      pathname: "/login/password",
      search: location.search,
    });
  }, [history, setEmailLinkError, setEmailPasswordError]);

  useEffect(() => {
    if (fieldError) {
      setPasswordlessError(false);
    }
  }, [fieldError, setPasswordlessError]);

  if (isAuthenticated) {
    return <Redirect to={preserveQueryParams("/")} />;
  }

  const isDisabled = isSubmitting && !passwordlessError;

  return (
    <Container $serverError={passwordlessError}>
      <StyledHeadings>
        <Typography
          theme="default"
          variant="h3"
          color="ebonyClay"
          component="h1"
        >
          Let's find your patient record
        </Typography>
      </StyledHeadings>
      {emailLinkError && (
        <BannerContainer>
          <ErrorBanner role="alert">
            {emailLinkErrorMessage[emailLinkError]}
          </ErrorBanner>
        </BannerContainer>
      )}
      <TextContainer>
        <Typography
          theme="default"
          variant="subtitle1"
          color="ebonyClay"
          component="p"
        >
          Welcome to the CancerIQ platform, where you can access up-to-date
          information about your cancer risk. Let's get started by locating you
          in our system.
        </Typography>
        <PromptText>
          <Typography
            theme="default"
            variant="subtitle1"
            color="ebonyClay"
            component="p"
          >
            <strong>Securely sign in using your email</strong>
          </Typography>
        </PromptText>
      </TextContainer>
      <FormContainer>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Input
              theme="default"
              type="email"
              label="Email address"
              name="patient.email"
              labelPosition="top"
              alignText="left"
              hideDirty
            />
            {!fieldError && passwordlessError && (
              <ErrorMessage>
                <Typography
                  theme="default"
                  variant="subtitle2"
                  color="ebonyClay"
                  component="p"
                >
                  Error sending email, please try again.
                </Typography>
              </ErrorMessage>
            )}
          </Row>
          <ButtonsContainer>
            <ContinueButton
              variant="contained"
              disabled={isDisabled}
              $isSubmitting={isSubmitting}
            >
              Continue
            </ContinueButton>
            <ButtonSeparator>
              <Typography
                theme="default"
                variant="subtitle2"
                color="ebonyClay"
                component="span"
              >
                Or
              </Typography>
            </ButtonSeparator>
            <Button
              type="button"
              theme="default"
              variant="outlined"
              disabled={isSubmitting}
              onClick={handleManualSignInClick}
            >
              Use Password
            </Button>
            {isSubmitting && (
              <ProgressContainer>
                <CircularProgress size={30} />
              </ProgressContainer>
            )}
          </ButtonsContainer>
          <PrivacyLink />
        </Form>
      </FormContainer>
    </Container>
  );
}

export function ErrorIcon() {
  return (
    <svg
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      aria-label="error icon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23 21.5L12 2.5L1 21.5H23ZM11 18.5V16.5H13V18.5H11ZM11 14.5H13V10.5H11V14.5Z"
        fill="#C71000"
      />
    </svg>
  );
}

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App.tsx";
import * as Sentry from "@sentry/react";
// Must import sentry/tracing after sentry/react according to Sentry documentation
import { Integrations } from "@sentry/tracing";
import { CaptureConsole as CaptureConsoleIntegration } from "@sentry/integrations";
import {
  SEND_EVENTS_TO_SENTRY,
  SENTRY_DSN,
  SENTRY_ENVIRONMENT,
  SENTRY_SAMPLE_RATE,
} from "./env-vars.ts";
import { name, version } from "../package.json";

// Initialize Sentry SDK as early as possible
Sentry.init({
  dsn: SEND_EVENTS_TO_SENTRY ? SENTRY_DSN : undefined,
  integrations: [
    new CaptureConsoleIntegration({
      levels: ["error"],
    }),
    new Integrations.BrowserTracing({
      tracingOrigins: ["localhost", "my.canceriq.com"],
      // react-router integration
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      idleTimeout: 2000, // Wait 2s before marking transaction as complete
    }),
  ],
  tracesSampleRate: SENTRY_SAMPLE_RATE,
  environment: SENTRY_ENVIRONMENT,
  release: `${name}@${version}`,
});

ReactDOM.render(
  <Sentry.ErrorBoundary fallback={<p>An error has occurred</p>}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </Sentry.ErrorBoundary>,
  document.getElementById("root")
);

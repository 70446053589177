import { useLocation } from "react-router-dom";
import { KioskLandingPage } from "./kiosk-landing-page";
import { KioskResultsPage } from "./kiosk-results-page";

export default function KioskLanding() {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const complete = searchParams.get("complete");

  return complete ? <KioskResultsPage /> : <KioskLandingPage />;
}

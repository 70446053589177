import styled from "styled-components";
import {
  StyledModal,
  StyledModalHead,
  StyledModalBody,
  StyledModalActions,
  StyledCloseButton,
} from "../styles";
import { selector } from "../../../utils/selector";
import { legacyTheme } from "../../../themes/legacy";

const { colors } = legacyTheme;

export const StyledModalLegacy: typeof StyledModal = styled(StyledModal)`
  &${selector`.MuiDialog-root`} {
    box-sizing: border-box;
  }

  & ${selector`.MuiDialog-paper`} {
    box-shadow: 0 3px 9px rgb(0 0 0 / 50%);
  }
`;

export const StyledModalHeadLegacy = styled(StyledModalHead)`
  &${selector`.MuiDialogTitle-root`} {
    background-color ${colors.greyLynch};
    border-bottom: 1px solid ${colors.platinum};
  }

  ${selector`.MuiTypography-root`} {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: ${colors.white};
  }
`;

export const StyledModalBodyLegacy = styled(StyledModalBody)`
  &${selector`.MuiDialogContent-root`} {
    padding: 20px;
  }
`;

export const StyledModalActionsLegacy = styled(StyledModalActions)`
  &${selector`.MuiDialogActions-root`} {
    padding: 15px;
    border-top: 1px solid ${colors.platinum};
  }
`;

export const StyledCloseButtonLegacy = styled(StyledCloseButton)`
  &${selector`.close-btn`} {
    display: none;
  }
`;

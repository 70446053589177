export function ScreeningsIcon() {
  return (
    <svg
      width="21"
      height="16"
      fill="none"
      viewBox="0 0 21 16"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.5521 8C20.5521 12.4183 16.9066 16 12.4097 16C9.83965 16 7.55133 14.8273 6.05904 13H9.04139C10.0078 13.6306 11.1652 14 12.4097 14C15.777 14 18.5165 11.3084 18.5165 8C18.5165 4.69159 15.777 2 12.4097 2C10.1552 2 8.18703 3.20966 7.12915 5H4.86469C6.07314 2.0694 8.99343 0 12.4097 0C16.9066 0 20.5521 3.58173 20.5521 8ZM15.4631 10H0.196045V12H15.4631V10ZM13.4275 5C12.3033 5 11.3919 5.89543 11.3919 7C11.3919 8.10457 12.3033 9 13.4275 9C14.5517 9 15.4631 8.10457 15.4631 7C15.4631 5.89543 14.5517 5 13.4275 5ZM10.3741 6H3.24945C1.5631 6 0.196045 7.34314 0.196045 9L10.3741 9V6Z"
        fill="black"
      />
    </svg>
  );
}

import styled from "styled-components";
import { Typography } from "storybook/components/typography";

export const TermsLink = styled.a`
  color: black;
  font-size: 12px;
  margin-top: 10px;
  display: block;
`;

export const Container = styled.div`
  margin-top: 41px;
  text-align: center;

  && {
    p {
      font-size: 12px;
    }
  }
`;

export function PrivacyLink() {
  return (
    <Container data-id="PrivacyLink">
      <Typography
        theme="default"
        variant="subtitle2"
        color="ebonyClay"
        component="p"
      >
        Securing your personal information is our priority.{" "}
      </Typography>
      <TermsLink href="https://www.canceriq.com/privacy-policy" target="_blank">
        See our privacy measures
      </TermsLink>
    </Container>
  );
}

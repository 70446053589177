import styled from "styled-components";

export const DateInputContainer = styled.div`
  width: 100%;
  position: relative;

  .react-datepicker-wrapper {
    width: 100%;
  }
`;

// export const InputContainer = styled.div`
//   position: relative;
// `;

// export const IconContainer = styled.div`
//   position: absolute;
//   right: 10px;
//   top: 35px;
//   cursor: pointer;

//   background-color: red;
//   width: 20px;
//   height: 20px;
//   z-index: 1;

//   svg {
//     fill: #333;
//   }
// `;

import styled from "styled-components";
import { defaultTheme } from "storybook/themes/default";
import { Typography } from "storybook/components/typography";

const { colors } = defaultTheme;

type TextareaDefaultProps = {
  $height: number;
  $hasError: boolean;
  $hideLabel: boolean;
  $resize: boolean;
  disabled: boolean;
  $error: boolean;
  $isDirty: boolean;
};

export const StyledTextareaLabelDefault = styled(Typography).attrs(
  ({ $hasError, $isDirty, disabled }: TextareaDefaultProps) => {
    let color;

    if ($hasError) {
      color = "error";
    } else if ($isDirty) {
      color = "dirtyLabel";
    } else if (disabled) {
      color = "disabledText";
    } else {
      color = "defaultTextButton";
    }

    return {
      color,
      theme: "default",
      variant: "subtitle1",
      component: "label",
    };
  }
)`
  display: flex;
  flex: 1;
  align-items: center;

  > * {
    flex: 0 0
      ${({ $hideLabel }: TextareaDefaultProps) => ($hideLabel ? 100 : 50)}%;
  }
`;

export const StyledTextareaDefault = styled.textarea<TextareaDefaultProps>`
  resize: ${({ $resize }) => ($resize ? "auto" : "none")};
  height: ${({ $height }) => $height}px;
  color: inherit;
  border: none;
  box-shadow: inset 0 0 0 1px
    ${({ $hasError }) => ($hasError ? colors.error : colors.inputBorder)};
  border-radius: 8px;
  flex-shrink: 1;
  padding: 8px;
  font-family: ${defaultTheme.typography.fontFamily};
  font-size: 1rem;
  font-weight: 400;
  line-height: 24px;

  ::placeholder {
    color: ${colors.ciqBrandLight};
    font-family: ${defaultTheme.typography.fontFamily};
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
  }
  :-ms-input-placeholder {
    color: ${colors.ciqBrandLight};
    font-family: ${defaultTheme.typography.fontFamily};
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
  }

  :hover {
    box-shadow: ${({ disabled }) =>
      !disabled && `inset 0 0 0 1px ${colors.defaultTextButton}`};
  }

  &:focus {
    outline: none;
    box-shadow: inset 0 0 0 2px
      ${({ $hasError }) => ($hasError ? colors.error : colors.ciqBrand)};
  }
`;

import React, { useCallback } from "react";
import { Box, CircularProgress } from "@material-ui/core";
import { format } from "date-fns";
import { Modal } from "storybook/components/modal";
import { Button } from "storybook/components/button";
import { Typography } from "storybook/components/typography";
import { ErrorType, usePatientInterest } from "context/patient-interest";
import { ErrorBanner, ConfirmButtons, ModalBodyWrapper } from "../styles";
import { FormSection } from ".";

type Props = {
  dates: Date[];
  onEdit: () => void;
  onClose: () => void;
  onContinue: (data: unknown, formSection: FormSection) => void;
  isSubmitting: boolean;
};

export function Confirmation({
  onEdit,
  onClose,
  onContinue,
  dates,
  isSubmitting,
}: Props) {
  const { errorType } = usePatientInterest();

  const handleConfirm = useCallback(() => {
    onContinue(null, FormSection.CONFIRMATION);
  }, [onContinue]);

  return (
    <>
      <Modal.Body>
        <ModalBodyWrapper>
          {errorType === ErrorType.GENERAL && (
            <ErrorBanner role="alert">
              Something went wrong, please try again
            </ErrorBanner>
          )}
          <Box mb="24px">
            <Typography theme="default" variant="body1" color="black">
              Thank you for letting us know your preferred date(s) and time(s)
              for an appointment!
            </Typography>
          </Box>
          <Typography theme="default" variant="body1" color="ciqBrand">
            You said you were available on:
          </Typography>
          {dates.length && (
            <ul>
              {dates.map((date) => (
                <li key={date.getTime()}>
                  {format(date, "EEEE, MMM d, yyyy 'at' h:mmaaa zzz")}
                </li>
              ))}
            </ul>
          )}
        </ModalBodyWrapper>
      </Modal.Body>
      <Modal.Actions alignButtons="center">
        {isSubmitting ? (
          <CircularProgress />
        ) : (
          <ConfirmButtons>
            <Button
              color="default"
              variant="text"
              theme="default"
              onClick={onClose}
              fullWidth={true}
            >
              Cancel
            </Button>
            <Button
              color="secondary"
              variant="contained"
              theme="default"
              fullWidth={true}
              onClick={onEdit}
            >
              Edit
            </Button>
            <Button
              color="default"
              variant="contained"
              theme="default"
              fullWidth={true}
              onClick={handleConfirm}
            >
              Confirm
            </Button>
          </ConfirmButtons>
        )}
      </Modal.Actions>
    </>
  );
}

import { Button as MUIButton } from "@material-ui/core";
import styled from "styled-components";
import { selector } from "../../../utils/selector";
import { defaultTheme } from "../../../themes/default";

const { colors } = defaultTheme;

export const StyledButtonDefault = styled(MUIButton)`
  &${selector`.MuiButton-root`} {
    white-space: nowrap;
    border-radius: 8px;
  }

  & ${selector`.MuiButton-endIcon`} {
    margin-left: 7px;
  }
  & ${selector`.MuiButton-startIcon`} {
    margin-right: 7px;
  }

  &${selector`.MuiButton-sizeSmall`} {
    & ${selector`.MuiButton-endIcon`} {
      margin-right: 6.5px;
    }
    & ${selector`.MuiButton-startIcon`} {
      margin-right: 6.5px;
    }
  }

  &${selector`.MuiButton-sizeLarge`} {
    & ${selector`.MuiButton-endIcon`} {
      margin-left: 7.5px;
    }
    & ${selector`.MuiButton-startIcon`} {
      margin-right: 7.5px;
    }
  }

  // Icons are always the same size regardless of button size
  &
    ${selector`.MuiButton-iconSizeSmall`},
    &
    ${selector`.MuiButton-iconSizeMedium`},
    &
    ${selector`.MuiButton-iconSizeLarge`} {
    font-size: 24px;
    > *:first-child {
      font-size: inherit;
    }
  }

  &${selector`.MuiButton-contained`},
    &${selector`.MuiButton-containedPrimary`} {
    color: ${colors.white};
    background-color: ${colors.ciqBrand};
    border-color: ${colors.ciqBrand};
    &:hover {
      border-color: ${colors.ciqBrandDark};
      background-color: ${colors.ciqBrandDark};
    }
    &:focus,
    &:hover:active {
      outline: 5px auto ${colors.focusBlue};
      border-color: ${colors.ciqBrandLight};
      background-color: ${colors.ciqBrandLight};
    }
  }
  &${selector`.MuiButton-containedSecondary`} {
    color: ${colors.white};
    background-color: ${colors.highlight};
    border-color: ${colors.highlight};
    &:hover {
      border-color: ${colors.highlightDark};
      background-color: ${colors.highlightDark};
    }
    &:focus,
    &:hover:active {
      outline: 1px dotted;
      outline: 5px auto -webkit-focus-ring-color;
      border-color: ${colors.highlightLight};
      background-color: ${colors.highlightLight};
    }
  }
  &${selector`.MuiButton-contained`},
    &${selector`.MuiButton-containedPrimary`},
    &${selector`.MuiButton-containedSecondary`} {
    &${selector`.Mui-disabled`} {
      color: ${colors.disabledDark};
      background-color: ${colors.disabledLight};
      border-color: ${colors.disabledLight};
    }
  }

  &${selector`.MuiButton-outlined`},
    &${selector`.MuiButton-outlinedPrimary`},
    &${selector`.MuiButton-outlinedSecondary`} {
    color: ${colors.greyDarkest};
    background-color: ${colors.white};
    border-color: ${colors.grey};
    &:hover {
      color: ${colors.greyDarkest};
      border-color: ${colors.greyDark};
      background-color: ${colors.greyLight};
    }
    &:focus,
    &:hover:active {
      color: ${colors.greyDarkest};
      outline: 1px dotted;
      outline: 5px auto -webkit-focus-ring-color;
      border-color: ${colors.greyDark};
      background-color: ${colors.greyDark};
    }
    &${selector`.Mui-disabled`} {
      color: ${colors.disabledDark};
      border-color: ${colors.disabledLight};
      background-color: ${colors.white};
    }
  }

  &${selector`.MuiButton-text`} {
    // By default, set all text buttons to be the same color
    color: ${colors.defaultTextButton};
    &${selector`.MuiButton-colorInherit`} {
      color: inherit;
    }
  }
`;

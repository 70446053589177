import { Box } from "@material-ui/core";
import InfoIcon from "@material-ui/icons/Info";
import { Typography } from "storybook/components/typography";
import {
  Welcome,
  Content,
  RiskSplash,
  ErrorBanner,
  ContentInfo,
  ContentHeader,
  ContentTitle,
  TimeRemaining,
  TileContainer,
  MainContainer,
  ContinueButton,
  AllDoneContainer,
  RiskSplashOverlay,
  RiskAssessmentLabel,
  RiskAssessmentPanel,
  RiskAssessmentHeader,
  ContinueButtonContainer,
} from "./styles";
import { SurveyButton } from "./survey-button";
import { useKioskLongSurvey, LevelOfDone } from "./hooks/use-kiosk-long-survey";

type Props = {
  userId: string;
};

export function KioskLongSurvey({ userId }: Props) {
  const {
    error,
    levelOfDone,
    surveyModules,
    handleContinue,
    selectedSurvey,
    setSelectedSurvey,
  } = useKioskLongSurvey({ userId });

  if (error) {
    return (
      <MainContainer>
        <Content>
          <Welcome>Welcome back!</Welcome>
          <ErrorBanner>
            <InfoIcon />
            <span role="alert">
              There was an error retrieving your surveys. Please try again, or
              contact support.
            </span>
          </ErrorBanner>
        </Content>
      </MainContainer>
    );
  }

  return (
    <MainContainer>
      <Content>
        {levelOfDone === LevelOfDone.DONE && (
          <Box display="flex" flexDirection="column">
            <img alt="Risk result image" src="/risk-result-low-ppl.png" />
            <Box textAlign="center" mt="12px">
              <Typography theme="default" variant="body1" color="black">
                Thank you for completing CancerIQ's Cancer Risk Assessment!
                <br />
                <br />
                Please return this device to the designated area and thank you
                for using CancerIQ!
              </Typography>
            </Box>
          </Box>
        )}
        {levelOfDone === LevelOfDone.EMPTY && (
          <AllDoneContainer>
            <InfoIcon />
            <span role="alert">
              No surveys available. If this is unexpected, please contact your
              healthcare provider.
            </span>
          </AllDoneContainer>
        )}
        {levelOfDone === LevelOfDone.NOT_DONE && (
          <RiskAssessmentPanel>
            <RiskAssessmentHeader>
              <RiskAssessmentLabel>Risk Assessment</RiskAssessmentLabel>
            </RiskAssessmentHeader>
            <RiskSplash $splashUrl={selectedSurvey?.splashImage as string}>
              <RiskSplashOverlay />
              <ContentInfo>
                <ContentHeader>{selectedSurvey?.splashTitle}</ContentHeader>
                <ContentTitle>{selectedSurvey?.displayName}</ContentTitle>
                <TimeRemaining>
                  {selectedSurvey?.estimatedTime} minutes
                </TimeRemaining>
                <ContinueButtonContainer>
                  <ContinueButton
                    onClick={handleContinue}
                    disabled={!selectedSurvey}
                  >
                    Continue
                  </ContinueButton>
                </ContinueButtonContainer>
              </ContentInfo>
            </RiskSplash>
            <TileContainer>
              {surveyModules?.map((survey) => {
                const setSelectedId = (name: string) => {
                  setSelectedSurvey(
                    surveyModules.find((survey) => survey.surveyName === name)
                  );
                };

                return (
                  <SurveyButton
                    key={survey.surveyName}
                    name={survey.surveyName}
                    setSelected={setSelectedId}
                    label={survey.displayName}
                    moduleIcon={survey.moduleIcon}
                    selected={selectedSurvey?.surveyName}
                    disabled={survey.surveyStatus}
                  />
                );
              })}
            </TileContainer>
          </RiskAssessmentPanel>
        )}
      </Content>
    </MainContainer>
  );
}

import styled from "styled-components";

export const IconContainer = styled.div<{ $isPlaying: boolean }>`
  top: 50%;
  color: #565656;
  position: absolute;
  transform: translateY(-50%);
  width: 100%;
  display: flex;
  opacity: 0.75;
  justify-content: center;
  pointer-events: none;
  ${({ $isPlaying }) => $isPlaying && "display: none;"}

  svg {
    transform: scale(2);
  }
`;

export const VideoContainer = styled.div`
  position: relative;

  video {
    width: 100%;
    display: block;
  }
`;

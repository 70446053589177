import { useRef, useMemo, useEffect, useCallback, useState } from "react";
import EmailIcon from "@material-ui/icons/Email";
import { SPECIALIST_HOSTNAME } from "env-vars";
import { Modal } from "storybook/components/modal";
import { Button } from "storybook/components/button";
import { Typography } from "storybook/components/typography";
import { usePatientAuth } from "context/patient-auth";
import { Content, ButtonContainer, CopyText } from "./styles";

type Props = {
  onClose: () => void;
};

export function EmailModal({ onClose }: Props) {
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const { logout } = usePatientAuth();
  const [email] = useState(() => localStorage.getItem("ciq_email") || "");
  const abortControllerRef = useRef<AbortController | null>(null);

  const handleButtonClick = useCallback(async () => {
    if (error || success) {
      onClose();
      return;
    }

    setError(false);
    setLoading(true);

    try {
      const patientId = localStorage.getItem("ciq_id");
      const idToken = localStorage.getItem("id_token");

      abortControllerRef.current = new AbortController();

      const response = await fetch(
        `${SPECIALIST_HOSTNAME}/api/v1/reports/send_risk_assessment_email`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${idToken}`,
          },
          body: JSON.stringify({
            person_id: patientId,
            template_name: "patient_portal_risk_assessment",
          }),
          signal: abortControllerRef.current.signal,
        }
      );

      if (!response.ok) {
        if (response.status === 401) {
          logout();
        } else {
          setError(true);
        }

        return;
      }

      setSuccess(true);
    } catch (error) {
      if (error instanceof Error && error.name !== "AbortError") {
        console.error(error);
        setError(true);
      }
    } finally {
      setLoading(false);
    }
  }, [error, logout, onClose, success]);

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);

  const [title, line1, line2, buttonText] = useMemo(() => {
    if (success) {
      return [
        "Email Successfully Sent",
        <>
          <CopyText>
            Success! &#x1F389; Your risk assessment results are on their way to:
          </CopyText>
          <strong>{email}</strong>
        </>,
        "Please check your inbox shortly. If you don't see it, don't forget to look in your spam folder.",
        "Got it!",
      ];
    }

    if (error) {
      return [
        "Error",
        "We encountered an error and were unable to email you your risk assessment results.",
        "Please try again shortly.",
        "Ok",
      ];
    }

    return [
      "Email Results",
      <>
        <CopyText>
          We'll be sending the PDF of your risk assessment results to:
        </CopyText>
        <strong>{email}</strong>
      </>,
      "Click 'Send' to confirm and watch for your results in your email inbox!",
      loading ? "Sending..." : "Send",
    ];
  }, [error, email, loading, success]);

  return (
    <Modal
      open={true}
      theme="default"
      onClose={onClose}
      disableBackdropClick={true}
    >
      <Modal.Head>{title}</Modal.Head>
      <Modal.Body>
        <Content>
          <Typography theme="default" variant="body1" color="black">
            {line1}
          </Typography>
          <br />
          <Typography theme="default" variant="body1" color="black">
            {line2}
          </Typography>
        </Content>
      </Modal.Body>
      <Modal.Actions alignButtons="center">
        <ButtonContainer>
          <Button
            theme="default"
            color="default"
            variant="contained"
            disabled={loading}
            startIcon={<EmailIcon />}
            onClick={handleButtonClick}
          >
            {buttonText}
          </Button>
        </ButtonContainer>
      </Modal.Actions>
    </Modal>
  );
}

import { CSSProperties } from "react";
import styled from "styled-components";
import { VariantProp } from "../";

const borderColor = {
  default: "#b2b2b2",
  primary: "#8F90A0",
  secondary: "#ED58AB",
};

const textColor = {
  default: "#141414",
  primary: "#141414",
  secondary: "#000000DE",
};

type Props = {
  $variant: VariantProp;
  $disabled: boolean;
};

const getBorderColor = ({ $variant, $disabled }: Props) => {
  if ($disabled && $variant === "default") {
    return "#D3D3D3";
  }
  return borderColor[$variant];
};

const getTextColor = ({ $variant, $disabled }: Props) => {
  if ($disabled) {
    return "#000000DE";
  }

  return textColor[$variant];
};

export const ContainerDefault = styled.div<{
  $variant: VariantProp;
  $disabled: boolean;
}>`
  user-select: none;
  border-radius: 3px;
  align-items: center;
  display: inline-block;
  background-color: white;
  border: 1px solid ${getBorderColor};
  opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};
  background-color: ${({ $variant, $disabled }) =>
    $variant === "default" && $disabled ? "#D3D3D3" : "white"};

  svg {
    width: 20px;
    height: 20px;
    fill: #6a6b80;
    cursor: ${({ $disabled }) => ($disabled ? "not-allowed" : "pointer")};
  }
`;

export const InnerContainerDefault = styled.div<{
  $variant: VariantProp;
  $disabled: boolean;
  $maxWidth?: CSSProperties["maxWidth"];
}>`
  height: 32px;
  display: flex;
  align-items: center;
  padding: 0 0 0 8px;
  color: ${getTextColor};

  && {
    span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: ${({ $maxWidth }) => $maxWidth || "none"};

      font-weight: 500; // Required by consuming applications
      font-variation-settings: "wght" 500; // Required by Storybook
    }
  }
`;

export const ButtonContainer = styled.div`
  height: 100%;
  display: flex;
  padding: 0 8px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

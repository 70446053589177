import styled from "styled-components";
import { defaultTheme } from "storybook/themes/default";

const { colors } = defaultTheme;

export const Content = styled.div`
  p {
    white-space: normal;
    word-break: break-word;
  }

  strong {
    display: block;
    color: ${colors.ciqBrand};
  }
`;

export const ButtonContainer = styled.div`
  button {
    text-transform: capitalize;
  }
`;

export const CopyText = styled.span`
  display: block;
  padding-bottom: 18px;
`;

import React, {
  useState,
  useCallback,
  useMemo,
  useRef,
  useEffect,
  CSSProperties,
} from "react";
import { get, useController, useFormContext } from "react-hook-form";
import { useCombobox } from "downshift";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { isIE11 } from "storybook/utils/helpers";
import { useUniqueId } from "storybook/utils/use-unique-id";
import { Button } from "storybook/components/button";
import { FieldErrorDefault } from "storybook/components/field-error";
import { FormFieldType } from "storybook/utils/form";
import { Chip } from "../chip";
import {
  Input,
  List,
  Label,
  Filler,
  ListItem,
  Container,
  ChipsInner,
  StyledPopper,
  FormElements,
  AnimatedChip,
  ChipContainer,
  StyledListIE11,
  ButtonContainer,
  InputContainer,
  LabelContainer,
  ListContainer,
  InputListContainer,
} from "./theme/default";

export interface MultiSelectAutocompleteType<T> extends FormFieldType<T> {
  label: string;
  items: string[];
  disabled?: boolean;
  placeholder?: string;
  hideErrorText?: boolean;
  hideDirty?: boolean;
  minSearchChars?: number;
  labelWidth?: CSSProperties["width"];
}

const overflowHeight = 86;

export function MultiSelectAutocomplete<T>({
  name,
  label,
  items,
  disabled,
  hideDirty,
  labelWidth,
  placeholder,
  hideErrorText,
  minSearchChars = 1,
}: MultiSelectAutocompleteType<T>) {
  const { formState, trigger } = useFormContext();
  const { field } = useController({ name });
  const [inputValue, setInputValue] = useState("");
  const chipsInnerRef = useRef<HTMLDivElement>(null);
  const chipContainerRef = useRef<HTMLDivElement>(null);
  const inputContainerRef = useRef<HTMLDivElement>(null);
  const resultsId = useUniqueId("multi-select-results");
  const [showExpandLink, setShowExpandLink] = useState(false);
  const [expanded, setExpanded] = useState(false);
  const isDisabled = disabled || formState.isSubmitting;
  const fieldError = get(formState.errors, name);
  const hasError = Boolean(fieldError);

  const selectedItems = useMemo(
    () => (field.value as string[]) || [],
    [field.value]
  );

  const isDirty = useMemo(() => {
    const initialValue = (field.value as string[]) || [];
    return JSON.stringify(initialValue) !== JSON.stringify(selectedItems);
  }, [selectedItems, field.value]);

  const filteredItems = useMemo(() => {
    return items.filter((item) => {
      const filtered =
        !selectedItems.includes(item) &&
        item.toLowerCase().includes(inputValue?.trim().toLowerCase());

      return filtered;
    });
  }, [items, selectedItems, inputValue]);

  const handleSelect = useCallback(
    (item: string) => {
      const newSelectedItems = [...selectedItems, item];

      field.onChange(newSelectedItems);
      trigger(name);
      setInputValue("");
    },
    [selectedItems, field, trigger, name]
  );

  const {
    isOpen,
    selectItem,
    getItemProps,
    getMenuProps,
    getInputProps,
    getLabelProps,
    highlightedIndex,
    setInputValue: setDownshiftInputValue,
  } = useCombobox({
    items: filteredItems,
    inputValue,
    onInputValueChange: ({ inputValue: newInputValue }) => {
      setInputValue(newInputValue || "");
    },
    onSelectedItemChange: ({ selectedItem }) => {
      if (selectedItem) {
        handleSelect(selectedItem);
      }
    },
    stateReducer: (state, actionAndChanges) => {
      const { changes, type } = actionAndChanges;

      switch (type) {
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
        case useCombobox.stateChangeTypes.ItemClick:
          return {
            ...changes,
            inputValue: "",
          };
        case useCombobox.stateChangeTypes.InputBlur:
          return {
            ...changes,
            isOpen: state.isOpen,
            highlightedIndex: state.highlightedIndex,
          };

        default:
          return changes;
      }
    },
  });

  const handleRemove = useCallback(
    (itemToRemove: string) => {
      const newSelectedItems = selectedItems.filter(
        (item) => item !== itemToRemove
      );
      field.onChange(newSelectedItems);

      // If we don't clear Downshift's state of its selected item,
      // removal and addition of the same item will not trigger
      // Downshift's onSelectItemChange event. This would mean the item
      // cannot be reselected after being removed.
      selectItem(null);
    },
    [selectedItems, field, selectItem]
  );

  useEffect(() => {
    setDownshiftInputValue(inputValue);
  }, [inputValue, setDownshiftInputValue]);

  const checkChipsOverflow = useCallback(() => {
    if (chipsInnerRef.current && chipContainerRef.current) {
      const chipsHeight = chipsInnerRef.current.scrollHeight;
      const overflow = chipsHeight > overflowHeight;

      setShowExpandLink(overflow);

      if (!overflow) {
        setExpanded(false);
      }
    }
  }, []);

  const toggleExpand = () => {
    setExpanded((prev) => !prev);
  };

  useEffect(() => {
    if (isIE11() || !window.ResizeObserver) return;

    checkChipsOverflow();

    const resizeObserver = new window.ResizeObserver(checkChipsOverflow);

    if (chipsInnerRef.current) {
      resizeObserver.observe(chipsInnerRef.current);
    }
    if (chipContainerRef.current) {
      resizeObserver.observe(chipContainerRef.current);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [checkChipsOverflow, selectedItems, expanded]);

  const inputProps = getInputProps(
    {
      disabled: isDisabled,
      "aria-owns": resultsId,
    },
    { suppressRefError: true }
  );

  const expandCollapseIcon = expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />;

  const showList =
    isOpen && inputValue.length >= minSearchChars && filteredItems.length > 0;

  const ListComponent = useMemo(() => {
    return (
      <ListContainer
        {...getMenuProps({ disabled: isDisabled }, { suppressRefError: true })}
      >
        {showList && (
          <List>
            {filteredItems.map((item, index) => (
              <ListItem
                key={item}
                $highlighted={highlightedIndex === index}
                {...getItemProps({ item, index })}
              >
                {item}
              </ListItem>
            ))}
          </List>
        )}
      </ListContainer>
    );
  }, [
    showList,
    isDisabled,
    getItemProps,
    getMenuProps,
    filteredItems,
    highlightedIndex,
  ]);

  return (
    <Container data-testid="MultiSelectAutocomplete">
      <FormElements>
        <LabelContainer>
          <Label
            $width={labelWidth}
            $isDirty={isDirty && !hideDirty}
            {...getLabelProps({ disabled: isDisabled })}
          >
            {label}
          </Label>
          <Filler />
        </LabelContainer>
        <InputListContainer>
          <InputContainer ref={inputContainerRef}>
            <Input
              $error={hasError}
              $disabled={isDisabled}
              placeholder={placeholder}
              {...inputProps}
            />
          </InputContainer>
          {isIE11() && Boolean(isOpen) ? (
            <StyledListIE11>{ListComponent}</StyledListIE11>
          ) : (
            <StyledPopper
              keepMounted={true} // To reduce ref errors outputting in console, described below at ref prop.
              open={Boolean(isOpen)}
              anchorEl={inputContainerRef.current}
              $maxHeight="40vh"
              $hasItems={filteredItems.length > 0}
              // $width={buttonRef?.current?.clientWidth}
              placement="bottom-start"
              modifiers={{
                // This modifier is important for keeping the menu fixed while
                // multiple items are added to the select. However, it has the
                // knock-on affect is disabling flipping, so this needs more investigation.
                inner: {
                  enabled: false,
                },
                flip: {
                  enabled: false,
                },
              }}
            >
              {ListComponent}
            </StyledPopper>
          )}
          {hasError && !hideErrorText && (
            <FieldErrorDefault>{fieldError?.message}</FieldErrorDefault>
          )}
          {selectedItems.length > 0 && (
            <>
              <ChipContainer
                role="listbox"
                id={resultsId}
                ref={chipContainerRef}
                aria-expanded={expanded}
                aria-label="Selected options"
                $expanded={isIE11() || expanded}
              >
                <ChipsInner ref={chipsInnerRef}>
                  {selectedItems.map((item: string) => (
                    <AnimatedChip key={item}>
                      <Chip
                        key={item}
                        value={item}
                        role="option"
                        theme="default"
                        maxWidth="100px"
                        variant="primary"
                        ariaSelected={true}
                        disabled={isDisabled}
                        onRemove={handleRemove}
                      />
                    </AnimatedChip>
                  ))}
                </ChipsInner>
              </ChipContainer>
              {!isIE11() && showExpandLink && (
                <ButtonContainer $disabled={isDisabled}>
                  <Button
                    theme="default"
                    variant="text"
                    color="default"
                    disabled={isDisabled}
                    onClick={toggleExpand}
                    endIcon={expandCollapseIcon}
                  >
                    {expanded ? "See Less" : "See More"}
                  </Button>
                </ButtonContainer>
              )}
            </>
          )}
        </InputListContainer>
      </FormElements>
    </Container>
  );
}

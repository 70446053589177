import { useCallback } from "react";
import {
  TryAgainText,
  PanelHeader,
  MainContainer,
  PasswordPanel,
  EditAccountHeader,
  PanelTextContainer,
  PanelHeaderContainer,
} from "./styles";
import { usePatientAuth } from "context/patient-auth";

export function Settings() {
  const { requestPasswordReset, passwordResetSuccess } = usePatientAuth();
  const email = localStorage.getItem("ciq_email");

  const handleClick = useCallback(
    async (event: React.MouseEvent) => {
      event.preventDefault();

      if (!email) {
        console.error("No email found");

        return;
      }

      requestPasswordReset(email);
    },
    [email, requestPasswordReset]
  );

  return (
    <MainContainer>
      <EditAccountHeader>Edit Account</EditAccountHeader>
      <PasswordPanel>
        <PanelHeaderContainer>
          <PanelHeader>Change Password</PanelHeader>
        </PanelHeaderContainer>
        <PanelTextContainer>
          {passwordResetSuccess === false && (
            <TryAgainText>
              Something went wrong. Please try again later
            </TryAgainText>
          )}
          <p className="panel-text">
            To change your password{" "}
            <a href="#" onClick={handleClick}>
              click here
            </a>
            . An email will be sent to
            {` ${email}`} with instructions to change your password.
          </p>
        </PanelTextContainer>
      </PasswordPanel>
    </MainContainer>
  );
}

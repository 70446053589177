import React, { useCallback, useMemo, useState } from "react";
import { get, useFormContext } from "react-hook-form";
import useMergedRef from "@react-hook/merged-ref";
import styled, { StyledComponent } from "styled-components";
import TextField, { TextFieldProps } from "@material-ui/core/TextField";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import InputAdornment from "@material-ui/core/InputAdornment";
import { VisuallyHide } from "storybook/styles/common";
import { ThemeType } from "storybook/utils/theme";
import { FormFieldType } from "storybook/utils/form";
import { useUniqueId } from "storybook/utils/use-unique-id";
import {
  InputLabelDefault,
  StyledErrorWithLabelOutside,
  StyledErrorWithLabelInside,
  StyledInputWithLabelInside,
} from "./theme/default";
import { InputLabelLegacy } from "./theme/legacy";
import { defaultTheme } from "storybook/themes/default";

const { colors } = defaultTheme;

type MuiProps = Pick<
  TextFieldProps,
  | "size" // TODO - do we actually need multiple sizes?
  | "inputRef"
  | "disabled"
  | "autoFocus"
  | "placeholder"
  | "autoComplete"
  | "className"
>;

export interface InputThemeType extends ThemeType {
  name: Extract<ThemeType["name"], "default" | "legacy">;
}

const StyledInputContainer = styled.div<{ $labelPosition: string }>`
  display: flex;
  flex-direction: column;

  label {
    display: ${({ $labelPosition }) =>
      $labelPosition === "left" ? "flex" : "block"}};

    ${({ $labelPosition }) =>
      $labelPosition === "top" &&
      `
      span {
        display: inline-block;
        margin-bottom: 1px;
      }
    `}
  }

  .password-icon {
    color: ${colors.defaultTextButton};
  }    
`;

type StyledComponentMap = Record<
  InputThemeType["name"],
  StyledComponent<any, any>
>;

const inputLabelMap: StyledComponentMap = {
  default: InputLabelDefault,
  legacy: InputLabelLegacy,
};

export const inputTypes = [
  "text",
  "number",
  "search",
  "email",
  "tel",
  "password",
] as const;

export interface InputType<T> extends FormFieldType<T>, MuiProps {
  theme: InputThemeType["name"];
  type?: typeof inputTypes[number];
  label: TextFieldProps["label"];
  step?: number;
  error?: boolean;
  suffix?: string | JSX.Element;
  shrinkLabel?: boolean;
  hideErrorText?: boolean;
  hideLabel?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
  labelPosition?: "left" | "top";
  alignText?: "left" | "right" | "center";
  hideDirty?: boolean;
  variant?: "default" | "round";
  size?: "small" | "medium";
  min?: number;
  max?: number;
}

const StyledHiddenLabelText = styled.span`
  ${VisuallyHide}
`;

export function Input<T>({
  name,
  step,
  theme,
  label,
  error,
  suffix,
  disabled,
  className,
  shrinkLabel,
  type = "text",
  registerOptions = {},
  hideErrorText = false,
  hideLabel = false,
  inputRef = null,
  labelPosition = "left",
  alignText = "right",
  hideDirty = false,
  variant = "default",
  size = "medium",
  min,
  max,
  ...rest
}: InputType<T>): JSX.Element {
  const { formState, register } = useFormContext();
  const fieldError = get(formState.errors, name);
  const hasError = error || Boolean(fieldError);
  const isDirty = Boolean(get(formState.dirtyFields, name));
  const [passwordVisible, setPasswordVisible] = useState(false);
  const instanceId = useUniqueId("Input");

  if (type === "number") {
    // There is a valueAsNumber option, however this can return NaN for non-numeric
    // input (like an empty string). Instead, use a custom setValueAs that returns
    // either a valid integer, or null.
    registerOptions.setValueAs = (value) => {
      const output = parseFloat(value);
      if (Number.isNaN(output)) return null;

      if (min && output < min) {
        return min;
      }

      if (max && output > max) {
        return max;
      }

      return output;
    };
  }

  const isDisabled = disabled || formState.isSubmitting;

  const { ref: rhfRef, ...registerProps } = register(name, registerOptions);

  const combinedRefs = useMergedRef(inputRef, rhfRef);

  const toggleVisibilityIcon = useCallback(() => {
    setPasswordVisible((prev) => !prev);
  }, []);

  const endAdornment = useMemo(() => {
    if (type === "password") {
      const IconComponent = passwordVisible
        ? VisibilityOffIcon
        : VisibilityIcon;

      return (
        <InputAdornment position="end">
          <IconComponent
            onClick={toggleVisibilityIcon}
            style={{ cursor: "pointer", color: colors.ciqBrandLight }}
          />
        </InputAdornment>
      );
    }

    if (suffix) {
      return <InputAdornment position="end">{suffix}</InputAdornment>;
    }

    return undefined;
  }, [passwordVisible, suffix, toggleVisibilityIcon, type]);

  const props: TextFieldProps = {
    ref: combinedRefs,
    label,
    error: hasError,
    variant: "outlined",
    InputProps: endAdornment
      ? {
          endAdornment,
        }
      : {},
    type: type === "password" && passwordVisible ? "text" : type,
    inputProps: type === "number" ? { min, max, step } : undefined,
    disabled: isDisabled,
    ...registerProps,
    ...rest,
  };

  if (shrinkLabel) {
    return (
      <>
        <StyledInputWithLabelInside
          data-testid="Input"
          className={className}
          $hasError={hasError}
        >
          <TextField
            {...props}
            margin="dense"
            fullWidth={true}
            id={instanceId}
            label={hideLabel ? undefined : label}
          />
          {hasError && !hideErrorText && (
            <StyledErrorWithLabelInside>
              {fieldError?.message}
            </StyledErrorWithLabelInside>
          )}
        </StyledInputWithLabelInside>
      </>
    );
  }

  const StyledInputLabel = inputLabelMap[theme];

  return (
    <StyledInputContainer
      data-testid="Input"
      className={className}
      $labelPosition={labelPosition}
    >
      <StyledInputLabel
        $size={size}
        $hasError={hasError}
        $isDirty={isDirty}
        $hideLabel={hideLabel}
        $disabled={isDisabled}
        $variant={variant}
        $alignText={alignText}
        $hideDirty={hideDirty}
      >
        {hideLabel ? (
          <StyledHiddenLabelText data-testid="Input__LabelText">
            {label}
          </StyledHiddenLabelText>
        ) : (
          <span data-testid="Input__LabelText">{label}</span>
        )}
        <TextField {...props} label={undefined} fullWidth={true} />
      </StyledInputLabel>
      {hasError && !hideErrorText && (
        <StyledErrorWithLabelOutside $alignText={alignText}>
          {fieldError.message}
        </StyledErrorWithLabelOutside>
      )}
    </StyledInputContainer>
  );
}

export function InfoIcon() {
  return (
    <svg
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
      aria-label="information icon"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_641_425"
        style={{ maskType: "luminance" }}
        maskUnits="userSpaceOnUse"
        x="1"
        y="1"
        width="22"
        height="22"
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M23 12L20.56 9.22001L20.9 5.54001L17.29 4.72001L15.4 1.54001L12 3.00001L8.6 1.54001L6.71 4.72001L3.1 5.53001L3.44 9.21001L1 12L3.44 14.78L3.1 18.47L6.71 19.29L8.6 22.47L12 21L15.4 22.46L17.29 19.28L20.9 18.46L20.56 14.78L23 12ZM13 17H11V15H13V17ZM11 13H13V7.00001H11V13Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask0_641_425)">
        <rect width="24" height="24" fill="black" fillOpacity="0.58" />
      </g>
    </svg>
  );
}

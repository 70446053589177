/* eslint-disable */
// @ts-nocheck

export const zendeskSnippet = function (apiKey) {
  const script = document.createElement("script");
  script.id = "ze-snippet";
  script.src = `https://static.zdassets.com/ekr/snippet.js?key=${apiKey}`;
  script.defer = true;
  document.body.appendChild(script);

  window.zESettings = {
    webWidget: {
      answerBot: {
        avatar: {
          url: "https://assets.canceriq.com/canceriq/logos/modern/smalllogo.png",
        },
      },
    },
  };

  return () => {
    document.body.removeChild(script);
  };
};

import { useState, useCallback } from "react";
import { z } from "zod";
import { format, parseISO, isAfter, isBefore } from "date-fns";
import { Button } from "storybook/components/button";
import { Modal } from "storybook/components/modal";
import { useForm } from "storybook/utils/form";
import { useAdminContent } from "context/admin-content";
import { useKioskAuth } from "context/kiosk-auth";
import { ITHACA_HOSTNAME, SPECIALIST_HOSTNAME } from "env-vars";
import { openQuestionnaire, clearQuestionnaire } from "helpers";
import {
  Banner,
  DobRow,
  FormRow,
  TextWrapper,
  SignInContainer,
  GlobalModalStyles,
  QuestionnaireContainer,
} from "../styles";

enum ErrorType {
  GENERAL = "GENERAL",
  NO_INITIAL_SURVEY = "NO_INITIAL_SURVEY",
  NOT_FOUND_INTEGRATED = "NOT_FOUND_INTEGRATED",
  NOT_FOUND_NON_INTEGRATED = "NOT_FOUND_NON_INTEGRATED",
  MULTIPLE_FOUND_INTEGRATED = "MULTIPLE_FOUND_INTEGRATED",
  MULTIPLE_FOUND_NON_INTEGRATED = "MULTIPLE_FOUND_NON_INTEGRATED",
}

type Props = {
  onClose: () => void;
};

const minDate = new Date("1900-01-01");
const maxDate = new Date();

const isDateInRange = (date: Date) => {
  return isAfter(date, minDate) && isBefore(date, maxDate);
};

const userSchema = z.object({
  user: z
    .object({
      firstName: z.string().min(1, { message: "Please enter your first name" }),
      lastName: z.string().min(1, { message: "Please enter your last name" }),
      dateOfBirth: z
        .string()
        .refine(
          (value) => {
            const date = parseISO(value);
            return !isNaN(date.getTime()) && isDateInRange(date);
          },
          {
            message: "Please enter a valid date",
          }
        )
        .transform((value) => {
          const date = parseISO(value);
          return format(date, "yyyy-MM-dd");
        }),
    })
    .required()
    .strict(),
});

type User = z.infer<typeof userSchema>;

export function NameDobModal({ onClose }: Props) {
  const adminData = useAdminContent();
  const { isIntegrated, logout } = useKioskAuth();
  const [error, setError] = useState<ErrorType | null>(null);
  const [formValues] = useState<User>({
    user: {
      firstName: "",
      lastName: "",
      dateOfBirth: "",
    },
  });

  const { Form, Input, DateOfBirth } = useForm<User>({
    schema: userSchema,
    values: formValues,
    mode: "onChange",
  });

  const locationName = adminData?.locationName;
  const initialSurveyToken = adminData?.initialSurveyToken;

  const handleSubmit = useCallback(
    async (data: User) => {
      const jwt = localStorage.getItem("id_token");

      const { user } = data;

      const findResponse = await fetch(
        `${SPECIALIST_HOSTNAME}/api/v1/people/find`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
          body: JSON.stringify({
            date_of_birth: user.dateOfBirth,
            first_name: user.firstName,
            last_name: user.lastName,
          }),
        }
      );

      if (!findResponse.ok) {
        if (findResponse.status === 404) {
          setError(
            isIntegrated
              ? ErrorType.NOT_FOUND_INTEGRATED
              : ErrorType.NOT_FOUND_NON_INTEGRATED
          );
        } else if (findResponse.status === 403) {
          setError(
            isIntegrated
              ? ErrorType.MULTIPLE_FOUND_INTEGRATED
              : ErrorType.MULTIPLE_FOUND_NON_INTEGRATED
          );
        } else if (findResponse.status === 401) {
          logout();

          return;
        } else {
          setError(ErrorType.GENERAL);
        }

        return;
      }

      const findPatientData = await findResponse.json();

      const canRescreen = findPatientData.can_rescreen;
      const patientId = findPatientData.person_id;

      const sessionResponse = await fetch(
        `${SPECIALIST_HOSTNAME}/api/v1/survey_sessions/create_session`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${jwt}`,
          },
          body: JSON.stringify({
            is_initial_screening: !canRescreen,
            person_id: patientId,
          }),
        }
      );

      if (!sessionResponse.ok) {
        if (sessionResponse.status === 401) {
          logout();

          return;
        }

        setError(ErrorType.GENERAL);

        return;
      }

      const sessionData = await sessionResponse.json();

      const { survey_type, code, survey_token } = sessionData;

      if (survey_type === "in_person_rescreen" && code) {
        // In person rescreen, redirect to ithaca rescreen wizard
        const rescreenUrl = `${ITHACA_HOSTNAME}/patients/${patientId}/encounters/${patientId}/rescreen-questionnaire?code=${code}`;

        window.open(rescreenUrl, "_self");
      } else if (survey_type === "code" && survey_token) {
        // Open questionnaire with survey_token
        const currentUrl = document.location.href.split("?")[0];
        const redirectUrl = `${currentUrl}?complete=true`;
        const restartUrl = currentUrl;
        const queryParams = {
          redirectUrl: encodeURIComponent(redirectUrl),
          restartUrl: encodeURIComponent(restartUrl),
        };

        localStorage.setItem("surveyCode", code);

        openQuestionnaire({
          queryParams,
          newTab: false,
          token: survey_token,
          skipLandingPage: true,
        });

        return;
      } else {
        setError(ErrorType.NO_INITIAL_SURVEY);
      }
    },
    [isIntegrated, logout]
  );

  const startQuestionnaire = useCallback(() => {
    clearQuestionnaire();

    if (initialSurveyToken) {
      const currentUrl = document.location.href.split("?")[0];
      const redirectUrl = `${currentUrl}?complete=true`;
      const restartUrl = currentUrl;
      const queryParams = {
        redirectUrl: encodeURIComponent(redirectUrl),
        restartUrl: encodeURIComponent(restartUrl),
      };

      openQuestionnaire({
        queryParams,
        newTab: false,
        token: initialSurveyToken,
        skipLandingPage: true,
      });
    } else {
      setError(ErrorType.NO_INITIAL_SURVEY);
    }
  }, [initialSurveyToken]);

  const showStartQuestionnaireBtn =
    !isIntegrated &&
    (error === ErrorType.NOT_FOUND_NON_INTEGRATED ||
      error === ErrorType.MULTIPLE_FOUND_NON_INTEGRATED);

  return (
    <>
      <GlobalModalStyles />
      <Modal
        open={true}
        width={600}
        theme="default"
        onClose={onClose}
        disableBackdropClick={true}
      >
        <Form onSubmit={handleSubmit} disableAutocomplete={true}>
          <Modal.Head alignTitle="left">
            <TextWrapper>Sign in to find your patient record</TextWrapper>
          </Modal.Head>
          <Modal.Body>
            {error === ErrorType.NOT_FOUND_INTEGRATED && (
              <Banner role="alert">
                We couldn't find your patient record in our system. Please try
                again or ask your healthcare team for help.
              </Banner>
            )}
            {(error === ErrorType.NOT_FOUND_NON_INTEGRATED ||
              error === ErrorType.MULTIPLE_FOUND_NON_INTEGRATED) && (
              <Banner role="alert">
                It looks like we couldn't find your patient record. Verify your
                information or select "Start Questionnaire" to start a new
                survey.
              </Banner>
            )}
            {error === ErrorType.GENERAL && (
              <Banner role="alert">
                There was an issue with your request. Please try again.
              </Banner>
            )}
            {error === ErrorType.NO_INITIAL_SURVEY && (
              <Banner role="alert">
                No initial screening survey found for {locationName}
              </Banner>
            )}
            {error === ErrorType.MULTIPLE_FOUND_INTEGRATED && (
              <Banner role="alert">
                We found multiple patient records with your information in our
                system. Please ask your healthcare team for help.
              </Banner>
            )}
            <FormRow>
              <Input
                theme="default"
                name="user.firstName"
                label="First Name"
                hideDirty={true}
                labelPosition="top"
                alignText="left"
                autoComplete="off"
              />
            </FormRow>
            <FormRow>
              <Input
                theme="default"
                name="user.lastName"
                label="Last Name"
                hideDirty={true}
                labelPosition="top"
                alignText="left"
                autoComplete="off"
              />
            </FormRow>
            <DobRow>
              <DateOfBirth
                theme="default"
                name="user.dateOfBirth"
                maxMonthListHeight="30vh"
                disablePortal={false}
              />
            </DobRow>
          </Modal.Body>
          <Modal.Actions alignButtons="center">
            <SignInContainer>
              <Button
                theme="default"
                variant="contained"
                color="default"
                type="submit"
              >
                Sign In
              </Button>
            </SignInContainer>
            {showStartQuestionnaireBtn && (
              <QuestionnaireContainer>
                <Button
                  color="default"
                  theme="default"
                  variant="contained"
                  onClick={startQuestionnaire}
                >
                  Start Questionnaire
                </Button>
              </QuestionnaireContainer>
            )}
          </Modal.Actions>
        </Form>
      </Modal>
    </>
  );
}

import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import { ModalActionsProps, ModalHeadProps, ModalProps } from "./types";
import { sanitizeCSSLength } from "../../utils/helpers";
import { selector } from "../../utils/selector";

const flexPropMap = {
  left: "flex-start",
  right: "flex-end",
  center: "center",
  stretch: "space-between",
};

const verticalAlignMap = {
  top: "start",
  center: "center",
};

export const StyledModal = styled(Dialog)<{
  $verticalAlign: ModalProps["verticalAlign"];
  $padding: ModalProps["padding"];
  $margin: ModalProps["margin"];
  $width: ModalProps["width"];
  $height: ModalProps["height"];
  $backgroundColor: ModalProps["backgroundColor"];
}>`
  word-break: break-all;

  ${selector`.MuiDialog-container`} {
    ${({ $backgroundColor }) =>
      $backgroundColor && `background-color: ${$backgroundColor} !important;`}
  }

  ${selector`.MuiPaper-root`} {
    margin: 0 10px 0 10px;
    padding: 1.5rem !important;

    ${({ $backgroundColor }) =>
      $backgroundColor && `background-color: ${$backgroundColor} !important;`}

    @media (min-width: 600px) {
      margin: 32px;
      padding: 3rem !important;
    }
  }

  ${selector`.MuiDialog-scrollPaper`} {
    align-items: ${({ $verticalAlign }) =>
      $verticalAlign ? verticalAlignMap[$verticalAlign] : "center"};
  }

  ${selector`.MuiDialog-paper`} {
    width: ${({ $width }) => sanitizeCSSLength($width)};
    height: ${({ $height }) => sanitizeCSSLength($height)};
    ${({ $padding }) => $padding && `padding: ${$padding} !important;`}
    ${({ $margin }) => $margin && `margin: ${$margin} !important;`}
  }
`;

export const StyledModalHead = styled(DialogTitle)<{
  $alignTitle: ModalHeadProps["alignTitle"];
}>`
  & ${selector`.MuiTypography-root`} {
    padding-right: 50px;
    word-break: break-word;
    text-align: ${({ $alignTitle }) => $alignTitle || "center"};
  }
`;

export const StyledModalBody = styled(DialogContent)`
  &${selector`.MuiDialogContent-root`} {
    padding: 0;
  }
`;

export const StyledModalActions = styled(DialogActions)<{
  $alignButtons?: ModalActionsProps["alignButtons"];
}>`
  &${selector`.MuiDialogActions-root`} {
    ${({ $alignButtons }) =>
      $alignButtons ? `justify-content: ${flexPropMap[$alignButtons]};` : ""}
  }
`;

export const StyledCloseButton = styled.button`
  &${selector`.close-btn`} {
    position: absolute;
    cursor: pointer;
  }
`;
